<template lang="pug">
  .area()
    .area-inner(
      :style='{ "border-radius": radius }'
    )
    .area-dots(
      :style='{ "border-radius": radius }'
    )
      span +
</template>

<script>

export default {
  name: 'drop-area',
  props: {
    radius: [String, Number],
  },
  data() {
    return {
      deleteIsVisible: false,
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
    showDelete() {
      this.deleteIsVisible = true;
    },
  },
};
</script>

<style lang="scss">

</style>
