import {
  fromPairs, compact, reduce, reject, isUndefined, uniq, orderBy, first, sortBy, isNumber, map, indexOf, flatten, includes, find, filter, each, every, isFunction, isObject, isString, matches, extend, keys, uniqueId, bind, once, clone, result, defaults, isEqual, isArray, has, escape, iteratee, isEmpty, size, defer, some, create, intersection,
} from 'lodash';

const _ = {
  pluck: map,
  fromPairs,
  compact,
  reduce,
  reject,
  isUndefined,
  uniq,
  orderBy,
  first,
  sortBy,
  map,
  isNumber,
  indexOf,
  flatten,
  includes,
  find,
  filter,
  each,
  every,
  isFunction,
  isObject,
  isString,
  matches,
  extend,
  keys,
  uniqueId,
  bind,
  once,
  clone,
  result,
  defaults,
  isEqual,
  isArray,
  has,
  escape,
  iteratee,
  isEmpty,
  size,
  defer,
  some,
  create,
  intersection,
  contains: includes,
  findWhere: find,
  where: filter,
};

export default _;
