<template lang="pug">
  li.item.text-align-center.little-padding.medium-padding-bottom.medium-padding-top(
    :class="itemClass"
    @click="clickHandler"
    @touchstart="clickStartHandler"
    @mousedown="clickStartHandler",
    @contextmenu="contextMenuHandler"
    )
    .visuel(:class="size" :style="backgroundImg")

    .text.tc-dark.medium-margin-top.little-margin-bottom
      span {{ name }}

</template>

<script>
import { getImgDatas } from '@/libs/core/utils';
import { debounce } from 'lodash';
import { setTimeout } from 'timers';

export default {
  name: 'selector-item',
  components: {
  },
  props: {
    type: String,
    id: String,
    name: String,
    img: String,
    size: {
      type: String,
      default: 'thumb',
    },
  },
  data() {
    return {
      eventForClick: null,
    };
  },
  computed: {
    backgroundImg() {
      const imgDatas = getImgDatas(this.img, this.size);
      return {
        'background-image': `url(${imgDatas.u})`,
        'background-size': `${imgDatas.w}px ${imgDatas.h}px`,
        width: `${imgDatas.w}px`,
        height: `${imgDatas.h}px`,
      };
    },
    itemClass() {
      return [
        this.id,
        `item-${this.type}`,
        'cursor-pointer',
      ];
    },
  },
  methods: {
    contextMenuHandler(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    removeReleaseListeners() {
      if (this.eventForClick) {
        this.debouncedEmitHandler(this.eventForClick);
      }
      window.removeEventListener('mouseup', this.globalMouseUpOrTouchEndHandler);
      window.removeEventListener('touchend', this.globalMouseUpOrTouchEndHandler);
    },
    addReleaseListeners(event) {
      this.eventForClick = event;
      setTimeout(() => {
        this.eventForClick = false;
      }, 100);
      window.addEventListener('mouseup', this.globalMouseUpOrTouchEndHandler);
      window.addEventListener('touchend', this.globalMouseUpOrTouchEndHandler);
    },
    globalMouseUpOrTouchEndHandler() {
      this.removeReleaseListeners();
    },
    clickStartHandler(event) {
      this.addReleaseListeners(event);
      if (this.$viewport.useLateralConfigurationMenu) {
        this.debouncedEmitHandler(event);
      }
    },
    clickHandler(event) {
      const areas = this.$hub.inter.getPossibleAreasHTML(this.id, this.size, true);
      if (areas.length === 1) {
        setTimeout(() => {
          const { id } = this;
          const { poste, mod } = areas[0];
          this.$hub.inter.set('fonctions', { id, poste, mod });
          this.$hub.removeFunctionToAdd();
        }, 10);
      } else {
        setTimeout(() => {
          this.$hub.isDragingFunction = true;
        }, 1);
        this.debouncedEmitHandler(event);
      }
    },
    debouncedEmitHandler() {},
    emitHandler() {
      this.$emit('selectItem', this.id);
    },
  },
  created() {
    this.debouncedEmitHandler = debounce(this.emitHandler, 1);
  },
};
</script>

<style lang="scss">
  li.item {
    vertical-align: top;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -webkit-touch-callout: none;
    &:hover{
      span{
        text-decoration: underline;
      }
    }
    // width:65px;
    .visuel {
      display: inline-block;
    }
    .text {
      span {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        text-transform: capitalize;
        font-size: 11px;
        line-height: 11px;
        font-weight: 600 !important;
        word-break: normal;
        hyphens: auto;
      }
    }
  }
</style>
