import { map } from 'lodash';
import { L, numberize } from '@/libs/core/utils';

export default class CartAsJson {
  _reduce(a, coef = 1) {
    return a.reduce((p, c) => {
      if (!Object.prototype.hasOwnProperty.call(p, c)) {
        p[c] = 0;
      }
      p[c] += coef;
      return p;
    }, {});
  }

  getComponents(product) {
    const refs = this._reduce(product.getRefs(), product.get('quantity'));
    return Object.keys(refs).map((ref) => ({
      ref,
      ean: MYS.PRODUITS.get(ref).get('ean'),
      quantity: refs[ref],
      name: MYS.PRODUITS.get(ref).get('name').trim(),
      price: numberize(MYS.PRODUITS.get(ref).get('prix')),
    }));
  }

  getProductTitle(product) {
    const finition = MYS.FINITIONS.get(product.get('finition'));
    const gamme = finition.get('gamme');
    const format = product.get('format');
    return `${L(gamme)} ${L(finition.id)} ${L(format)}`;
  }

  transform(cart) {
    if (!cart || !cart.get) {
      return [];
    }
    const products = cart.get('products').models;
    return map(products, (product) => (
      {
        name: product.getName().trim(),
        quantity: product.get('quantity'),
        description: this.getProductTitle(product).trim(),
        image: product.getVisuel('thumb'),
        doigt: product.get('doigt'),
        gamme: product.getGamme(),
        marque: product.getMarque(),
        prix: numberize(product.getPrix()),
        components: this.getComponents(product),
      }
    ));
  }
}
