<template lang="pug">
  .gamme-list
    h1.tc-white.large-padding
      slot
        | Gammes
    ul
      gamme-item(v-for="gamme in gammes", v-bind="gamme", :key="gamme.slug", @click="clickHandler")

</template>

<script>
import gammeItem from '@/components/Gamme/item.vue';

export default {
  name: 'gamme-list',
  components: {
    gammeItem,
  },
  props: {
    gammes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  methods: {
    clickHandler(slug) {
      this.$emit('gammeSelection', slug);
    },
  },

};
</script>
<style lang="scss">
.gamme-list {
  padding-left: 30px;
  padding-right: 30px;
  & > ul {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
}
</style>
