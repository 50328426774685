import GTM from '@/libs/gtm';

const gtm = GTM();
const GTMconnector = {
  install(Vue, options) {
    Vue.prototype.$gtm = gtm;
  },
};

export default GTMconnector;
