<template lang="pug">
  div.test
    mini-config-test
</template>

<script>
import miniConfigTest from '@/components/miniConfigTest.vue';

export default {
  name: 'test',
  components: {
    miniConfigTest,
  },
};
</script>
