<template lang="pug">
  div.line.text-align-left
    hr
    div.item(:class="{ deleting: showConfirm }")
      div.delete.display-inline-block(@click="deleteClickHandler")
        i.delete-button.mobile-icon-close.tc-white

      div.item-details.display-inline-block
        div.medium-margin-top.medium-margin-bottom
            .visuel.left-col.display-inline-block(
              style="position: relative"
              v-html='visuel'
            )
            .quantifier.right-col.display-inline-block
              i.mobile-icon-embed
              select(v-model="productQty") // opacity 0
                option(v-for="quantity in quantities", :value="quantity")
                  | {{ quantity }}

              div.value.display-inline-block {{ qty }}

        div.medium-margin-top.little-margin-bottom
          .title.left-col.display-inline-block
            | {{ title }}
            ul.functions
              li.function(v-for="(fonction, fonctionIndex) in functions" :key="`${fonction}_${fonctionIndex}`")
                |- {{ fonction.usage }}

          .quantified-price.right-col.display-inline-block(v-if="canShowPrice")
            | {{ totalPrice | pricize }}{{ $getCurrency() }} {{ $getTax() }}

      transition(:name="transitionName" mode="out-in")
        div.confirm(v-if="showConfirm" v-release-outside="cancelDeleteClickHandler")
          div.confirm-delete(@click="confirmDeleteClickHandler")
            button.vertical-bg-gradient Supprimer
          div.cancel-delete(@click="cancelDeleteClickHandler")
            button Annuler

</template>

<script>
import { L } from '@/libs/core/utils';
import { releaseOutside } from '@/directives/release';
import { get } from 'lodash';

export default {
  name: 'cart-line',
  components: {},
  directives: { releaseOutside },
  props: {
    title: {
      type: String,
    },
    functions: {
      type: Array,
      default: () => [],
    },
    price: {
      type: Number,
    },
    qty: {
      type: Number,
      default: 1,
    },
    visuel: {
      type: String,
    },
  },
  data() {
    const quantities = [];
    for (let i = 1; i < 100; i++) {
      quantities.push(i);
    }
    return {
      quantities,
      showConfirm: false,
      transitionName: 'slide-width-right',
    };
  },
  methods: {
    deleteClickHandler() {
      this.transitionName = 'slide-width-right';
      this.showConfirm = true;
      // this.$emit('delete')
    },
    confirmDeleteClickHandler() {
      this.$emit('delete');
    },
    cancelDeleteClickHandler() {
      this.transitionName = 'slide-width-left';
      this.showConfirm = false;
    },
  },
  computed: {
    canShowPrice() {
      return get(this.$getNativeMYS(), 'CONFIG.layout.showPrice', true);
    },
    totalPrice() {
      return this.price * this.qty;
    },
    refPrefix() {
      return L('réf.');
    },
    productQty: {
      get() {
        return this.qty;
      },
      set(value) {
        this.$emit('changeQuantity', value);
      },
    },
  },
};
</script>
<style lang="scss">
.line {
  background-color: white;

  hr {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    border-top: 1px solid #DDDDDD;
  }
  .item {
    display: table;
    width: 100%;
    position: relative;

    .delete {
      width: 48px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      &:hover {
        .delete-button {
          color: black;
        }
      }
      .delete-button {
        vertical-align: middle;
        font-size: 0.5em;
        color: #AAAAAA;
        transition: color 250ms ease;
      }
      &:hover{
        .delete-button{
          color: #000;
        }
      }
    }
    .item-details {
      display: table-cell;
      font-size: 0.85em;
      padding-right: 20px;
      transition: 0.8s all cubic-bezier(0.55, 0, 0.1, 1);;

      .left-col {
        width: calc(100% - 100px);
      }
      .right-col {
        width: 85px;
        margin-left: 15px;
        text-align: right;
      }
      .title {
        font-weight: 500;

        .functions {
          margin-top: 5px;
        }
      }
      .quantified-price {
        font-weight: 500;
        color: var(--primary-color);
        text-align: right;
        vertical-align: top;
        font-size: 12.5px;
      }
      .quantifier {
        position: relative;
        height: 100%;
        vertical-align: top;
        $height: 35px;
        $width: 80px;
        i {
          position: absolute;
          top: ($height / 2 - 5);
          right: 12px;
          color: #CCCCCC;
        }
        .value, select{
          border: 1px solid #DDDDDD;
          background-color: #FFFFFF;
          vertical-align: middle;
          width: $width;
          height: $height;
          text-align: center;
        }
        .value {
          line-height: $height;
        }
        select {
          top: 0;
          right:0;
          position: absolute;
          opacity: 0;
        }
      }
      .lights {
        display: none;
      }
    }
    .confirm {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: calc( 100% + 2px);
      margin-top: -1px;
      top: 0;
      left: 0;
      z-index: 2;

      div {
        display: inline-block;
        width: 50%;
        height: 100%;
        text-align: center;

        button {
          height: 100%;
          width: 100%;
          border: none;
          text-transform: uppercase;
          font-weight: 500;
          transition: all 250ms ease;
          cursor: pointer;
        }
        &.confirm-delete button{
          color: #FFFFFF;
          &:hover{
            // background-color: #313131;
            // background: #000;
            background: linear-gradient(-190deg,#000,#000);
          }
        }
        &.cancel-delete button {
          background-color: #EEEEEE;
          color: #000000;

          &:hover{
            background-color: #cacaca;
          }
        }
      }
    }

    &.deleting {
      .delete {
        opacity: 0;
      }
      .item-details {
        transform: translate(50%)
      }
    }
  }
}
.slide-width-left-enter-active,
.slide-width-left-leave-active,
.slide-width-right-enter-active,
.slide-width-right-leave-active {
      transition-duration: 0.8s;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
      overflow: hidden;
}

.slide-width-left-enter,
.slide-width-right-leave-active {
  transform: translate(-100vw, 0);
}

.slide-width-left-leave-active,
.slide-width-right-enter {
  transform: translate(-100vw, 0);
}
</style>
