/**
 @typedef {Object} Finition
 @property {string} id - identifiant de la Finition
 @property {string} name - Nom de la Finition
 @property {string} marque - Marque de la Finition (Legrand, Bticino, Arnould …)
 @property {string} gamme - Gamme de la Finition (Celiane, Axolute, Art …)
 @property {string} collection - Collection de la Finition
 @property {integer} position-couleur - Poids, permettant de définir la Position de la Finition sur le tri par "couleur"
 @property {integer} position-marketing - Poids, permettant de définir la Position de la Finition sur le tri par "popularité"
 @property {string[]} couleurs - Identifiants des couleurs auxquelles est rattachée la Finition
 @property {string[]} matieres - Identifiants des matières auxquelles est rattachée la Finition
 */

/**
 * Représente le Modèle Finition.
 * @constructor
 * @param {Finition} finition - Données de la Finition, permettant sa génération.
 * @augments Backbone.Model
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 */
MYS.BB.MODELS.Finition = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Finition.prototype */
    {

        /**
         * Retourne la plus petite {@link MYS.BB.MODELS.Plaque Plaque} associée à cette finition
         * @return {MYS.BB.MODELS.Plaque}
         */
        getSmallestPlaque: function() {
            var plaques = this.getPlaques()
            var numberOfHoles = _.map(plaques, function(plaque) {
                return plaque.getFormat().get('composition#id').length

            })
            var minHole = Math.min.apply(null, numberOfHoles) || 1
            var minimalPlaques = _.filter(plaques, function(plaque) {
                return plaque.getFormat().get('composition#id').length === minHole
            })
            var minimalPlaque = _.sortBy(minimalPlaques, function(plaque) {
                return plaque.getPrix();
            });
            return _.first(minimalPlaque);
        },

        /**
         * Retourne la moins chere {@link MYS.BB.MODELS.Plaque Plaque} associée à cette finition,
         * parmis une lise de format prédéfinie.
         * @param {array} formatIds - identifiants de {@link MYS.BB.MODELS.Format}
         * @return {MYS.BB.MODELS.Plaque}
         */
        getCheapestPlaqueByFormat: function(formatIds) {
            var plaques = this.getPlaques();
            plaques = _.filter(plaques, function(plaque) {
                return _.contains(formatIds, plaque.get("format#id"));
            });
            plaques = _.sortBy(plaques, function(plaque) {
                return plaque.getPrix();
            });

            return _.first(plaques);
        },
        getPrivelegedPlaque: function() {
            var privelegedFormat = this.getGamme().get('privelegedFormat');
            var privelegedPlaque;
            if (privelegedFormat) {
                privelegedPlaque = _.find(this.getPlaques(), function(el) {
                    return el.get('format#id') === privelegedFormat // '2_modules_mosaic'
                })
            }
            return privelegedPlaque
        },
        getPrivelegedPlaqueOrSmallest: function() {
            return this.getPrivelegedPlaque() || this.getSmallestPlaque()
        },

        /**
         * Retourne toutes les {@link MYS.BB.MODELS.Plaque Plaques} associées à cette finition
         * @return {MYS.BB.MODELS.Plaque}
         */
        getPlaques: function() {

            var output = MYS.PLAQUES.where({
                "finition#id": this.id
            });
            if (output.length === 0) {
                console.warn("WARNING : aucune plaque trouvée");
            }

            return output;
        },

        /**
         * Retourne une  {@link MYS.BB.MODELS.Plaque Plaque} associées à cette finition
         * @param {string} formatId - identifiants de {@link MYS.BB.MODELS.Format}
         * @return {MYS.BB.MODELS.Plaque}
         */
        getPlaquesByFormat: function(formatId) {

            var output = MYS.PLAQUES.where({
                "finition#id": this.id,
                "format#id": formatId,
            });
            return output;
        },
        getGamme: function() {
            var gamme;
            var gammeId = this.get('gamme');
            if (MYS.GAMMES) {
                gamme = MYS.GAMMES.get(gammeId)
            } else {
                MYS.GAMMES = new MYS.BB.COLLECTIONS.Gammes()
            }
            if (!gamme) {
                gamme = MYS.GAMMES.add({
                    id: gammeId
                })
            }
            return gamme
        },
        /**
         * Invalide la Finition s'il n'y a pas de {@link MYS.BB.MODELS.Plaque Plaque} associée
         * @return {string|void}  La chaine retournée est un descriptif de l'erreur entrainant l'invalidation
         */
        validate: function() {

            if (MYS.PLAQUES.findWhere({
                    "finition#id": this.id
                }) === undefined) {
                return "Aucune MYS.BB.MODELS.Plaque associée";
            }


        }
    }
);
