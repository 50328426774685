window.MYS = {};

require('../../../static/2/js/base/app');
// Gamme
require('../../../static/2/js/base/core/Configurateur/Gamme/Gamme');
require('../../../static/2/js/base/core/Configurateur/Gamme/Gammes');

// Boite
require('../../../static/2/js/base/core/Configurateur/Boite/Boite');
require('../../../static/2/js/base/core/Configurateur/Boite/Boites');

// Composition
require('../../../static/2/js/base/core/Configurateur/Composition/Composition');
require('../../../static/2/js/base/core/Configurateur/Composition/Compositions');

// Finition
require('../../../static/2/js/base/core/Configurateur/Finition/Finition');
require('../../../static/2/js/base/core/Configurateur/Finition/Finitions');

// Fonction
require('../../../static/2/js/base/core/Configurateur/Fonction/Fonction');
require('../../../static/2/js/base/core/Configurateur/Fonction/Fonctions');

// Format
require('../../../static/2/js/base/core/Configurateur/Format/Format');
require('../../../static/2/js/base/core/Configurateur/Format/Formats');

// Img
require('../../../static/2/js/base/core/Configurateur/Img/Img');
require('../../../static/2/js/base/core/Configurateur/Img/Imgs');

// Interrupteur
require('../../../static/2/js/base/core/Configurateur/Interrupteur/Interrupteur');
require('../../../static/2/js/base/core/Configurateur/Interrupteur/Interrupteurs');

// Module
require('../../../static/2/js/base/core/Configurateur/Module/Module');
require('../../../static/2/js/base/core/Configurateur/Module/Modules');

// Plaque
require('../../../static/2/js/base/core/Configurateur/Plaque/Plaque');
require('../../../static/2/js/base/core/Configurateur/Plaque/Plaques');

// Produit
require('../../../static/2/js/base/core/Configurateur/Produit/Produit');
require('../../../static/2/js/base/core/Configurateur/Produit/Produits');

// Rectangle
require('../../../static/2/js/base/core/Configurateur/Rectangle/Rectangle');

// Rule
require('../../../static/2/js/base/core/Configurateur/Rules/Rule');
require('../../../static/2/js/base/core/Configurateur/Rules/Rules');

// Support
require('../../../static/2/js/base/core/Configurateur/Supports/Support');
require('../../../static/2/js/base/core/Configurateur/Supports/Supports');

// Usage
require('../../../static/2/js/base/core/Configurateur/Usage/Usage');
require('../../../static/2/js/base/core/Configurateur/Usage/Usages');

// Selection
require('../../../static/2/js/base/core/selection/Selection');

delete MYS.CONFIG.URLS.templates;

export default window.MYS;
