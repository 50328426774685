import { isFunction, cloneDeep, isEqual } from 'lodash';

export default {
  computed: {
    hasBackUrl() {
      return Boolean(this.backUrl);
    },
    rawBackUrl() {
      if (!this.$route.meta.back) {
        return false; // this.$router.options.routes[0].path
      } if (isFunction(this.$route.meta.back)) {
        const gamme = this.$hub && this.$hub.inter ? this.$hub.inter.getGamme() : undefined;
        return this.$route.meta.back(gamme);
      }
      return cloneDeep(this.$route.meta.back);
    },
    backUrl() {
      const { rawBackUrl } = this;
      if (!this.$getNativeMYS().CONFIG.layout.canShowGammePage) {
        if (isEqual(rawBackUrl, { name: 'gammes' })) {
          return false;
        }
      }
      return rawBackUrl;
    },
  },
};
