import Vue from 'vue';
import Router from 'vue-router';
import Gammes from './views/Gammes.vue';
import Config from './views/Config.vue';
import Cart from './views/Cart.vue';
import Test from './views/Test.vue';

Vue.use(Router);
let routes = [
  {
    path: '/',
    name: 'gammes',
    component: Gammes,
  }, {
    path: '/configurateur/:gamme',
    name: 'config',
    component: Config,
    meta: { back: { name: 'gammes' } },
  }, {
    path: '/panier',
    name: 'cart',
    component: Cart,
    meta: {
      back(gamme) {
        if (!gamme) { return '/'; }
        return {
          name: 'config', params: { gamme },
        };
      },
    },
  }, {
    path: '/test',
    name: 'test',
    component: Test,
  }, {
    path: '*',
    name: 'default',
    redirect: { name: 'gammes' },
  },
];
routes = routes.map((route, index) => {
  route.meta = route.meta || {};
  route.meta.index = index;
  return route;
});
export default new Router({
  routes,
});
