<template lang="pug">
  ul.interrupteur-fingers
    li.hover.finger.hover(v-for="finger in fingers" :class="fingerClass(finger)" @click="clickHandler(finger)")
      div.border
        div.sample(:style="{ color: 'white', backgroundImage: `url('/static/mobile/imgs/gammes/${imageName(finger)}.png')` }")

    .activeSymbol.active(:style="activeElementStyle")
      div

</template>

<script>
import { kebabCase } from 'lodash';
import { L } from '@/libs/core/utils';

export default {
  name: 'interrupteur-fingers',
  components: {
  },
  props: {
    active: String,
    gamme: {
      type: String,
      default: '',
    },
    fingers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    imageName(finger) {
      return kebabCase(`${this.gamme} ${finger}`);
    },
    fingerClass(finger) {
      return [
        {
          active: this.isActive(finger),
        },
        kebabCase(`finger ${finger}`),
      ];
    },
    isActive(finger) {
      return finger === this.active;
    },
    clickHandler(finger) {
      this.pushGTM(finger);
      this.$emit('update:active', finger);
    },
    pushGTM(finger) {
      this.$gtm.push({
        event: 'eventGA',
        eventCategory: this.$getNativeMYS().CONFIG.contour,
        eventAction: 'Choix couleur fonction',
        eventLabel: L(finger),
      });
    },
  },
  computed: {
    hasActive() {
      return this.fingers.indexOf(this.active) > -1;
    },
    activeElementStyle() {
      return {
        display: this.hasActive ? 'block' : 'none',
        left: `${this.fingers.indexOf(this.active) * 100 / (this.fingers.length || 1)}%`,
      };
    },
  },
};
</script>

<style lang="scss">
.interrupteur-fingers {
  display: inline-block;
  position: relative;
  margin-top: 0px;
  .activeSymbol {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    transition: left linear .25s;
    padding: 14px 16px 9px;
  }
  .sample {
    background-color: silver;
  }
  .finger {
    &:not(.active) {
      cursor: pointer;
    }
    padding: 20px 14px 9px 14px;
    .border {
      padding: 0px;
      transition: border-color linear .25s .25s;
    }
  }
  .finger, .activeSymbol {
    display: inline-block;
  }
  .finger .border {
    border: 1px solid rgba( 0, 0, 0, .2);
  }
  .finger .border, .finger .sample, .activeSymbol > div {
    display: block
  }
  .finger .sample, .activeSymbol > div {
    background-size: contain;
    width: 1em;
    height: 1em;
  }
  .finger.active .border {
    border-color: rgba( 0, 0, 0, .6);
  }
  .activeSymbol.active {
    border-top: 4px solid var(--primary-color);
  }
}
.darkModeEnabled {
  .finger.active .border {
    border-color: rgba( 255, 255, 255, .6);
  }
  .interrupteur-fingers {
    .activeSymbol.active {
      z-index: 0;
      border-top-color: #FFFFFF;
    }
  }
}
.is-lateral-configuration-menu {
  .interrupteur-fingers {
    .finger {
          padding: 20px 14px 3px 14px;
    }
  }
}

</style>
