<template lang="pug">
  div.cart.view(v-if="hasProject")
    div.cart-detail(v-if="products.length > 0")
      h2.large-padding-top.large-margin-bottom.tc-primary.grand-total
        | Ma sélection :
        template(v-if="canShowPrice")
          |  {{ amount | pricize }}{{ $getCurrency() }}
          | &nbsp;
          span.tax {{ $getTax() }}
        template(v-else)
          |  {{ productCount }} produit{{ productCount > 1 ? 's' : '' }}

      div.actions.desktop-actions(v-if="displayDesktopCartButtons")
        .desktop-actions-container
          .row-action#turnstile-holder
            div(v-if="displayTurnstile") Cochez la case pour télécharger la liste
            #inv-turnstile-holder

          .row-action
            a#c2b-legrand-wishlist-cta.tc-white.upper.horizontal-bg-gradient(:style="click2BuyActionStyles")
              span Acheter
            a.download-pdf.external-links.upper.tc-primary(@click="downloadPdfClickHandler" v-if="displayPdfAction")
              span
                svg-icon.icon.tc-primary(icon="dl-pdf")
                | Télécharger la liste
            a.form-elec-action.tc-white.horizontal-bg-gradient(@click="electricienClickHandler" v-if="displayElecCertifieAction")
              img(src="/static/mobile/imgs/badge-electricien-certifie.png")
              svg-icon.icon.tc-white(icon="form-elec")
              div Legrand vous accompagne pour trouver un électricien certifié près de chez vous
              span.button.tc-dark.upper Envoyer mon projet à un électricien
            a.external-links.upper.tc-primary(
              v-for="link in links" :href="link.url"
              target="_blank"
              rel="noopener"
              )
              span {{ link.title }}
            a.external-links.addCartToExternal.upper.tc-primary(
              v-if="canSendToCart"
              @click="sendToCart"
              )
              span {{ sendCartLabel }}

      transition-group(name="list-complete", tag="p")
        cart-line(
          v-for="product in products"
          :title="getProductTitle(product)"
          :price="product.getPrix()"
          :qty="product.get('quantity')"
          :visuel="product.getVisuel('thumb')"
          :functions="getRefsWithrelativeDatas(product)"
          :key="product.cid"
          class="list-complete-item"
          @changeQuantity="changeQuantity(product, $event)"
          @delete="deleteLine(product)"
          )

    div.cart-empty(v-else)
      h2.large-margin-bottom.tc-primary
        | Votre sélection est vide

    div.add-product
      router-link.tc-primary(:to="addProductLink")
        | + Ajouter un produit

    div.actions.mobile-actions(v-if="displayMobileCartButtons")
      .row-action#turnstile-holder
        div(v-if="displayTurnstile") Cochez la case pour télécharger la liste
        #inv-turnstile-holder

      .row-action
        a#c2b-legrand-wishlist-cta.tc-white.upper.horizontal-bg-gradient(:style="click2BuyActionStyles")
          span Acheter
        a.download-pdf.external-links.upper.tc-primary(@click="downloadPdfClickHandler" v-if="displayPdfAction")
          span Télécharger la liste
        a.external-links.upper.tc-primary(@click="electricienClickHandler" v-if="displayElecCertifieAction")
            span Envoyer mon projet à un électricien
        a.external-links.upper.tc-primary(
          v-for="link in links" :href="link.url" @click="electricienClickHandler"
          target="_blank"
          rel="noopener"
          )
          span {{ link.title }}
        a.addCartToExternal.upper.tc-primary(
          v-if="canSendToCart"
          @click="sendToCart"
          )
          span {{ sendCartLabel }}

    .turnstile-disclaimer
      | Ce site est protégé par Cloudflare.
      a(href="https://www.cloudflare.com/fr-fr/privacypolicy/")
        | Politique de confidentialité
      | &nbsp;et&nbsp;
      a(href="https://www.cloudflare.com/fr-fr/website-terms/")
        | Conditions d'utilisation.
      | .
</template>

<script>
import { L, appendClick2buy } from '@/libs/core/utils';
import {
  get, each, sum, map,
} from 'lodash';
import cartLine from '@/components/Cart/line.vue';

export default {
  name: 'cart',
  components: {
    cartLine,
  },
  data() {
    return {
      holderId: null,
      displayTurnstile: false,
      click2BuyEnabled: false,
      maxLoadingClick2Buy: 20,
    };
  },
  watch: {
    displayElecCertifieAction: {
      handler(value) {
        if (value === true) {
          this.$gtm.push({
            event: 'eventGA',
            eventCategory: this.$getNativeMYS().CONFIG.contour,
            eventAction: 'Contact',
            eventLabel: 'Affichage bloc CTA',
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    click2BuyActionStyles() {
      return {
        display: this.products.length > 0 && this.click2BuyEnabled ? '' : 'none',
      };
    },
    displayPdfAction() {
      if (MYS.CONFIG.cart && MYS.CONFIG.cart.pdfAction) {
        return true;
      }
      return false;
    },
    displayElecCertifieAction() {
      if (MYS.CONFIG.cart && MYS.CONFIG.cart.elecCertifieAction && MYS.CONFIG.cart.elecCertifieLimit && this.whishlist.getPrix() >= MYS.CONFIG.cart.elecCertifieLimit) {
        return true;
      }
      return false;
    },
    addProductLink() {
      if (!this.$getNativeMYS().CONFIG.layout.canShowGammePage) {
        if (!this.hasBackUrl) {
          return false;
        }
        return this.backUrl;
      }
      return 'gammes';
    },
    displayDesktopCartButtons() {
      return this.products.length > 0 && this.$viewport.useDesktopCartButtons;
    },
    displayMobileCartButtons() {
      return this.products.length > 0 && !this.$viewport.useDesktopCartButtons;
    },
    canSendToCart() {
      return get(this.$getNativeMYS(), 'CONFIG.layout.sendCartMethod');
    },
    sendCartLabel() {
      return get(this.$getNativeMYS(), 'CONFIG.layout.sendCartLabel');
    },
    productCount() {
      return sum(map(this.products, (pdt) => get(pdt, 'attributes.quantity', 0)));
    },
    canShowPrice() {
      return get(this.$getNativeMYS(), 'CONFIG.layout.showPrice', true);
    },
    hasProject() {
      return Boolean(this.$hub.project);
    },
    whishlist() {
      return this.$hub.project.get('wishlists').at(0);
    },
    products() {
      return this.whishlist.get('products').models || [];
    },
    amount() {
      return this.whishlist.getPrix();
    },
    links() {
      if (MYS.CONFIG.cart && MYS.CONFIG.cart.additionnalLinks) {
        return MYS.CONFIG.cart.additionnalLinks;
      }
      return [];
    },
  },
  methods: {
    sendToCart() {
      this.$sendToExternalCart(this.whishlist);
    },
    gotToAddProductClickHandler() {
      // this.$gtm.push({
      //   'event': 'eventGA',
      //   'eventCategory': this.$getNativeMYS().CONFIG.contour,
      //   'eventAction': 'Basket',
      //   'eventLabel': 'add Item'
      // })
    },
    electricienClickHandler() {
      // this.$exportToExcel()
      if (this.displayElecCertifieAction) {
        this.$displayFormElec();
      } else {
        this.$gtm.push({
          event: 'eventGA',
          eventCategory: this.$getNativeMYS().CONFIG.contour,
          eventAction: 'Trouver un electricien',
        });
      }
    },
    resetInvTurnstileHolder() {
      if (!this.holderId) return;
      window.turnstile.reset(this.holderId);
      const holder = this.$el.querySelector('#inv-turnstile-holder');
      holder.remove();
      this.displayTurnstile = false;
      const div = document.createElement('div');
      div.id = 'inv-turnstile-holder';
      const holderDiv = this.$el.querySelector('#turnstile-holder');
      holderDiv.appendChild(div);
    },
    proxyRecapchaRender() {
      if (window.turnstile) {
        this.resetInvTurnstileHolder();
        this.holderId = window.turnstile.render('#inv-turnstile-holder', {
          sitekey: '0x4AAAAAAAipENzvhELnqHfU',
          size: 'flexible',
          theme: 'light',
          callback: (turnstileToken) => {
            console.log(`Challenge Success ${turnstileToken}`);
            try {
              this.saveToPdf(turnstileToken);
            } catch (error) {
              console.error(error);
            }
          },
        });
      }
    },
    getProjectForParamOutput() {
      const products = this.whishlist.get('products');
      const output = [];
      products.each((currentInter) => {
        if (!currentInter.has('quantity')) {
          currentInter.set('quantity', 1);
        }
        const product = {
          content: [],
          datas: currentInter.toJSON(),
          id: currentInter.get('createdAt'),
          preview: currentInter.getImage('small'),
          title: currentInter.getShortTitle(),
          price: currentInter.getPrix(),
          gamme: currentInter.getGamme(),
          gammeName: L(currentInter.getGamme()),
        };
        const refs = {};
        each(currentInter.getRefs(), (ref) => {
          refs[ref] = (refs[ref] === undefined) ? refs[ref] = 1 : refs[ref] + 1;
        });
        for (const ref in refs) {
          const produit = MYS.PRODUITS.get(ref);
          if (produit !== undefined) {
            product.content.push({
              REF: produit.id,
              qty: refs[ref],
            });
          }
        }
        output.push(product);
      });
      return output;
    },
    saveToPdf(turnstileToken) {
      const projet = this.whishlist;
      const output = this.getProjectForParamOutput();

      const host = get(process.env, 'VUE_APP_PDF_HOST', window.location.origin);
      const url = host + this.$getNativeMYS().CONFIG.pdfUrlPath;

      const totalPrice = projet.getPrix();

      this.pushCartGTM('purchase');

      this.$download(url, output, totalPrice, turnstileToken);
    },
    downloadPdfClickHandler() {
      this.proxyRecapchaRender();
      // this.initTurnstile()
    },
    getRefsWithrelativeDatas(inter) {
      return inter.getProductFonctions();
    },
    deleteLine(product) {
      product.destroy();
    },
    changeQuantity(product, quantity) {
      product.set('quantity', quantity);
    },
    getProductTitle(product) {
      const finition = this.$getNativeMYS().FINITIONS.get(product.get('finition'));
      const gamme = finition.get('gamme');
      const format = product.get('format');
      return `${L(gamme)} ${L(finition.id)} ${L(format)}`;
    },
    increment(product) {
      product.set('quantity', product.get('quantity') + 1);
    },
    decrement(product) {
      product.set('quantity', product.get('quantity') - 1);
    },
    getHTML(product) {
      return product.getVisuel();
    },
    pushCartGTM(type) {
      if (!this.$hub.project) return;
      let evt;
      let refs = this.$hub.project.getHouseRefs();
      refs = this.$gtm.formatRefsAsObject(refs);
      const products = this.$gtm.populate(refs);
      if (type === 'checkout') {
        evt = {
          event: 'checkout',
          ecommerce: {
            currencyCode: 'EUR',
            [type]: {
              actionField: { step: 1 },
              products,
            },
          },
        };
      } else if (type === 'purchase') {
        evt = {
          event: 'purchase',
          ecommerce: {
            currencyCode: 'EUR',
            [type]: {
              actionField: {
                id: this.$hub.project.get('createdAt').toString(),
                affiliation: 'telechargement',
                revenue: this.$hub.project.getHousePrix(),
              },
              products,
            },
          },
        };
      } else {
        return;
      }
      this.$gtm.push(evt);
    },
    click2BuyLoading(i = 0) {
      if (i < this.maxLoadingClick2Buy) {
        i++;
        if (typeof window.c2bLegrandWishlistMonInterrupteur !== 'undefined') {
          this.click2BuyEnabled = true;
          return;
        }
        setTimeout(() => {
          this.click2BuyLoading(i);
        }, 200);
      }
    },
    loadClick2Buy() {
      this.click2BuyLoading();
      appendClick2buy();
    },
    starterPackIsNeededInCart() {
      let functionsNeedingStarterPack = [];
      this.products.forEach((inter) => {
        functionsNeedingStarterPack = functionsNeedingStarterPack.concat(inter.getFunctionsNeedingStarterPack());
      });

      if (functionsNeedingStarterPack.length) {
        const beforeMessage = 'Certains produits de votre panier sont connectés et nécessitent l\'installation d\'un pack de démarrage connecté.';
        this.$hub.project.trigger('warning.functionsNeedStarterPack', { functionsNeedingStarterPack, beforeMessage });
      }
    },
  },
  created() {
    window._cart = this;
  },
  mounted() {
    if (MYS.CONFIG.cart && MYS.CONFIG.cart.click2buy && !window.c2bLegrandWishlistMonInterrupteur) {
      this.loadClick2Buy();
      // c2bLegrandWishlistMonInterrupteur.initCta() is called when c2bLegrandWishlistMonInterrupteur object is first instanciated
    }
    if (MYS.CONFIG.cart && MYS.CONFIG.cart.click2buy && typeof window.c2bLegrandWishlistMonInterrupteur !== 'undefined') {
      // then we just call the init
      window.c2bLegrandWishlistMonInterrupteur.initCta();
      this.click2BuyEnabled = true;
    }
    this.pushCartGTM('checkout');
    if (this.hasProject) {
      this.starterPackIsNeededInCart();
    }
  },
};
</script>

<style lang="scss">
.list-complete-item {
  transition: all 0.8s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100px);
  width: 100%;
  z-index: -1;
  // margin-left: 100%;
}
.list-complete-leave-active {
  position: absolute;
}
.turnstile-badge {
  visibility: hidden;
}
.cart.view {
  min-height: 100%;
  position: relative;

  .cart-detail {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .cart-empty {
    h2 {
      margin-top: 100px;
    }
  }
  h2 {
    font-size: 1.1em;
    font-weight: 500;
  }
  .add-product {
    padding-top: 10px;
    padding-bottom: 60px;
    a:hover{
      text-decoration: underline;
    }
  }
  .actions {
    position: fixed;
    background-color: #FFFFFF;
    bottom: 0;
    left:0;
    width: 100%;
    z-index: 1000;

    #turnstile-holder{
      flex-direction: column;

      & > div {
        font-size: 13px;
        font-weight: 500;
        padding: 3px 0;
        box-shadow:0px -4px 4px 0px #FFFFFF;
      }
    }
    .row-action {
      display: flex;
      width: 100%;

      #inv-turnstile-holder {
        display: table-row;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;

        & > div {
          margin-left: auto;
          margin-right: auto;
        }
      }

      a {
        height: 45px;
        padding: 5px 18px;
        font-weight: 500;
        font-size: 13px;
        flex: 1 1 auto;
        -ms-flex-item-align: center;
        align-self: center;
        display: flex;
        cursor: pointer;
        &.download-pdf {
          // margin-right: 2px;
        }
        &.external-links {
          margin-left:1.5%;
          border: 1px solid var(--primary-color);
          background-color: #FFFFFF;
        }

        span {
          display: -ms-flexbox;
          -ms-flex-item-align: center;
          margin: auto;

          .icon {
            font-size: 26px;
            margin-right: 13px;
          }
        }
      }
    }

    &.mobile-actions {
      flex-wrap: wrap;
    }

    &.desktop-actions {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      max-width: 255px;
      margin-top: 56px;

      .desktop-actions-container {
        position: sticky;
        top: 5px;

        #inv-turnstile-holder {
          & > div {
            max-width: 255px;
          }
        }

        .row-action {
          flex-direction: column;

          a {
            width: 100%;

            & + a {
              margin-top: 15px;
            }

            &.form-elec-action {
              position: relative;
              display: flex;
              flex-direction: column;
              height: auto;
              justify-content: center;
              align-items: center;
              padding: 13px;

              img {
                position: absolute;
                right:26px;
                top: 13px;
                width: 50px;
              }

              .icon {
                font-size: 62px;
                margin-top: 25px;
              }

              div {
                margin-top: 13px;
              }

              .button {
                margin-top: 13px;
                background-color: #FFFFFF;

                &:hover {
                  background-color: #FFFFFF;
                  background: #FFFFFF;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  .turnstile-disclaimer {
    position: absolute;
    bottom: 15px;
    width: 100%;
    font-size: 10px;

    a {
      text-decoration: underline;
    }
  }
}
</style>
