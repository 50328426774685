<template lang="pug">
  li.gamme-item.large-padding-top.large-padding-bottom(:class="itemClass" @click="clickHandler")
    img.thumb(:src="imgUrl", :srcset="imgSrcSet")
    .text.text-align-left.tc-white.little-padding.medium-padding-left
      h3 {{ name }}
      p {{ descriptionText }}
    .arrow
      span.mobile-icon-next.tc-white(v-if="isActive")
</template>

<script>
import { L } from '@/libs/core/utils';

export default {
  name: 'gamme-item',
  components: {
  },
  props: {
    slug: String,
    active: Boolean,
    name: String,
    description: String,
    thumbUrl: String,
    thumbRetinaUrl: String,
  },
  data() {
    return {
    };
  },
  computed: {
    isActive() {
      return this.active;
    },
    descriptionText() {
      if (!this.active) {
        return 'Bientôt disponible';
      }
      const desc = L(`${this.slug}-description`);
      if (desc !== (`${this.slug}-description`)) {
        return desc;
      }
      return this.description;
    },
    imgUrl() {
      if (this.thumbUrl) {
        return this.thumbUrl;
      }
      return `/static/mobile/imgs/gammes/${this.slug}.png`;
    },
    imgSrcSet() {
      let normalSrcUrl = `/static/mobile/imgs/gammes/${this.slug}.png`;
      let retinaSrcUrl = `/static/mobile/imgs/gammes/${this.slug}@2x.png`;
      if (this.thumbUrl) {
        normalSrcUrl = this.thumbUrl;
      }
      if (this.thumbRetinaUrl) {
        retinaSrcUrl = this.thumbRetinaUrl;
      }
      return `${normalSrcUrl} 1x, ${retinaSrcUrl} 2x`;
    },
    itemClass() {
      return [
        this.slug,
        `item-${this.active ? 'enabled' : 'disabled'}`,
        { 'cursor-pointer': this.active },
      ];
    },
  },
  methods: {
    clickHandler() {
      if (this.active) {
        this.$emit('click', this.slug);
      }
    },
  },
};
</script>

<style lang="scss">
$borderColor: rgba(255, 255, 255, .7);
.gamme-item {
  width: 100%;
  display: block;
  border-top: 1px solid $borderColor;
  cursor: pointer;
  & > div, & > img {
    display: inline-block;
    vertical-align: middle;
    transition: all 250ms ease;
  }
  &:hover{
    & > div, & > img{
      transform: translateX(10px);
    }
  }
  .thumb {
    width: 67px;
  }
  .arrow {
    width: 20px;
  }
  .text {
    width: calc(100% - 67px - 20px)
  }
  & + .gamme-item {
    border-bottom: 1px solid $borderColor;
  }
  &.item-disabled{
    opacity: .5;
    p {
      color: #333;
    }
  }
}
</style>
