<template lang="pug">
  .cart-indicator
    router-link.cart-button(:to='{ name: "cart" }' :style="{ width: '100%' }" v-on:click.native="closeFormElec()")
      .cart.mobile-icon-paper-bag
        strong.display-inline-block.indicator.vertical-bg-gradient(v-if='hasProductInCart')
          | {{ productQuantiyInCart }}

      .total(v-if="$viewport.displayTotalPriceInCart")
        span.price(v-if="canShowPrice")
          | {{ totalPriceInCart | pricize }}{{ $getCurrency() }} {{ $getTax() }}
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'cart-indicator',
  components: {},
  props: {
    productQuantiyInCart: {
      type: Number,
      default: 0,
    },
    totalPriceInCart: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    closeFormElec() {
      if (this.$hub.formElecOn) {
        this.$hideFormElec();
      }
    },
  },
  computed: {
    canShowPrice() {
      return get(this.$getNativeMYS(), 'CONFIG.layout.showPrice', true);
    },
    hasProductInCart() {
      return this.productQuantiyInCart > 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.cart-indicator {
  margin-right: 25px;
  .cart-button {
    padding-right: 10px;

    .cart {
      position: relative;
      min-width: 50px;
    }
  }
}
a {
  font-size: 22px;
  .price {
    font-size: 15px;
    font-weight: 600;
    color: var(--primary-color);
  }
}
.indicator {
  pointer-events: none;
  $size: 16px;
  $fontSize: 8px;
  color: white;
  font-size: $fontSize;
  padding: ($size - $fontSize - 2) / 2;
  line-height: normal;
  height: $size;
  min-width: $size;
  vertical-align: text-top;
  border-radius: $size / 2 ;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: calc(50% - 0px);
  right: calc(50% - 15px);
  font-family: Roboto,Arial,Verdana,Helvetica,sans-serif;
}
</style>
