/**
 @typedef {Object} Format
 @property {string} id - identifiant du Format
 @property {boolean|string} vertical - est ce que ce Format est vertical ou non. Si non, existe-t-il un format qui corresponde
 @property {boolean|string} horizontal - est ce que ce Format est horizontal ou non. Si non, existe-t-il un format qui corresponde
 @property {composition[]} composition#id - liste des Compositions du Format
 */

/**
 * Représente le Modèle Format.
 * @constructor
 * @param {Format} format - Données de la Format, permettant sa génération.
 * @augments Backbone.Model
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 */
MYS.BB.MODELS.Format = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Format.prototype */
    {
        /**
         * Retourne les coordonnées X, Y, Largeur, Hauteur d'après d'un emplacement particulier.
         * @param {int} posteIndex - Identifiant du poste cible
         * @param {int} moduleIndex - Identifiant du modume cible (au sein du poste précédement ciblé)
         * @param {int} quantité - Nombre de module occupés.
         * @return {Rectangle}
         */
        getRectangleCoordonates: function(posteIndex, moduleIndex, q) {

            var compositionId = this.get("composition#id")[posteIndex];

            if (typeof compositionId === "undefined") {
                console.error("ERROR : Le poste n'existe pas : '" + posteIndex + "'");
            }
            var composition = MYS.COMPOSITIONS.get(compositionId);
            var module = MYS.MODULES.get(composition.get("module#id"));
            if (typeof composition === 'undefined') {
                console.error("ERROR : la composition n'existe pas : '" + compositionId + "'");
            }
            var output = {
                left: composition.get("x") + moduleIndex * module.get("width"),
                top: composition.get("y")
            };
            if (typeof q !== 'undefined') {
                output.width = module.get("width") * q;
                output.height = module.get("height");
            }
            return output;

        },

        /**
         * Retourne les zones possible pour une quantité de module donnée.
         * @param {Module.id} moduleId - Identifiant du module
         * @param {int} quantity - Nombre de module occupés.
         * @return {Rectangle[]}
         */

        getRectangles: function(moduleId, quantity) {

            var module = MYS.MODULES.get(moduleId);
            var compositionsIds = this.get("composition#id");
            var areas = [];
            for (var posteIndex = 0; posteIndex < compositionsIds.length; posteIndex++) {

                var compositionId = compositionsIds[posteIndex];
                var composition = MYS.COMPOSITIONS.get(compositionId);
                if (typeof composition === "undefined") {
                    console.error("ERROR : la composition n'existe pas : '" + compositionId + "'");
                }
                if (composition.get("module#id") !== moduleId) {
                    console.warn("WARNING : la composition n'est pas compatible, le module attendu est '" + composition.get("module#id") + "', le module reçu est : '" + module.get('id') + "'");
                } else {
                    for (var moduleIndex = 0; moduleIndex + quantity <= composition.get("q"); moduleIndex++) {
                        var position = this.getRectangleCoordonates(posteIndex, moduleIndex);

                        areas.push(new MYS.BB.MODELS.Rectangle({
                            left: position.left,
                            top: position.top,
                            width: module.get("width") * quantity,
                            height: module.get("height"),
                            poste: posteIndex,
                            mod: moduleIndex
                        }));
                    }
                }

            }
            if (!areas.length) {
                console.warn("WARNING : aucune area de libre");
            }
            return areas;
        },
        getStructure: function(coef) {
            if (typeof coef === 'undefined') {
                coef = 1
            }
            var plaque = MYS.PLAQUES.findWhere({
                'format#id': this.id
            })
            if (typeof plaque === 'undefined') {
                return false
            }
            var imgPlaque = plaque.get('imgs') && MYS.BIG.get(plaque.get('imgs'))
            if (typeof imgPlaque === 'undefined') {
                return false
            }
            var imageW = imgPlaque.get('w') / MYS.BIG.infos.mm2pixels;
            var imageH = imgPlaque.get('h') / MYS.BIG.infos.mm2pixels;
            var compositionsIds = this.get("composition#id");
            var postes = [];
            for (var posteIndex = 0; posteIndex < compositionsIds.length; posteIndex++) {
                var compositionId = compositionsIds[posteIndex];
                var composition = MYS.COMPOSITIONS.get(compositionId);
                var module = MYS.MODULES.get(composition.get('module#id'))
                if (typeof composition === "undefined") {
                    console.error("ERROR : la composition n'existe pas : '" + compositionId + "'");
                }
                var position = this.getRectangleCoordonates(posteIndex, 0);
                postes.push({
                    left: (position.left + imageW / 2) * coef,
                    top: (position.top + imageH / 2) * coef,
                    width: (module.get("width") * (composition.get('q') || 1)) * coef,
                    height: (module.get("height")) * coef,
                    poste: posteIndex,
                    mod: 0
                });
            }
            return {
                plaque: {
                    width: (imageW) * coef,
                    height: (imageH) * coef
                },
                postes: postes
            }
        },
        getOrientation: function() {
            return (this.get("vertical") === true && this.get("horizontal") !== true);
        },
        /**
         * Invalide le Format si aucune {@link MYS.BB.MODELS.Plaque} n'est associée
         * @return {string|void} La chaine retournée est un descriptif de l'erreur entrainant l'invalidation
         */
        validate: function() {


            if (typeof MYS.PLAQUES.findWhere({
                    "format#id": this.id
                }) === "undefined") {
                return "Aucune MYS.BB.MODELS.Plaque associée";
            }


        }

    });
