/**
 * Représente une Collection de {@link MYS.BB.MODELS.Support}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Support[]} Supports - Ensemble de Supports
 * @augments Backbone.Collection
 */

MYS.BB.COLLECTIONS.Supports = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Supports.prototype */
    {
        model: MYS.BB.MODELS.Support
    });
