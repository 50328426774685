<template lang="pug">
  .miniConfigTest
    .notLoadedContent(v-if="!$MYS.isLoaded")
      button(@click="load") Charger l'application
      p {{ $MYS.isLoaded }}
      p {{ $MYS.progress }}

    .loadedContent(v-else)
      .miniConfig
        select.finition(v-model="currentFinition")
          option(
            v-for="finition in finitions"
            :value="finition.get('id')"
            )
            | {{ finition.get('name') }}

        br
        select.format(v-model="currentFormat")
          option(
            v-for="format in formats"
            :value="format.get('id')"
            )
            | {{ format.get('id') }}

        br
        select.doigt(v-model="currentDoigt")
          option(
            v-for="doigt in doigts"
            :value="doigt"
            )
            | {{ doigt }}

        div.visuel(style="text-align: center")
          div(
            style="position:relative; display: inline-block"
            v-html="visuel")
        h2 {{ inter.get('finition') }}
        h3 {{ visuel }}
</template>

<script>
import { map } from 'lodash';

export default {
  name: 'miniConfigTest',
  data() {
    return {
    };
  },
  watch: {
    '$MYS.isLoaded': {
      handler(value) {
        if (value) {
          this.createInterrupteur();
        }
      },
      immediate: true,
    },
  },
  methods: {
    load() {
      this.$MYS.load();
    },
    createInterrupteur() {
      if (!this.$MYS.isLoaded) {
        return null;
      }
      const inter = this.$create('Interrupteur', {
        rotation: false,
        quantity: 1,
        createdAt: 1544403651692,
        boite: null,
        format: '2_poste_1_x_1poste_celiane',
        fonctions: [
          {
            id: 'legrand-celiane-gsb-interrupteur-soft-gsb',
            poste: 0,
            mod: 0,
          },
          {
            id: 'legrand-celiane-gsb-interrupteur-soft-gsb',
            poste: 1,
            mod: 0,
          },
        ],
        doigt: 'titane',
        finition: 'celiane-tungstene',
      });
      this.inter = this.$setProxyfiedModel(inter);
    },
  },
  computed: {
    currentFinition: {
      get() {
        if (this.inter) {
          return this.inter.get('finition');
        }
        return undefined;
      },
      set(value) {
        if (this.inter) {
          return this.inter.set('finition', value);
        }
        return this;
      },
    },
    visuel() {
      if (this.inter) {
        return this.inter.getVisuel();
      }
      return '';
    },
    currentFormat: {
      get() {
        if (this.inter) {
          return this.inter.get('format');
        }
        return undefined;
      },
      set(value) {
        if (this.inter) {
          this.inter.set('format', value);
        }
        if (this.inter.get('format') !== value) {
          console.warn('[ VUE ] MAJ impossible', this.inter.get('format'), value);
        }
        return this;
      },
    },
    currentDoigt: {
      get() {
        if (this.inter) {
          return this.inter.get('doigt');
        }
        return undefined;
      },
      set(value) {
        if (this.inter) {
          this.inter.set('doigt', value);
        }
        return this;
      },
    },
    finitions() {
      return this.$getAll('finitions').where({ gamme: 'celiane' });
    },
    formats() {
      return map(map(this.inter.getFinition().getPlaques(), 'attributes.format#id'), (id) => this.$get('formats', id));
    },
    doigts() {
      return this.$getAll('fonctions').findAllDoigtsByGamme('celiane') || [];
    },
    interDatas() {
      if (this.inter) {
        return this.inter.getDatas();
      }
      return {};
    },
  },
  mounted() {
    window._miniConfigTest = this;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.miniConfigTest {
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #777777;
  }
}
</style>
