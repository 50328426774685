<template lang="pug">
  a.banner(@click="clickHandler" :href="linkUrl" target="_blank")
    img.main(:src="imgUrl")
    img.hover(:src="imgHoverUrl")
</template>

<script>
export default {
  name: 'ad-banner',
  components: {
  },
  props: {
    link: String,
    img: String,
    imgHover: String,
  },
  data() {
    return {
    };
  },
  computed: {
    imgUrl() {
      if (this.img) {
        return `/static/mobile/imgs/adBanner/${this.img}`;
      }
      return '';
    },
    imgHoverUrl() {
      if (this.imgHover) {
        return `/static/mobile/imgs/adBanner/${this.imgHover}`;
      }
      return '';
    },
    linkUrl() {
      return this.link;
    },
  },
  methods: {
    clickHandler() {
    },
  },
};
</script>

<style lang="scss">
.banner {
  position: relative;
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    transition: opacity 200ms;
    cursor: pointer;

    &.hover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &:hover {
    img.hover {
      opacity: 1;
    }
  }
}
</style>
