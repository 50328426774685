<template lang="pug">
  .list-item
    ul
      selector-item-finish(
        v-for="item in items"
        v-bind="item"
        :key="item.id"
        :size.sync="size"
        @selectItem="clickHandler"
        )

</template>

<script>
import listElements from '@/components/Selector/listElements.vue';
import selectorItemFinish from '@/components/Selector/Item/itemFinish.vue';

export default {
  name: 'list-finishes',
  extends: listElements,
  components: {
    selectorItemFinish,
  },
};
</script>
<style lang="scss">
</style>
