<!-- SvgSprite.vue -->

<template>
  <svg width="0" height="0" style="display: none;">
      <symbol version="1.1" id="dl-pdf" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <path d="M95.9,322.5c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5h123.7c4.1,0,7.5-3.4,7.5-7.5c0-4.1-3.4-7.5-7.5-7.5H95.9z
        M38.5,129.5c4.1,0,7.5-3.4,7.5-7.5V47.5C46,29.6,60.6,15,78.5,15h171.2c1.7,0,3.3,0.2,4.9,0.6V112c0,19.1,15.5,34.6,34.5,34.6h96.3
        c0.4,1.6,0.6,3.2,0.6,4.9V192c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-40.5c0-10-3.9-19.4-11-26.5L276.3,11
        c-7.1-7.1-16.5-11-26.5-11H78.5C52.3,0,31,21.3,31,47.5V122C31,126.1,34.4,129.5,38.5,129.5z M269.7,25.6l105.8,106h-86.2
        c-10.8,0-19.5-8.8-19.5-19.6V25.6z M93.9,217.5h224c4.1,0,7.5-3.4,7.5-7.5c0-4.1-3.4-7.5-7.5-7.5h-224c-4.1,0-7.5,3.4-7.5,7.5
        C86.4,214.1,89.8,217.5,93.9,217.5z M325.4,270L325.4,270c0-4.1-3.4-7.5-7.5-7.5h-224c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5
        h224C322.1,277.5,325.4,274.1,325.4,270z M95.9,157.5h123.7c4.1,0,7.5-3.4,7.5-7.5c0-4.1-3.4-7.5-7.5-7.5H95.9
        c-4.1,0-7.5,3.4-7.5,7.5C88.4,154.1,91.8,157.5,95.9,157.5z M401,297.9V227c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v67.9
        c-4.6-0.6-9.3-0.9-14-0.9c-34.4,0-66.1,15.7-86.9,43.1c-2.5,3.3-1.9,8,1.4,10.5c3.3,2.5,8,1.9,10.5-1.4c18-23.6,45.3-37.2,74.9-37.2
        c6.9,0,13.6,0.7,20,2.2c0,0,0,0,0,0c42.2,9.2,74,46.9,74,91.8c0,51.8-42.2,94-94,94s-94-42.2-94-94c0-9.5,1.4-19,4.2-28
        c1.2-4-1-8.2-4.9-9.4c-4-1.2-8.2,1-9.4,4.9c-3.3,10.5-4.9,21.4-4.9,32.4c0,16,3.5,31.3,9.7,45H206c-4.1,0-7.5,3.4-7.5,7.5
        c0,4.1,3.4,7.5,7.5,7.5h75c19.5,29.5,53,49,91,49c60.1,0,109-48.9,109-109C481,353,447.1,310.7,401,297.9z M372,345
        c-12.4,0-22.5,10.1-22.5,22.5v32.9l-4.6-2.6c-10.9-6-24.6-2-30.6,8.8c-2.9,5.3-3.6,11.3-1.9,17.1c1.7,5.8,5.5,10.6,10.7,13.5l38,21
        c6.6,3.7,15.1,3.7,21.8,0l38-21c5.3-2.9,9.1-7.7,10.7-13.5s1-11.9-1.9-17.1c-6-10.9-19.7-14.8-30.6-8.8l-4.6,2.6v-32.9
        C394.5,355.1,384.4,345,372,345z M406.4,410.9c3.6-2,8.2-0.7,10.2,2.9c2,3.5,0.6,8.3-2.9,10.2l-38,21c-2.3,1.3-5.3,1.2-7.6-0.2
        l-37.7-20.8c-3.6-1.9-5-6.7-2.9-10.2c2-3.6,6.6-4.9,10.2-2.9l15.7,8.7c4.7,2.8,11.3-1,11.1-6.6v-45.6c0-4.1,3.4-7.5,7.5-7.5
        s7.5,3.4,7.5,7.5v45.6c-0.2,5.5,6.4,9.4,11.1,6.6L406.4,410.9z M171,448H78.5C60.6,448,46,433.4,46,415.5V157c0-4.1-3.4-7.5-7.5-7.5
        S31,152.9,31,157v258.5c0,26.2,21.3,47.5,47.5,47.5H171c4.1,0,7.5-3.4,7.5-7.5C178.5,451.4,175.1,448,171,448z"/>
      </symbol>
      <symbol version="1.1" id="form-elec" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <path d="M493.1,357.4c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5c-0.6,131,1.1,126.1-1.2,131.4l-142-136.1c22.3-12,126-67.9,143.3-77.3
        v47c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5c0-49.2,0-68.4,0-70.1c-0.8-14.2-7.7-25.8-18.3-33.5l-34.6-25.2v-39.9
        c6.7-3.7,7.6-9.6,10.7-14.9c3.2-6.6,10.2-5.1,15.4-6.4c9.5-1.4,16-10.3,14.4-19.8c-0.3-5-3.9-11.5,1.3-16.5l6-5.9
        c6.9-6.7,6.9-17.8,0-24.5c-3.3-4-10-7.1-8.8-14.3l1.4-8.1c1.6-9.5-4.9-18.4-14.4-19.8c-4.9-1.3-12.2,0.1-15.4-6.4
        c-2.9-4.6-4.2-13.1-13.8-16.3c-9.6-3.1-15.6,3-20.6,5C410,9.3,405.2,3.9,400.3,2c-8.4-4.4-19-1.2-23.3,7.6
        c-2.8,4.4-3.6,11.7-10.9,12.7c-5.2,1.3-13.7-0.1-19.6,8.1c-1.6,2.2-2.6,4.7-3,7.4H203.9c-4.1,0-7.5,3.4-7.5,7.5
        c0,4.1,3.4,7.5,7.5,7.5H345c0.3,10.1-14,11-14,25.2c0,12.9,12.1,15.2,13.8,22.9c1.5,6.7-6.9,18.6,5,28.1c5.3,4.2,10.1,3.5,16.4,4.7
        c7.2,1.1,8,8.3,10.9,12.7c2.9,5.9,8.8,9.5,15.3,9.5c10.3,0,15.7-10.2,24.1-5.8c5.1,2.7,7.3,3.9,8.7,4.5V287l-161,86.8
        c-5,2.7-11.3,2.7-16.4,0L86.9,287V70.5c0-9.8,8-17.8,17.8-17.8h64.2c4.1,0,7.5-3.4,7.5-7.5c0-4.1-3.4-7.5-7.5-7.5h-64.2
        c-18.1,0-32.8,14.7-32.8,32.8v123.1l-34.6,25.2c-10.5,7.6-17.4,19-18.3,33.5c0,0.1-0.4,231.4,0.4,236c2.5,13.5,14.4,23.7,28.3,23.7
        h290c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5H48.3l142.8-136.8c52.2,28.1,49.8,26.9,51.5,27.7c7,3.2,15,3.9,22.6,1.6
        c5.8-1.7,4.6-1.9,55.9-29.3L463.7,497h-91c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h91.6c15.8,0,28.2-13,28.7-27
        C493.2,481.7,493.1,487.9,493.1,357.4z M440.2,212.2L466,231c14.6,10.6,11.9,26.1,12.2,27.4l-38,20.5V212.2z M395.7,139.4
        c-2.5,1.4-2.8,1.5-3.3,1.5c-4.5,0-4.7-19.3-24.1-22.1c-12.2-2.2-10.6,1-8.6-11.8c3.2-19-14.3-25.4-13.7-29.4
        c0.5-3.1,16.8-10.7,13.7-28.7c0-0.1-0.9-5.3-0.8-4.9c-0.2-1-0.6-3.3-0.5-3.2c-1-6.1,19.1,1.8,28.4-17c6.1-11.2,2.4-10.3,14-4.6
        c14.8,7.8,26.6-2.7,30.1-3.9c1.1-0.6,2.3-0.1,2.9,0.9l3.7,7.6c9.3,18.9,29.5,10.8,28.4,17c0,3.8-6.3,18.1,5.6,29.8
        c8.9,9.1,8.9,5.6,0,14.8c-13.5,13.1-3.8,28.4-6,31.4c-1,1.3-2.2,0.7-9.6,2c-16.5,2.4-20,17.9-22.2,21c-0.6,1.1-1.9,1.4-2.9,0.9
        C418.7,134.4,413.1,129.8,395.7,139.4z M46.1,231l25.8-18.8v66.7l-38-20.5C34.3,256.8,31.3,241.7,46.1,231z M35.1,488.8
        c-3-7-0.1-2.4-1.2-213.4c45,24.3,96.7,52.2,143.3,77.3L35.1,488.8z M441.7,53.8c-3-2.8-7.8-2.6-10.6,0.4l-28.7,31.2l-9.2-10.6
        c-2.7-3.1-7.4-3.5-10.6-0.8c-3.1,2.7-3.5,7.4-0.8,10.6l14.7,17c2.9,3.4,8.1,3.5,11.2,0.2l34.4-37.4
        C444.9,61.3,444.7,56.6,441.7,53.8z M355.2,191.3H186.4c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h168.8c4.1,0,7.5-3.4,7.5-7.5
        S359.3,191.3,355.2,191.3z M219.6,119.9h-66.4c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5h66.4c4.1,0,7.5-3.4,7.5-7.5
        S223.7,119.9,219.6,119.9z M355.2,222.3H186.4c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h168.8c4.1,0,7.5-3.4,7.5-7.5
        S359.3,222.3,355.2,222.3z M355.2,284.4H186.4c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h168.8c4.1,0,7.5-3.4,7.5-7.5
        S359.3,284.4,355.2,284.4z M355.2,253.4H186.4c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h168.8c4.1,0,7.5-3.4,7.5-7.5
        S359.3,253.4,355.2,253.4z M154,205.7c0.5,0.2,0.9,0.3,1.4,0.4c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.5-0.1c0.5-0.1,1-0.2,1.4-0.4
        c0.4-0.2,0.9-0.4,1.3-0.7c0.4-0.3,0.8-0.6,1.1-0.9c1.4-1.4,2.2-3.3,2.2-5.3c0-0.5,0-1-0.1-1.5c-0.1-0.5-0.2-0.9-0.4-1.4
        c-0.2-0.5-0.4-0.9-0.7-1.3c-0.3-0.4-0.6-0.8-0.9-1.1c-0.3-0.3-0.7-0.7-1.1-0.9c-0.4-0.3-0.8-0.5-1.3-0.7c-0.5-0.2-0.9-0.3-1.4-0.4
        c-1-0.2-2-0.2-2.9,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.9,0.4-1.3,0.7c-0.4,0.3-0.8,0.6-1.1,0.9c-0.3,0.3-0.7,0.7-0.9,1.1
        c-0.3,0.4-0.5,0.8-0.7,1.3c-0.2,0.5-0.3,0.9-0.4,1.4c-0.1,0.5-0.1,1-0.1,1.5c0,2,0.8,3.9,2.2,5.3c0.4,0.3,0.7,0.7,1.1,0.9
        C153.1,205.3,153.5,205.5,154,205.7z M149.9,263.7c0.2,0.5,0.4,0.9,0.7,1.3c0.3,0.4,0.6,0.8,0.9,1.1c0.3,0.4,0.7,0.7,1.1,0.9
        c0.4,0.3,0.8,0.5,1.3,0.7c0.4,0.2,0.9,0.3,1.4,0.4c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.5-0.1c0.5-0.1,1-0.2,1.4-0.4
        c0.5-0.2,0.9-0.4,1.3-0.7c0.4-0.3,0.8-0.6,1.1-0.9c0.4-0.3,0.7-0.7,0.9-1.1c0.3-0.4,0.5-0.8,0.7-1.3c0.2-0.5,0.3-0.9,0.4-1.4
        c0.1-0.5,0.1-1,0.1-1.5c0-2-0.8-3.9-2.2-5.3c-0.4-0.3-0.7-0.7-1.1-0.9c-0.4-0.3-0.8-0.5-1.3-0.7c-0.5-0.2-0.9-0.3-1.4-0.4
        c-1-0.2-2-0.2-2.9,0c-0.5,0.1-1,0.2-1.4,0.4c-0.5,0.2-0.9,0.4-1.3,0.7c-0.4,0.3-0.8,0.6-1.1,0.9c-1.4,1.4-2.2,3.3-2.2,5.3
        c0,0.5,0.1,1,0.1,1.5C149.6,262.8,149.7,263.3,149.9,263.7z"/>
      </symbol>
      <symbol version="1.1" id="selectbox-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 25 25" style="enable-background:new 0 0 20 20;" xml:space="preserve">
        <path d="M 12.5 21.875 L 7.03125 16.40625 L 8.132812 15.304688 L 12.5 19.664062 L 16.867188 15.304688 L 17.96875 16.40625 Z M 12.5 3.125 L 17.96875 8.59375 L 16.867188 9.695312 L 12.5 5.335938 L 8.132812 9.695312 L 7.03125 8.59375 Z M 12.5 3.125 "/>
      </symbol>
  </svg>
</template>

<script>

export default {
  name: 'SvgSprite',
};
</script>
