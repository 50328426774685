/**
 * Représente une Collection de {@link MYS.BB.MODELS.Format}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Format[]} formats - Ensemble de formats
 * @augments Backbone.Collection
 */

MYS.BB.COLLECTIONS.Formats = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Formats.prototype */
    {
        model: MYS.BB.MODELS.Format,
        /**
         * Trouve le format répondant à un ID et un sens de rotation. Si la par défaut l'id demandé n'est pas visible dans l'orientation voulue, la fonction trouve la correspondance.
         * @param  {string}  id        identifiant d'un Format
         * @param  {Boolean} isRotated TRUE si le format attendu est vertical, FALSE s'il doit être horizontal
         * @return {MYS.BB.MODEL.Format} Format répondant aux critères de recherche
         */
        getFormat: function(id, isRotated) {
            var format = this.get(id);
            if (format !== undefined) {

                var conversion = true;
                if (isRotated) {
                    // vertical
                    conversion = format.get("vertical");

                } else {
                    // horizontal
                    conversion = format.get("horizontal");
                }

                if (conversion === true) {
                    // le format est adapté, on ne fait rien
                    return this.get(id);
                } else if (conversion === false) {
                    // le format n'est pas compatible
                    // console.error("ERROR : la plaque ne peut pas s'afficher dans ce sens.");
                } else if (_.isString(conversion)) {
                    return this.get(conversion);
                }
            }
        },
        /**
         * Retourne les formats {@link MYS.BB.MODELS.Format Format}
         * compatible avec avec un {@link MYS.BB.MODELS.Module Module} et quantité défini.
         * @param {string} moduleId - identifiant d'une {@link MYS.BB.MODELS.Module}
         * @param {string} q - encombrement
         * @return {MYS.BB.MODELS.Format[]}
         */
        findAllByModuleAndSize: function(moduleId, q) {
            var formats = [];
            formats = this.filter(function(format, indexFormat) {
                var compositionIds = format.get("composition#id");
                if (typeof compositionIds == "undefined") return false;
                for (i in compositionIds) {
                    compositionId = compositionIds[i];
                    var composition = MYS.COMPOSITIONS.get(compositionId);
                    if (typeof composition == "undefined") continue;
                    if (composition.get("module#id") == moduleId && composition.get("q") >= q) {
                        return true;
                    }
                }
            });

            return _.pluck(formats, 'id');
        },


    });
