<template lang="pug">
  div.configuration.view(:class="getConfigurationViewClasses")
    div.fingers-container(v-if="showFingers")
      div
      div.center
        interrupteur-fingers(
        :gamme='gamme'
        :fingers='fingers'
        :active.sync="fingerSelected"
        )
        .finger-title.display-table(style="width: 100%;vertical-align:middle;")
          .display-table-cell.vertical-align-middle(style="height: 100%;")
            p.display-inline-block couleur fonction
      div
    .vertical-center(:style='verticalWrapperStyle')
      camera
      interrupteur(
          :gamme='gamme'
          v-if="displayed"
        )
      div.addToCart-wrapper
        button.addToCart.button.large-margin-top.tc-white.upper.vertical-bg-gradient(
          @click="addToCart"
          )
          i.mobile-icon-paper-bag-add
          | Ajouter à ma sélection

      interrupteur-details(v-if='hasRefs' :active.sync="detailsAreOpened", :refs="refs")
    drag
    selector(
      v-if='displayDesktopSelector'
      :gamme='gamme'
      @finition='finitionClickHandler'
      @fonction='fonctionClickHandler'
      @format='formatClickHandler'
      :isOpened.sync="selectorIsOpened"
      @clickAdd="addToCart"
      @changeHeight="changeHeightHandler"
      )
    mobile-selector(
      v-else
      :gamme='gamme'
      @finition='finitionClickHandler'
      @fonction='fonctionClickHandler'
      @format='formatClickHandler'
      :isOpened.sync="selectorIsOpened"
      )
</template>

<script>
import {
  each, includes, keys, isEmpty, debounce, orderBy, get, filter, find,
} from 'lodash';

import selector from '@/components/Selector/selector.vue';
import interrupteur from '@/components/Interrupteur/interrupteur.vue';
import interrupteurDetails from '@/components/Interrupteur/details.vue';
import interrupteurFingers from '@/components/Interrupteur/fingers.vue';
import drag from '@/components/Interrupteur/drag.vue';
import foundOnePlaqueForThisFunction from '@/views/messages/foundOnePlaqueForThisFunction.vue';
import foundManyPlaqueForThisFunction from '@/views/messages/foundManyPlaqueForThisFunction.vue';
import functionsNeedStarterPack from '@/views/messages/functionsNeedStarterPack.vue';
import camera from '@/components/Camera/camera.vue';
import mobileSelector from '@/components/Selector/mobileSelector.vue';

export default {
  name: 'configuration',
  components: {
    interrupteur,
    selector,
    interrupteurDetails,
    interrupteurFingers,
    drag,
    camera,
    mobileSelector,
  },
  data() {
    return {
      displayInter: true,
      requireMounted: false,
      selectedGamme: false, // 'celiane',
      detailsAreOpened: false,
      selectorIsOpened: true,
      selectorsHeight: 0,
    };
  },
  watch: {
    '$hub.inter': {
      handler(value) {
        if (!value) return;
        this.removeListeners();
        this.setListerners();
      },
      immediate: true,
    },
    '$hub.inter.attributes.format': {
      handler(value) {
        if (value) {
          this.updateDarkModeOnInterChanges();
        }
      },
      immediate: true,
    },
    '$hub.inter.attributes.fonctions': {
      handler(value) {
        if (value) {
          this.updateDarkModeOnInterChanges();
        }
      },
      immediate: true,
    },
    detailsAreOpened(value) {
      if (value && !this.$viewport.useLateralConfigurationMenu) {
        this.selectorIsOpened = false;
      }
    },
    selectorIsOpened(value) {
      if (value) {
        this.detailsAreOpened = false;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.putGamme();
  },
  methods: {
    updateDarkModeOnInterChanges() {
      if (!this.$hub.inter.isLuminous() && this.$hub.darkMode) {
        this.$hub.darkMode = false;
      }
    },
    removeListeners() {
      this.$hub.inter.off('warning.noSolutionPleaseAdjust', this.warningNoSolutionPleaseAdjust);
      this.$hub.inter.off('warning.noPlaqueFoundWithThisFinitionAndFormat', this.warningNoPlaqueFoundWithThisFinitionAndFormat);
      this.$hub.inter.off('warning.noPlaqueFoundWithThisFinitionAndFormatAndWillEraseFunctions', this.warningNoPlaqueFoundWithThisFinitionAndFormatAndWillEraseFunctions);
      this.$hub.inter.off('warning.noFunctionWithThisFinition', this.warningNoFunctionWithThisFinition);
      this.$hub.inter.off('warning.noSyncedDoigtWithThisFinition', this.warningnoSyncedDoigtWithThisFinition);
      this.$hub.inter.off('message:notCompatiblePlaque', this.messageNotCompatiblePlaque);
      this.$hub.inter.off('message:warning.fonctionIncompatibleFormat', this.warningFonctionIncompatibleFormat);
    },
    setListerners() {
      this.$hub.inter.on('warning.noSolutionPleaseAdjust', this.warningNoSolutionPleaseAdjust);
      this.$hub.inter.on('warning.noPlaqueFoundWithThisFinitionAndFormat', this.warningNoPlaqueFoundWithThisFinitionAndFormat);
      this.$hub.inter.on('warning.noPlaqueFoundWithThisFinitionAndFormatAndWillEraseFunctions', this.warningNoPlaqueFoundWithThisFinitionAndFormatAndWillEraseFunctions);
      this.$hub.inter.on('warning.noFunctionWithThisFinition', this.warningNoFunctionWithThisFinition);
      this.$hub.inter.on('warning.noSyncedDoigtWithThisFinition', this.warningnoSyncedDoigtWithThisFinition);
      this.$hub.inter.on('message:notCompatiblePlaque', this.messageNotCompatiblePlaque);
      this.$hub.inter.on('message:warning.fonctionIncompatibleFormat', this.warningFonctionIncompatibleFormat);
    },
    warningnoSyncedDoigtWithThisFinition({ finitionId, functionsToRemove }) {
      const { FINITIONS, FONCTIONS } = this.$getNativeMYS();
      const functionsList = functionsToRemove.map((funct) => {
        const fonction = FONCTIONS.get(funct.id);
        return fonction.get('nom');
      }).join(', ');

      const finition = FINITIONS.get(finitionId).get('name');

      const title = functionsToRemove.length > 1 ? 'Suppression de fonctions' : 'Suppression de fonction';
      const text = functionsToRemove.length > 1
        ? `Les fonctions ${functionsList} sont incompatibles avec la finition "${finition}" et seront retirées.`
        : `La fonction ${functionsList} est incompatible avec la finition "${finition}" et sera retirée`;

      this.$modal.show('dialog', {
        title,
        text,
        buttons: [
          { title: 'Annuler' },
          {
            title: 'Ok',
            default: true,
            class: 'vue-dialog-button vertical-bg-gradient',
            handler: () => {
              this.$modal.hide('dialog');
              this.$hub.inter.attributes.finition = finitionId;
              this.$hub.inter.attributes.fonctions = filter(this.$hub.inter.attributes.fonctions,
                (funct) => !find(functionsToRemove,
                  (functionToRemove) => funct.poste === functionToRemove.poste));
              this.$hub.inter.trigger('change');
            },
          },
        ],
      });
    },
    warningNoFunctionWithThisFinition() {
      this.$modal.show('dialog', {
        title: 'Information',
        text: 'Cette fonction n’est pas disponible dans cette finition.',
        buttons: [
          {
            title: 'Annuler',
          },
        ],
      });
    },
    noPlaqueCompatibleFound() {
      this.$hub.removeFunctionToAdd();
      this.$modal.show('dialog', {
        title: 'Action impossible',
        text: 'Il n\'existe aucune plaque permettant d\'accueillir cette fonction.',
        buttons: [
          {
            title: 'Ok',
            default: true,
            class: 'vertical-bg-gradient',
          },
        ],
      });
    },
    foundManyPlaqueForThisFunction(plaqueIds, interPossibleConfig) {
      this.$modal.show('dialog', {
        title: 'Changement de plaque',
        component: foundManyPlaqueForThisFunction,
        props: {
          text: 'La fonction sélectionnéee n\'est pas compatible avec le format de votre plaque, vous pouvez choisir une solution parmi les suivantes : ',
          inter: interPossibleConfig,
          plaqueIds,
          select: (interSelected) => {
            this.$modal.hide('dialog');
            this.$hub.inter.attributes.fonctions = undefined;
            this.$hub.inter.attributes.format = undefined;
            this.$hub.inter.attributes = interSelected.toJSON();
            this.$hub.inter.trigger('change');
          },
        },
        buttons: [
          { title: 'Annuler' },
        ],
      });
    },
    messageNotCompatiblePlaque({ fonctionId, finitionId, doigtId }) {
      this.$hub.removeFunctionToAdd();
      // let yes = true // for debugging
      const { FONCTIONS } = this.$getNativeMYS();
      const { FINITIONS } = this.$getNativeMYS();
      const fonction = FONCTIONS.get(fonctionId);
      const moduleId = fonction.get('module#id');
      const q = fonction.get('q');
      // AUCUNE PLAQUE POSSILBLE
      const formatsPossibles = this.$getNativeMYS().FORMATS.findAllByModuleAndSize(moduleId, q);
      const plaqueIds = FINITIONS.findAllCheapestPlaquesByFinitionByFormat(formatsPossibles);
      if (!formatsPossibles.length || !plaqueIds.length) {
        this.noPlaqueCompatibleFound();
        return;
      }
      const plaque = FINITIONS.get(finitionId).getCheapestPlaqueByFormat(formatsPossibles);

      const interPossibleConfig = {
        finition: finitionId,
        fonctions: [{
          id: fonctionId,
          poste: 0,
          mod: 0,
        }],
        doigt: doigtId,
      };

      // PLUSIEURS PLAQUE POSSILBLES
      if (plaqueIds.length && !plaque) {
        this.foundManyPlaqueForThisFunction(plaqueIds, interPossibleConfig);
        return;
      }

      // UNE SEULE PLAQUE POSSILBLE
      const format = plaque.getFormat();
      const rotation = format.get('vertical') === true && format.get('horizontal') !== true;
      const interWithGoodFormat = { ...interPossibleConfig, format: plaque.get('format#id'), rotation };
      if (this.canDisplayFoundOnePlaqueForThisFunction()) {
        this.$modal.show('dialog', {
          title: 'Changement de plaque',
          component: foundOnePlaqueForThisFunction,
          props: {
            text: 'La fonction sélectionnéee n\'est pas compatible avec le format de votre plaque, ce qui produira le changement suivant : ',
            inter: interWithGoodFormat,
          },
          buttons: [
            { title: 'Annuler' },
            {
              title: 'Ok',
              default: true,
              class: 'vue-dialog-button vertical-bg-gradient',
              handler: () => {
                this.$modal.hide('dialog');
                this.$hub.inter.attributes.fonctions = undefined;
                this.$hub.inter.attributes.format = undefined;
                this.$hub.inter.attributes = this.$create('Interrupteur', interWithGoodFormat).toJSON();
                this.$hub.inter.trigger('change');
              },
            },
          ],
        });
        return;
      }
      this.$hub.displayInter = false;
      this.$hub.inter.attributes.fonctions = undefined;
      this.$hub.inter.attributes.format = undefined;
      this.$hub.inter.attributes = this.$create('Interrupteur', interWithGoodFormat).toJSON();
      this.$hub.inter.trigger('change');
      setTimeout(() => { this.$hub.displayInter = true; }, 500);
    },

    canDisplayFoundOnePlaqueForThisFunction() {
      const gamme = this.$hub.getCurrentInterGamme();
      return gamme && !(gamme.get('preventWarningFoundOnePlaqueForThisFunction') === true);
    },
    warningFonctionIncompatibleFormat() {
      // on change de format, mais il y a des pertes, il faut demander confirmation
      this.$modal.show('dialog', {
        title: 'Information',
        text: "Configuration impossible.<br/> Il n'est pas possible d'associer les produits sélectionnés.",
        buttons: [
          {
            title: 'Annuler',
          },
        ],
      });
    },
    warningNoPlaqueFoundWithThisFinitionAndFormatAndWillEraseFunctions({ finitionId }) {
      this.$hub.removeFunctionToAdd();
      // on change de format, mais il y a des pertes, il faut demander confirmation
      this.$modal.show('dialog', {
        title: 'Information',
        text: 'Cette finition n\'existe pas dans la dimension actuelle, nous allons devoir supprimer les fonctions que vous avez choisies.',
        buttons: [
          {
            title: 'Annuler',
          }, {
            title: 'Ok',
            default: true,
            class: 'vue-dialog-button vertical-bg-gradient',
            handler: () => {
              this.$modal.hide('dialog');
              this.$hub.inter.set('finition', finitionId);
            },
          },
        ],
      });
    },
    warningNoPlaqueFoundWithThisFinitionAndFormat({ finitionId }) {
      this.$hub.removeFunctionToAdd();
      // on change de format, mais il n'y a pas de perte, donc pas grave
      this.$hub.inter.set('finition', finitionId);
    },
    warningNoSolutionPleaseAdjust({ formatId }) {
      this.$hub.removeFunctionToAdd();
      const fonction = orderBy(this.$hub.inter.attributes.fonctions, ['poste', 'mod'], ['desc', 'desc'])[0];
      if (fonction) {
        this.$hub.inter.deleteFonction(fonction.poste, fonction.mod);
        this.$hub.inter.set('format', formatId);
      }
    },
    changeHeightHandler(selectorHeight) {
      this.selectorsHeight = selectorHeight;
    },
    addToCart(event) {
      let from = '.button.large-margin-top.tc-white.upper.vertical-bg-gradient';
      const to = '.display-inlin-block.indicator.vertical-bg-gradient';

      if (event.target.className.indexOf('addToCartSelector') !== -1) {
        const divFrom = document.createElement('div');
        divFrom.className = 'button large-margin-top tc-white upper vertical-bg-gradient temporaryAddToCart';
        divFrom.style.width = '50px';
        document.getElementsByClassName('selectors')[0].prepend(divFrom);
        from = '.button.large-margin-top.tc-white.upper.vertical-bg-gradient.temporaryAddToCart';
      }
      this.$store.commit('morpher/add', {
        from,
        to,
      });
      if (document.getElementsByClassName('temporaryAddToCart').length > 0) {
        setTimeout(() => {
          each(document.getElementsByClassName('temporaryAddToCart'), (item) => {
            if (item) {
              item.remove();
            }
          });
        }, 5);
      }
      this.$addToCart(this.$hub.inter);
    },
    finitionClickHandler(finitionId) {
      const canSetFinition = this.$hub.inter.canSetFinition(finitionId, false) === true;
      if (canSetFinition) {
        this.$hub.inter.set('finition', finitionId);
        if (this.syncFingersWithFinition) {
          this.$hub.inter.set('doigt', finitionId);
        }
      }
    },
    formatClickHandler(formatId) {
      this.$hub.inter.set('format', formatId);
    },
    fonctionClickHandler(fonctionId) {
      // il faut supprimer la fonction en place, le remplacement n'est pas possible
      this.$hub.inter.deleteFonction(0, 0);
      this.$hub.inter.set('fonctions', { id: fonctionId, mod: 0, poste: 0 });
    },
    putGamme() {
      if (!this.$route || !this.$route.params.gamme || !includes(keys(this.$getNativeMYS().CONFIG.doigts), this.$route.params.gamme)) {
        this.selectedGamme = keys(get(this.$getNativeMYS(), 'CONFIG.doigts', {}))[0];
        return false;
      }
      this.selectedGamme = this.$route.params.gamme;
      if (this.$hub.inter && this.$hub.inter.getGamme() === this.selectedGamme) {
        return true;
      }
      this.$hub.createInterrupteur({ gamme: this.selectedGamme });
      this.initInterChangeGTM();
      this.$hub.inter.trigger('change');
      return true;
    },
    getConfigHeight() {
      if (document.getElementsByClassName('inter').length > 0) {
        const addToCartHeight = document.getElementsByClassName('addToCart').length > 0 ? document.getElementsByClassName('addToCart')[0].offsetHeight : 0;

        return document.getElementsByClassName('interrupteur-fingers')[0].offsetHeight
        + document.getElementsByClassName('inter')[0].offsetHeight
        + addToCartHeight
        + document.getElementsByClassName('interrupteur-details')[0].offsetHeight;
      }
      return 0;
    },
    getHeadHeight() {
      return document.getElementById('nav').offsetHeight;
    },
    getAvailableHeight() {
      return this.$viewport.height - this.getConfigHeight() - this.getHeadHeight() - this.selectorsHeight;
    },
    canDisplayAll() {
      return (this.getAvailableHeight() > 0);
    },
    initInterChangeGTM() {
      this.$gtm.watch([{
        model: this.$hub.inter,
        refs: this.$hub.inter.getRefs,
        callbacks: {
          change: (products) => {
            const detail = {
              actionField: {
                list: 'Configurateur',
              },
            };
            const productsArray = [];
            products.forEach(({
              id, name, price, brand, category, variant, quantity,
            }) => {
              for (let index = 0; index < quantity; index++) {
                productsArray.push({
                  id,
                  name,
                  price,
                  brand,
                  category,
                  variant,
                });
              }
            });
            detail.products = productsArray;
            const evt = {
              event: 'productDetail',
              ecommerce: {
                detail,
              },
            };
            this.$gtm.push(evt);
          },
        },
      }]).init();
    },
  },
  computed: {
    displayDesktopSelector() {
      return this.$viewport.useLateralConfigurationMenu;
    },
    getConfigurationViewClasses() {
      return [
        this.darkModeClass,
        (this.$camera.active) ? 'camera-enabled' : '',
        (this.$camera.animation) ? 'camera-animation' : '',
        (this.$camera.showInterface) ? 'show-interface' : '',
        (this.$camera.process) ? this.$camera.process : '',
      ];
    },
    displayed() {
      return this.displayInter;
    },
    darkModeClass() {
      return this.$hub.darkMode ? 'darkModeEnabled' : '';
    },
    syncFingersWithFinition() {
      const gamme = this.$hub.getCurrentInterGamme();
      return gamme && gamme.get('syncFingersWithFinition') === true;
    },
    showFingers() {
      return !this.syncFingersWithFinition && this.fingers && this.fingers.length > 1;
    },
    marginForMenu() {
      const margin = this.selectorsHeight - 33; // get missing space (security space 7px)
      return { height: `${margin}px` };
    },
    verticalWrapperStyle() {
      this.requireMounted; // eslint-disable-line no-unused-expressions
      const configElementContainer = document.querySelector('.vertical-center');
      const configElementContainerSize = configElementContainer ? configElementContainer.getBoundingClientRect().top || 0 : 0;
      const interElementContainerSize = this.$hub.interVisualHeight || 0;
      let paddingTop = this.$viewport.height / 2 - configElementContainerSize - interElementContainerSize;
      paddingTop = Math.max(paddingTop, 0); // pas de valeur négative
      if (paddingTop < 50) {
        paddingTop = 50; // pas de valeur trop petite
      } else {
        paddingTop -= 25; // fix optique pour relever l'interrupteur audessus de la ligne centrale
      }
      paddingTop = this.roundMore(paddingTop);
      return {
        'padding-top': `${paddingTop}px`,
      };
    },
    cartAmount() {
      return this.$hub.project.get('wishlists').at(0).getPrix();
    },
    hasRefs() {
      return !isEmpty(this.refs);
    },
    refs() {
      if (!this.$hub.inter) {
        return [];
      }
      return this.$hub.inter.getRefs();
    },
    fingers() {
      return this.$getNativeMYS().CONFIG.doigts[this.gamme];
    },
    fingerSelected: {
      get() {
        return this.$hub.inter ? this.$hub.inter.get('doigt') : null;
      },
      set(value) {
        this.$hub.inter.set('doigt', value);
      },
    },
    gamme() {
      return this.selectedGamme;
    },
  },
  destroyed() {
    this.removeListeners();
  },
  mounted() {
    this.requireMounted = Date.now();
    this.putGamme();
    window._config = this;
  },
};
</script>

<style lang="scss">
$animation-speed: .15s;
.fingers-container {
  display: flex;
  transition: all 250ms ease;
  & > :not(.center) {
    flex-grow: 1;
    flex-basis: 40px;
    p {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: right;
    }
  }
}
@media screen and (max-width: 375px) {
  .fingers-container {
    & > :not(.center) {
      visibility: hidden;
    }
  }
}
.finger-title * {
  font-weight: 500;
}
.configuration .addToCart{
  &:active {
    background: var(--primary-variant-color);
    background: -moz-linear-gradient(top, var(--primary-variant-color) 0%, var(--primary-color) 100%);
    background: -webkit-linear-gradient(top, var(--primary-variant-color) 0%, var(--primary-color) 100%);
    background: linear-gradient(to bottom, var(--primary-variant-color) 0%, var(--primary-color) 100%);
  }
}
.configuration.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .vertical-center {
    transition: padding-top $animation-speed linear;
    flex-grow: 1;
    position: relative;
  }
}
.visuel-container {
  text-align: center;
  .visuel {
    position:relative;
    display: inline-block;
  }
}
.temporaryAddToCart {
  width: 60px;
  height: 60px;
  position: absolute;
  left: calc(100vw - 50px + 1px);
  top: calc(((50px / 2) - 2px) * -1);
}
.is-lateral-configuration-menu {
  .fingers-container,
  .configuration.view .vertical-center{
    padding-left: 350px;
  }
}
@media only screen and (min-width: 1920px){
  .is-lateral-configuration-menu {
    .fingers-container,
    .configuration.view .vertical-center{
      padding-left: 0;
    }
  }
}
.darkModeEnabled {
  background-color: #262627;
  .fingers-container {
    color: white;
  }
}
.camera-process-video,
.camera-process-photo,
.camera-enabled{
  .addToCart-wrapper, .interrupteur-details{
    display: none;
  }
  .fingers-container{
    transform: translateY(-200%);
  }
}
</style>
