<template lang="pug">
  #app-mobile(:style="appStyle", :class="appClasses")
    link(href="https://fonts.googleapis.com/css?family=Roboto:300,500" rel="stylesheet")
    svg-sprite
    loader(
      v-if="!$MYS.isLoaded"
      :progress='$MYS.progress'
      :messages='$MYS.loadErrors'
      )
    template(v-else)
      #nav-mobile
        div.navigation-container
          div.available-left-container(v-if="availableLeftContainerWidth && displayAvailableLeftContainer" :style="{ width: availableLeftContainerWidth }")
          div.back-button-container
            router-link.back-button.mobile-icon-left-arrow(v-if="hasBackUrl" :to='backUrl' v-on:click.native="closeFormElec()")
            a.back-button.mobile-icon-left-arrow(v-else-if="canGoHome" href='/')

        div.home-button-container
          a.home-button(v-if="!isStandAlone" href='/') Mon-interrupteur.fr
          span.home-button(v-else) Mon-interrupteur.fr
          img.logo-legrand(src='/static/mobile/imgs/logo-legrand.png' v-if="$viewport.displayLegrandLogo && availableLegrandLogo")

        div.cart-container
          div.available-right-container(v-if="availableRightContainerWidth && displayAvailableRightContainer" :style="{ width: availableRightContainerWidth }")
          div.cart-button-container
            cart-indicator(v-if="canUseInternalCart" :productQuantiyInCart="productQuantiyInCart" :totalPriceInCart="totalPriceInCart")

      .content()
        transition(:name="transitionName" mode="out-in")
          router-view
    morph(v-for='morph in morphers' :key='morph.id' v-bind="morph" @ended="deleteMorpher")
    v-dialog
</template>

<script>
import loader from '@/components/Loader/loader.vue';
import cartIndicator from '@/components/Cart/indicator.vue';
import Morph from '@/components/Morph/index.vue';
import { get } from 'lodash';
import SvgSprite from '@/components/SvgSprite.vue';
import functionsNeedStarterPack from '@/views/messages/functionsNeedStarterPack.vue';

export default {
  name: 'App',
  components: {
    loader, cartIndicator, Morph, SvgSprite,
  },
  props: {
  },
  data() {
    return {
      transitionName: 'slide-left',
    };
  },
  watch: {
    '$hub.project': {
      handler(value) {
        if (!value) return;
        this.removeListeners();
        this.setListerners();
      },
      immediate: true,
    },
  },
  methods: {
    load() {
      this.$MYS.load();
    },
    deleteMorpher(id) {
      this.$store.commit('morpher/remove', id);
    },
    closeFormElec() {
      if (this.$hub.formElecOn) {
        this.$hideFormElec();
      }
    },
    removeListeners() {
      this.$hub.project.off('warning.functionsNeedStarterPack', this.warningFunctionsNeedStarterPack);
    },
    setListerners() {
      this.$hub.project.on('warning.functionsNeedStarterPack', this.warningFunctionsNeedStarterPack);
    },
    warningFunctionsNeedStarterPack({ functionsNeedingStarterPack, beforeMessage, afterMessage }) {
      if (this.$hub.avoidFunctionsNeedingStarterPackMessage) return;

      const title = 'Pack de démarrage';
      this.$modal.show('dialog', {
        title,
        component: functionsNeedStarterPack,
        props: {
          functionsNeedingStarterPack,
          beforeMessage,
          afterMessage,
        },
        buttons: [
          {
            title: 'Fermer',
            default: true,
            class: 'vue-dialog-button vertical-bg-gradient',
          },
        ],
      });
    },
  },
  computed: {
    appClasses() {
      return [
        { 'is-lateral-configuration-menu': this.$viewport.useLateralConfigurationMenu },
        (this.$camera.active) ? 'nav-mobile-hide' : '',
      ];
    },
    canUseInternalCart() {
      return get(this.$getNativeMYS(), 'CONFIG.layout.useInternalCart', true);
    },
    isStandAlone() {
      return get(this.$getNativeMYS(), 'CONFIG.layout.isStandAlone', false);
    },
    canGoHome() {
      return !this.isStandAlone;
    },
    morphers() {
      return this.$store.getters['morpher/morpherEmits'] || [];
    },
    appStyle() {
      return {
        '--primary-color-values': this.STYLES.primaryColorValues,
        '--primary-color': this.STYLES.primaryColor,
        '--primary-variant-color': this.STYLES.transitionColor,
      };
    },
    productQuantiyInCart() {
      return this.$hub && this.$hub.project && this.$hub.project.getHouseProductsQuantity();
    },
    whishlist() {
      return this.$hub.project.get('wishlists').at(0);
    },
    totalPriceInCart() {
      return this.whishlist.getPrix();
      // return this.$hub && this.$hub.project && this.$hub.project.getPrix()
    },
  },
  mounted() {
    this.$validateSendSelectionConfguration();
  },
  created() {
    window.mobile = this;
    // fonction utilisée par Cli2buy :
    window.exportForClickToBuy = this.$exportForClickToBuy.bind(this);

    this.$router.beforeEach((to, from, next) => {
      this.transitionName = to.meta.index > from.meta.index ? 'slide-left' : 'slide-right';
      next();
    });
    this.$MYS.load();
  },
};
</script>

<style lang="scss">
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-weight: 300;
}
* {
  box-sizing: border-box;
}
strong {
  font-weight: 500;
}
a {
  text-decoration: none;
}
html, body, #app-mobile, .content {
  height: 100%;
}
button:focus {
  outline: none
}
$props: ("margin", "padding");
$sides: ("left", "right", "top", "bottom");
$sizes: (("little", 5px), ("medium", 10px),("large", 20px), ("no", 0px));

@each $prop in $props {
  @each $size in $sizes {
    .#{nth($size, 1)}-#{$prop} {
      #{$prop}: nth($size, 2);
    }
    @each $side in $sides {
      .#{nth($size, 1)}-#{$prop}-#{$side} {
          #{$prop}-#{$side}: nth($size, 2);
      }
    }
  }
}
@mixin create-atomic($prop, $values) {
  @each $value in $values {
    .#{$prop}-#{$value} {
        #{$prop}: $value;
    }
  }
}
@include create-atomic("text-align", (center, right, left));
@include create-atomic("display", (block, inline-block, inline, none, table, table-cell));
@include create-atomic("vertical-align", (none, middle));
@include create-atomic("float", (left, right, none));
@include create-atomic("cursor", (default, pointer));
@include create-atomic("position", (relative, absolute));

.vertical-bg-gradient {
  color: red;
  background-color: rgb(128, 128, 128);
  background: var(--primary-color);
  background: -moz-linear-gradient(top, var(--primary-color) 0%, var(--primary-variant-color) 100%);
  background: -webkit-linear-gradient(top, var(--primary-color) 0%, var(--primary-variant-color) 100%);
  background: linear-gradient(-190deg, var(--primary-color) 0%, var(--primary-variant-color) 100%);
}
.vertical-bg-gradient-reverse {
  background-color: rgb(128, 128, 128);
  background: var(--primary-variant-color);
  background: -moz-linear-gradient(top, var(--primary-variant-color) 0%, var(--primary-color) 100%);
  background: -webkit-linear-gradient(top, var(--primary-variant-color) 0%, var(--primary-color) 100%);
  background: linear-gradient(-190deg, var(--primary-variant-color) 0%, var(--primary-color) 100%);
}
.horizontal-bg-gradient {
  background-color: rgb(128, 128, 128);
  background: var(--primary-color);
  background: -moz-linear-gradient(left, var(--primary-color) 0%, var(--primary-variant-color) 100%);
  background: -webkit-linear-gradient(left, var(--primary-color) 0%, var(--primary-variant-color) 100%);
  background: linear-gradient(130deg, var(--primary-color) 0%, var(--primary-variant-color) 100%);
}
.horizontal-bg-gradient-reverse {
  background-color: rgb(128, 128, 128);
  background: var(--primary-variant-color);
  background: -moz-linear-gradient(left, var(--primary-variant-color) 0%, var(--primary-color) 100%);
  background: -webkit-linear-gradient(left, var(--primary-variant-color) 0%, var(--primary-color) 100%);
  background: linear-gradient(130deg, var(--primary-variant-color) 0%, var(--primary-color) 100%);
}
.flex-center {
  display: flex;
  align-items: center }

.pre-spaced {
  white-space: pre-wrap }

.no-border {
  border: none !important }

.block-center {
  margin-left: auto;
  margin-right: auto }

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

*.unselectable-children * {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

h1 {
  text-transform: uppercase;
  font-size: 20px;
}
h3 {
  font-weight: 500;
  font-size: 18px;
  margin: 0 0 5px 0;
}
* + h3 {
  margin-top: 5px
}
ul {
  list-style: none
}
p {
  font-size: 14px;
}
.hover:hover {
  cursor: pointer;
}
.upper {
  text-transform: uppercase;
}
#app-mobile {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
$navHeight: 64px;

#nav-mobile {
  z-index: 10;
  top: 0;
  width: 100%;
  max-width: 100vw;
  position: fixed;
  height: $navHeight;
  font-size: 18px;
  background-color: white;
  box-shadow: rgba(199, 147, 147, 0.2) 0 3px 10px;
  display: flex;
  justify-content: space-between;
  transition: all 300ms ease;
  div {

    display: inline-block;
    line-height: $navHeight - 4px;
    &.home-button-container,
    &.navigation-container,
    &.cart-container{
      display: flex;
    }
    &.cart-container,
    &.navigation-container{
      flex-grow: 1;
      max-width: 20%;
      @media only screen and (max-width: 1023px){
        max-width: 10%;
      }
    }
    &.home-button-container{
      justify-content: center;
      flex-grow: 2;
      align-items: center;
      .logo-legrand {
        width: 78px;
        height: 19px;
        margin-left: 15px;
      }
    }
    &.cart-container{
      justify-content: flex-end;
    }

    &.back-button-container,
    &.cart-button-container {
      // width: $navHeight;
      & > * {
        width: 100%;
      }
    }
    .back-button-container {
      padding-left: 20px;
    }
    &.cart-button-container{
      .indicator{
        transition: all 250ms ease;
      }
      &:hover{
        .indicator{
          transform: scale(1.2);
        }
      }
    }

    & > a {
      color: #2c3e50;
      display: inline-block;
      line-height: $navHeight - 4px;
      cursor: pointer;
    }
  }
}
.content {
  overflow: auto;
  padding-top: $navHeight;
  position: fixed;
  top: 0;
  max-width: 100vw;
  width: 100vw;
  transition: all 300ms ease;
}
#app-mobile.nav-mobile-hide{
  #nav-mobile{
    top: -#{$navHeight};
  }
  .content{
    padding-top: 0;
  }
  & + #app-desktop{
    #nav{
      display: none;
    }
  }
}

.view {
  min-height: 100%;
}
.tc-white {
  color: white;
}
.tc-dark {
  color: #333;
}
.tc-primary {
  color: var(--primary-color);
}

.button {
  display:inline-block;
  font-size: 13px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  border: none;
  transition: all 250ms ease;
  i {
    font-size:150%;
    padding-right: 5px;
    vertical-align: middle;
  }
  &:hover{
    background: linear-gradient(-190deg,#000,#000);
  }
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

// icon font
@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon.eot?5k1qe6');
  src:  url('assets/fonts/icomoon.eot?5k1qe6#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.ttf?5k1qe6') format('truetype'),
    url('assets/fonts/icomoon.woff?5k1qe6') format('woff'),
    url('assets/fonts/icomoon.svg?5k1qe6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="mobile-icon-"], [class*=" mobile-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mobile-icon-paper-bag:before {
  content: "\e900";
}
.mobile-icon-paper-bag-add:before {
  content: "\e901";
}
.mobile-icon-left-arrow:before {
  content: "\e902";
}
.mobile-icon-next:before {
  content: "\e903";
}
.mobile-icon-close:before {
  content: "\e904";
}
.mobile-icon-embed:before {
  content: "\ea7f";
}
.mobile-icon-deplacement:before {
  content: "\ea80";
}
.mobile-icon-reduir:before {
  content: "\ea81";
}
.mobile-icon-reglage:before {
  content: "\ea82";
}
.mobile-icon-telechargement:before {
  content: "\ea83";
}
.mobile-icon-visualisation:before {
  content: "\ea84";
}
.mobile-icon-zoom-moins:before {
  content: "\ea85";
}
.mobile-icon-zoom-plus:before {
  content: "\ea86";
}
/* DIALOG */
div.vue-dialog {
 .dialog-c-title {
    font-weight: lighter;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 17px;
    padding-top: 5px;
 }
  button {
    color: rgba(0, 0, 0, 0.7);
    font-size: 10px;
    font-weight: 500;
  }
  button.vertical-bg-gradient {
    @extend .vertical-bg-gradient;
    border: none;
    color: white;
  }
}
// @media only screen and (min-width: 1440px){
//   .content{
//     overflow: hidden;
//   }
// }
</style>
