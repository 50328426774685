<template lang="pug">
  .interrupteur-drag(v-if="hasImage && canShowImage" :style="backgroundImg" v-release="hide")
</template>

<script>
import { getImgDatas } from '@/libs/core/utils';
import {
  find, throttle, isUndefined, get,
} from 'lodash';
import { release, releaseOutside } from '@/directives/release';

export default {
  name: 'interrupteur-drag',
  components: {
  },
  directives: {
    release,
    releaseOutside,
  },
  props: {
    size: {
      type: String,
      default: 'BIG',
    },
  },
  data() {
    return {
      mouseX: undefined,
      mouseY: undefined,
      showImage: false,
    };
  },
  watch: {
    '$hub.isDragingFunction': function (value) {
      if (!value) {
        this.mouseX = undefined;
        this.mouseY = undefined;
      }
    },
    hasImage: {
      handler(value) {
        if (value) {
          this.setListeners();
        } else {
          this.removeListeners();
          this.$hub.isDragingFunction = false;
        }
      },
      immediate: true,
    },
  },
  computed: {
    fonctionToAdd() {
      return this.$hub.fonctionToAddToInter;
    },
    inter() {
      return this.$hub.inter;
    },
    hasImage() {
      return Boolean(this.image);
    },
    canShowImage() {
      return this.hasImage && this.showImage && this.$hub.isDragingFunction && !isUndefined(this.mouseX) && !isUndefined(this.mouseY);
    },
    backgroundImg() {
      const imgDatas = getImgDatas(this.image, this.size);
      if (!this.canShowImage) return;
      const fonction = this.$getNativeMYS().FONCTIONS.get(this.fonctionToAdd);
      let Ypos = `${this.mouseY - 10}px`;
      let Xpos = `${this.mouseX - 10}px`;
      if (fonction.attributes.gamme === 'art') {
        Ypos = `${this.mouseY - 45}px`;
        Xpos = `${this.mouseX - 45}px`;
      }
      return {
        'background-image': `url(${imgDatas.u})`,
        'background-size': `${imgDatas.w}px ${imgDatas.h}px`,
        width: `${imgDatas.w}px`,
        height: `${imgDatas.h}px`,
        top: Ypos,
        left: Xpos,
      };
    },
    image() {
      if (!this.inter || !this.fonctionToAdd) return;
      const fonction = this.$getNativeMYS().FONCTIONS.get(this.fonctionToAdd);
      if (!fonction) return;
      const doigt = find(fonction.get('doigts'), { doigt: this.inter.get('doigt') });
      if (!doigt) return;
      return doigt.imgs && doigt.imgs[0];
    },
  },
  methods: {
    setListeners() {
      this.showImage = true;
      window.addEventListener('mousemove', this.debouncedMouseMoveHandler);
      window.addEventListener('touchmove', this.debouncedMouseMoveHandler);
    },
    removeListeners() {
      this.showImage = false;
      window.removeEventListener('mousemove', this.debouncedMouseMoveHandler);
      window.removeEventListener('touchmove', this.debouncedMouseMoveHandler);
    },
    hide() {
      this.$hub.isDragingFunction = false;
      this.removeListeners();
      this.$hub.removeFunctionToAdd();
    },
    debouncedMouseMoveHandler(event) {},
    mouseMoveHandler(event) {
      const mouseX = event.clientX || get(event, ['changedTouches', 0, 'clientX']);
      const mouseY = event.clientY || get(event, ['changedTouches', 0, 'clientY']);
      const doc = document.documentElement;
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      // on affiche uniquement si l'on est au-dessus du .selectors
      const y = mouseY - top;
      const rectangle = window.document.querySelector('.list-fonctions').getBoundingClientRect();
      if (!this.$viewport.useLateralConfigurationMenu && y > rectangle.y) {
        this.$hub.isDragingFunction = false;
        return;
      }
      /*
      // supprimé, mais fonctionnel : Jugé par pertinent par Erwan et Simon
      if (this.$viewport.useLateralConfigurationMenu && mouseX < (rectangle.x + rectangle.width)) {
        this.$hub.isDragingFunction = false
        return
      }
      */
      this.$hub.isDragingFunction = true;
      this.mouseX = mouseX;
      this.mouseY = mouseY;
    },
  },
  mounted() {
    this.debouncedMouseMoveHandler = throttle(this.mouseMoveHandler, 10);
  },
  beforeDestroy() {
    this.removeListeners();
  },
};
</script>

<style lang="scss">
.interrupteur-drag {
  z-index: 1;
  position: fixed;
}
</style>
