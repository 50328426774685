<template lang="pug">
  .fonction
    img(
      @click="clickHandler"
      @mouseover="mouseoverHandler"
      @mouseleave="mouseleaveHandler"
      :height="h"
      :width="w"
      :src="path"
      :style="fonctionStyle"
      )
</template>

<script>
import { PATH } from '@/libs/core/utils';

export default {
  name: 'interrupteur-render-fonction',
  props: {
    size: String,
    index: Number,
    h: Number,
    w: Number,
    id: String,
    x: Number,
    y: Number,
  },
  data() {
    return {
    };
  },
  watch: {
  },
  computed: {
    fonctionStyle() {
      return {
        'z-index': this.index,
        position: 'absolute',
        left: `${this.x}px`,
        top: `${this.y}px`,
      };
    },
    path() {
      return `${this.PATH()}/static/2/imgs/${this.size.toLowerCase()}@2x/${this.id}.png`;
    },
  },
  methods: {
    PATH,
    clickHandler(event) {
      this.$emit('click', event);
      this.$hub.$emit('freeAreaClicked');
    },
    mouseoverHandler(event) {
      this.$emit('mouseover', event);
    },
    mouseleaveHandler(event) {
      this.$emit('mouseleave', event);
    },
  },
};
</script>

<style lang="scss">

</style>
