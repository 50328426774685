/**
 @typedef {Object} Module
 @property {string} id - identifiant du Module
 @property {number} width - largeur en mm
 @property {number} height - hauteyr en mm
 */

/**
 * Représente le Modèle Module.
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 * @constructor
 * @param {Module} Module - Données du module, permettant sa génération.
 * @augments Backbone.Model
 */

MYS.BB.MODELS.Module = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Module.prototype */
    {}
);
