/**
 * Représente une Collection de {@link MYS.BB.MODELS.Boite}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Boite[]} boites - Ensemble de boites
 * @augments Backbone.Collection
 */

MYS.BB.COLLECTIONS.Boites = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Boites.prototype */
    {
        model: MYS.BB.MODELS.Boite
    });
