import { includes } from 'lodash';
// liste des fonction native de Backbone
// En commentaire les fonctions sont surveillées
// les autres sont les fonctions natives à ne pas surveiller
const reservedWords = [
  // 'defaults',
  // 'validate',
  // 'initialize',
  'constructor', // par sécu
  // 'get',
  'set',
  'on',
  // 'listenTo',
  // 'off',
  // 'stopListening',
  // 'once',
  // 'listenToOnce',
  // 'trigger',
  // 'bind',
  // 'unbind',
  // 'sync',
  // 'escape',
  // 'has',
  // 'matches',
  // 'unset',
  // 'clear',
  // 'hasChanged',
  // 'changedAttributes',
  // 'previous',
  // 'previousAttributes',
  // 'fetch',
  // 'save',
  // 'destroy',
  // 'url',
  // 'parse',
  // 'clone',
  // 'isNew',
  // 'isValid',
  // '_validate',
  // 'keys',
  // 'values',
  // 'invert',
  // 'pick',
  // 'omit',
  // 'chain',
  // 'isEmpty'
];

export default function proxyfy(model) {
  for (const key in model) {
    const nativeFunction = model[key];
    if (typeof nativeFunction === 'function' && !includes(reservedWords, key)) {
      const proxyfiedFunction = function () {
        /* eslint-disable no-unused-expressions */
        model.$$noCache;
        /* eslint-enable no-unused-expressions */
        return nativeFunction.apply(model, arguments);
      };
      model[key] = proxyfiedFunction;
    }
  }
  return model;
}
