import { getParam, askToParentFrame } from '@/libs/core/utils';
import {
  compact, map, isUndefined, camelCase, isFunction,
} from 'lodash';

const PARENT_URL = getParam('parent_url');

export default class SendCart {
  constructor(sendCartMethod) {
    this.sendCartMethod = sendCartMethod;
  }

  _sendWithPostMessageMethod(cart) {
    const totalProductCount = Object.keys(cart.getRefs()).length;
    const ConfiguratorName = 'Legrand';
    const products = cart.get('products').map((currentInter) => {
      const refs = {};
      currentInter.getRefs().forEach((ref) => {
        if (refs[ref] === undefined) {
          refs[ref] = 0;
        }
        refs[ref] += 1 * currentInter.get('quantity');
      });
      if (!currentInter.has('quantity')) {
        currentInter.set('quantity', 1);
      }
      const products = compact(map(refs, (count, ref) => {
        const produit = MYS.PRODUITS.get(ref);
        if (isUndefined(produit)) {
          return;
        }
        return {
          productCode: produit.id,
          quantity: refs[ref],
          ean: produit.get('ean'),
        };
      }));
      return {
        id: currentInter.get('createdAt'),
        title: currentInter.getShortTitle(),
        product: products,
      };
    });
    const output = {
      totalProductCount,
      ConfiguratorName,
      products,
    };
    askToParentFrame('function', JSON.stringify(output));
  }

  send(cart) {
    const functionName = `_${camelCase(`send with ${this.sendCartMethod} method`)}`;
    if (isFunction(this[functionName])) {
      this[functionName](cart);
    } else {
      console.error(`Le produit n'a pas pu être ajouté a un panier : fonction inexistante = ${functionName}`);
    }
  }
}
