/**
 * Représente une Collection d'Images.
 * @see {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Img[]} images - Ensemble d'images
 * @augments Backbone.Collection
 */
MYS.BB.COLLECTIONS.Imgs = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Imgs.prototype */
    {
        model: MYS.BB.MODELS.Img,
        /**
         * Vérifie si une ou plusieurs images sont présentes dans la collection
         * @param {Img.id[]} ids - Liste d'identifiants d'{@link MYS.BB.MODELS.Img}
         * @return {Boolean}
         */
        has: function(ids) {
            if (_.isString(ids)) {
                ids = [ids];
            }
            var retour = true;
            for (var i = 0; i < ids.length; i++) {
                if (this.get(ids[i]) === undefined) {
                    // console.info("IMAGES : absence : " + ids[i]);
                    retour = false;
                }
            }
            return retour;
        }
    });
