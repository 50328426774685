import { map, forEach } from 'lodash';
import { numberize } from '@/libs/core/utils';

export function GTMProductPrintFinishes(hubInter, nativeMYS, plaques, chunkSize) {
  const format = hubInter ? hubInter.get('format') : false;

  const plaquesList = map(plaques,
    (plaque) => {
      let plaqueFound = null;
      if (format) {
        plaqueFound = nativeMYS.PLAQUES.findWhere({
          'finition#id': plaque.id,
          'format#id': hubInter.get('format'),
        });
      }
      if (!plaqueFound) {
        plaqueFound = nativeMYS.FINITIONS.get(plaque.id).getPrivelegedPlaqueOrSmallest();
      }
      let products = false;
      if (plaqueFound) {
        products = map(plaqueFound.get('refs'),
          (ref) => {
            const pdt = nativeMYS.PRODUITS.get(String(ref));
            return pdt ? {
              key: String(ref),
              pdt,
              more: pdt.more(),
            } : false;
          });
      }
      return products;
    });

  let count = 1;
  const impressions = [];
  forEach(plaquesList, (plaqueProducts) => {
    forEach(plaqueProducts, (product) => {
      impressions.push({
        name: product.pdt.get('name'),
        id: product.key,
        price: numberize(product.pdt.get('prix').toFixed(2)),
        brand: product.more.gamme,
        category: product.more.category,
        variant: product.more.postes,
        list: 'Configurateur',
        position: count,
      });
    });
    count++;
  });

  const toSend = [].concat.apply([],
    impressions.map((elem, i) => (i % chunkSize ? [] : [impressions.slice(i, i + chunkSize)])));
  return toSend;
}

export function GTMProductPrintFunctions(fingerColor, finitionId, nativeMYS, fonctions, chunkSize) {
  const fonctionsList = map(fonctions,
    (f) => {
      const fonction = nativeMYS.FONCTIONS.get(f.id);
      const doigt = fingerColor;
      const products = map(fonction.getRefs(doigt, finitionId),
        (ref) => {
          const pdt = nativeMYS.PRODUITS.get(String(ref));
          return pdt ? {
            key: String(ref),
            pdt,
            more: pdt.more(),
          } : false;
        });
      return products;
    });

  let count = 1;
  const impressions = [];
  forEach(fonctionsList, (fonctionProducts) => {
    forEach(fonctionProducts, (product) => {
      impressions.push({
        name: product.pdt.get('name'),
        id: product.key,
        price: numberize(product.pdt.get('prix').toFixed(2)),
        brand: product.more.gamme,
        category: product.more.category,
        variant: product.more.postes,
        list: 'Configurateur',
        position: count,
      });
    });
    count++;
  });

  const toSend = [].concat.apply([],
    impressions.map((elem, i) => (i % chunkSize ? [] : [impressions.slice(i, i + chunkSize)])));
  return toSend;
}
