/**
 * Représente une Collection de {@link MYS.BB.MODELS.Plaque}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Plaque[]} plaques - Ensemble de plaques
 * @augments Backbone.Collection
 */

MYS.BB.COLLECTIONS.Plaques = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Plaques.prototype */
    {
        model: MYS.BB.MODELS.Plaque
    }
);
