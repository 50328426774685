import MYS from '@/libs/mon-interrupteur-desktop';
import proxyfy from '@/plugins/MYS/proxyfy';
import hub from '@/components/hub';

import {
  get, keys, isEmpty, includes,
} from 'lodash';
import { getParam, askToParentFrame } from '@/libs/core/utils';
import router from '@/router';
import SendCart from './sendCart/index';
import SendSelection from './sendSelection/index';
import CartAsJson from './cartAsJson/index';

const MODEL_NOCACHE_KEY = '$$noCache';
const DEFAULT_STARTER_PACK_TITLE = '';
const DEFAULT_STARTER_PACK_LINK_LABEL = 'Pack de démarrage';
const DEFAULT_STARTER_PACK_LINK_URL = 'https://www.legrand.fr/pro/catalogue/pack-de-demarrage-celiane-with-netatmo-avec-prise-control-et-commande-generale-departarrivee-blanc';
const CAMERA_DISPLAY = 'cam';
const PARENT_URL = getParam('parent_url');
const PRODUCT = getParam('product');
const PLAQUE = getParam('fi');
const FONCTIONS = getParam('fo');
const ORIENTATION = getParam('ori');
const STARTUP_DISPLAY = getParam('sud');
const SHOW_PRICE = !(getParam('showPrice') === 'false');
const _sendCartMethod = getParam('sendCartMethod');
const SEND_CART_METHOD = includes([false, 'postMessage'], _sendCartMethod) ? _sendCartMethod : false;
const SEND_CART_LABEL = getParam('sendCartLabel') || 'Ajouter à mon panier';
// @Info : avant on utilisait cart_method
const _addToSelectionMethod = (getParam('addToSelectionMethod') || getParam('cart_method') || '');
const ADD_TO_SELECTION_METHOD = includes(['internal', 'postMessage', 'post', 'get'], _addToSelectionMethod) ? _addToSelectionMethod : 'internal';

// const PARENT_URL = getQueryStringAsObject()['parent_url']
// const PRODUCT = getQueryStringAsObject()['product']
// const PLAQUE = getQueryStringAsObject()['plaque']
// const FONCTIONS = getQueryParamsObject()['fonctions']

function $mys(Vue) {
  return new Vue({
    router,
    data: {
      isLoaded: false,
      progress: 0,
      loadErrors: [],
    },
    methods: {
      reset() {
        this.isLoaded = false;
        this.progress = 0;
        this.loadErrors = [];
      },
      loadingComplete(datas) {
        this.setInterWithGivenProducts();
        this.isLoaded = isEmpty(this.loadErrors);
      },
      getDefaultGamme() {
        return keys(get(MYS, 'CONFIG.doigts', {}))[0] || 'celiane';
      },
      foundInter() {
        if (PLAQUE || FONCTIONS) {
          return MYS.findInterFromUrlParams(PLAQUE, FONCTIONS, ORIENTATION);
        }
        if (PRODUCT) {
          // affectation de l'interrupteur global si possible
          return MYS.findInterFromSku(PRODUCT);
        }
      },
      setInterWithGivenProducts() {
        const { layout } = MYS.CONFIG;
        let defaultGamme = this.getDefaultGamme();
        const interFound = this.foundInter();

        const hasProductToDisplay = interFound && interFound.isValid();
        if (hasProductToDisplay) {
          this.$set(this.$hub, 'inter', this.$create('Interrupteur', interFound.toJSON()));
          defaultGamme = interFound.getGamme();
        }
        if (STARTUP_DISPLAY === CAMERA_DISPLAY && ('mediaDevices' in navigator) && !this.$viewport.useLateralConfigurationMenu) {
          this.$camera.active = true;
          this.$camera.animation = true;
          this.$camera.showInterface = false;
          this.$camera.setProcess('camera-process-video');
          this.$camera.zoomEnabled = true;
          this.$hub.darkMode = false;
        }
        // S'il y a un produit a afficher, on l'affiche immédiatement
        if (!layout.canShowGammePage || hasProductToDisplay) {
          if (this.$route.name !== 'config' && this.$route.params.gamme !== defaultGamme) {
            this.$router.push({
              name: 'config',
              params: { gamme: defaultGamme },
            }).catch();
          }
        }
      },
      loadingError(datas) {
        this.loadErrors.push(datas);
      },
      loadingProgress(datas) {
        this.progress = datas.progress;
      },
      load() {
        MYS.configuration();
        MYS.load();
      },
    },
  });
}

const MYSconnector = {
  install(Vue, options) {
    const vm = $mys(Vue);
    MYS.loadingComplete = vm.loadingComplete;
    MYS.loadingProgress = vm.loadingProgress;
    MYS.loadingError = vm.loadingError;
    MYS.CONFIG.layout = {
      canShowGammePage: true, // true
      isStandAlone: false, // false
      useInternalCart: true, // true
      addToSelectionMethod: ADD_TO_SELECTION_METHOD, // default = ‘internal‘, possible: "internal", "postMessage", "get", "post"
      showPrice: SHOW_PRICE, // true
      sendCartMethod: SEND_CART_METHOD, // true
      sendCartLabel: SEND_CART_LABEL, // true
      ...get(MYS, 'CONFIG.layout', {}),
    };
    if (MYS.CONFIG.layout.addToSelectionMethod !== 'internal') {
      MYS.CONFIG.layout.useInternalCart = false;
    }
    const selectionSender = new SendSelection(MYS.CONFIG.layout.addToSelectionMethod);
    const cartSender = new SendCart(MYS.CONFIG.layout.sendCartMethod);
    Object.defineProperties(Vue.prototype, {
      $MYS: {
        get: () => vm,
      },
    });
    const $hub = hub(Vue); // Important : cette ligne doit être APRES la définition de $MYS
    Vue.prototype.$hub = $hub;
    const globalMethods = {
      $setProxyfiedModel(model) {
        model[MODEL_NOCACHE_KEY] = 0;
        const proxyVm = new Vue({
          data: {
            model: proxyfy(model),
          },
        });
        proxyVm.model.on('change', () => {
          Vue.set(proxyVm.model, MODEL_NOCACHE_KEY, Math.random());
        });
        return proxyVm.model;
      },
      $getNativeMYS() {
        return MYS;
      },
      $validateSendSelectionConfguration() {
        selectionSender.setContext(this).validate();
      },
      $create(modelName, opts) {
        return new MYS.BB.MODELS[modelName](opts);
      },
      $getAll(modelName) {
        return MYS[modelName.toUpperCase()];
      },
      $get(modelName, id) {
        return MYS[modelName.toUpperCase()].get(id);
      },
      $getCurrency() {
        return MYS.CONFIG.money;
      },
      $getTax() {
        return MYS.CONFIG.tax;
      },
      $redirect(url) {
        askToParentFrame('redirect', url);
      },
      $addToCart(inter) {
        selectionSender.setContext(this).send(inter);
      },
      $sendToExternalCart(cart) {
        cartSender.send(cart);
        this.$gtm.push({
          event: 'eventGA',
          eventCategory: MYS.CONFIG.contour,
          eventAction: 'Add to cart',
        });
      },
      $exportToExcel() {
        const projet = this.whishlist;
        const output = this.getProjectForParamOutput();

        const url = this.$getNativeMYS().CONFIG.excelUrlPath;

        const totalPrice = projet.getPrix();

        this.$download(url, output, totalPrice, 'noTurnstile');
      },
      $exportForClickToBuy() {
        const json = new CartAsJson();
        return json.transform(this.whishlist);
      },
      $displayFormElec() {
        if (window.toggleFormElec && window.sendProjectToFormElec) {
          const projet = this.whishlist;
          const output = this.getProjectForParamOutput();
          const totalPrice = projet.getPrix();
          window.sendProjectToFormElec(output, totalPrice, this.$getNativeMYS().CONFIG.pdfTheme, 'noTurnstile');
          this.$hub.formElecOn = true;
          window.toggleFormElec(true);
          this.$gtm.push({
            event: 'eventGA',
            eventCategory: this.$getNativeMYS().CONFIG.contour,
            eventAction: 'Contact',
            eventLabel: 'Affichage formulaire',
          });
        }
      },
      $hideFormElec() {
        this.$hub.formElecOn = false;
        window.toggleFormElec(false);
      },
      $getStarterPackMessage() {
        return {
          title: MYS.CONFIG.layout.starterPackMessage?.title || DEFAULT_STARTER_PACK_TITLE,
          label: MYS.CONFIG.layout.starterPackMessage?.link.label || DEFAULT_STARTER_PACK_LINK_LABEL,
          url: MYS.CONFIG.layout.starterPackMessage?.link.url || DEFAULT_STARTER_PACK_LINK_URL,
        };
      },
    };
    Object.assign(Vue.prototype, globalMethods);
  },
};

export default MYSconnector;
