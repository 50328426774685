<template lang="pug">
  div
    svg(
      v-bind='svgAttrs'
      xmlns="http://www.w3.org/2000/svg")
      mask(:id="maskId")
        rect(
          v-bind="plaqueMaskAttr"
          fill="white")
        rect(
          v-for='posteMaskAttr in posteMaskAttrs'
          v-bind='posteMaskAttr',
          fill="black")
      rect.plaque-bg(
        :mask="`url(#${maskId})`"
        v-bind="plaqueMaskAttr"
        fill="rgb(255, 255, 255)")
      rect.plaque(
        v-bind="plaqueAttrs"
        fill="none" stroke="rgba(0, 0, 0, .55)")
      rect.poste(
        v-for='(posteAttr, posteIndex) in posteAttrs'
        :class='`poste-${posteIndex}`'
        v-bind='posteAttr',
        fill="none" stroke="rgba(0, 0, 0, .8)")
      line.light(
        v-if="isLuminous"
        v-for='luminousPlaqueAttr in luminousPlaqueAttrs'
        v-bind='luminousPlaqueAttr'
        stroke="rgba(245, 201, 24, .8)"
        "stroke-width"="4")

</template>

<script>
export default {
  name: 'format-preview',
  components: {
  },
  props: {
    id: {
      type: String,
    },
    coef: {
      type: Number,
      default: 0.45,
    },
    postes: Array,
    plaque: Object,
    isLuminous: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lightLineUpWidthCoeff: 1 / 3,
      lightLineDownWidthCoeff: 3 / 5,
      lightLineHeightCoeff: 1 / 3,
      lightLineCount: 3,
    };
  },
  computed: {
    posteAttrs() {
      const attrs = [];
      for (const poste in this.postes) {
        attrs.push(this.getPosteAttrs(this.postes[poste]));
      }
      return attrs;
    },
    posteMaskAttrs() {
      const attrs = [];
      for (const poste in this.postes) {
        attrs.push(this.getPosteMaskAttrs(this.postes[poste]));
      }
      return attrs;
    },
    luminousPlaqueAttrs() {
      const attrs = [];
      for (let n = 0; n < this.lightLineCount; n++) {
        attrs.push(this.getLuminousPlaqueAttrs(n));
      }
      return attrs;
    },
    lightUpBaseWidth() {
      return this.plaque.width * this.lightLineUpWidthCoeff;
    },
    lightUpBaseStart() {
      return (this.plaque.width - this.lightUpBaseWidth) / 2;
    },
    lightDownBaseWidth() {
      return this.plaque.width * this.lightLineDownWidthCoeff;
    },
    lightDownBaseStart() {
      return (this.plaque.width - this.lightDownBaseWidth) / 2;
    },
    gamme() {
      const gamme = this.$hub.inter && this.$hub.inter.getGamme();
      if (!gamme) return;
      return this.$getNativeMYS().GAMMES.get(gamme);
    },
    maskId() {
      return `mask-${this.id}`;
    },
    svgAttrs() {
      const { width, height, viewBox } = this;
      return { width, height, viewBox };
    },
    width() {
      return Math.ceil(
        (
          this.plaqueAttrs.width
          + this.plaqueAttrs['stroke-width']
          + (this.isLuminous ? (this.plaque.height * this.lightLineHeightCoeff) : 0)
        )
        * this.coef,
      );
    },
    height() {
      return Math.ceil(
        (
          this.plaqueAttrs.height
          + this.plaqueAttrs['stroke-width']
          + (this.isLuminous ? (this.plaque.height * this.lightLineHeightCoeff) : 0)
        )
        * this.coef,
      );
    },
    viewBox() {
      const strokeWidth = Math.ceil(this.plaqueAttrs['stroke-width'] / 2);
      return [
        strokeWidth * -1,
        strokeWidth * -1,
        Math.ceil(this.plaqueAttrs.width) + strokeWidth + 1,
        Math.ceil(this.plaqueAttrs.height) + strokeWidth + 1 + (this.isLuminous ? (this.plaque.height * this.lightLineHeightCoeff) : 0),
      ].join(' ');
    },
    plaqueAttrs() {
      const radius = this.getFormatBorderRadius();
      return {
        'stroke-width': 4,
        y: 0,
        x: 0,
        ry: radius,
        rx: radius,
        height: this.plaque.height,
        width: this.plaque.width,
      };
    },
    plaqueMaskAttr() {
      const {
        x, y, height, width, rx, ry,
      } = this.plaqueAttrs;
      return {
        x, y, height, width, rx, ry,
      };
    },
    areaBorderRadius() {
      if (!this.gamme) return 0;
      return this.gamme.get('dropAreaBorderRadius') || 0;
    },
  },
  methods: {
    getFormatBorderRadius() {
      if (!this.gamme) return 0;
      return this.gamme.getFormatBorderRadius(this.id);
    },
    getPosteAttrs(poste) {
      return {
        'stroke-width': 1,
        rx: this.areaBorderRadius,
        ry: this.areaBorderRadius,
        y: poste.top,
        x: poste.left,
        width: poste.width,
        height: poste.height,
      };
    },
    getLuminousPlaqueAttrs(n) {
      return {
        x1: this.lightUpBaseStart + this.lightUpBaseWidth / (this.lightLineCount - 1) * n,
        y1: this.plaque.height + 4,
        x2: this.lightDownBaseStart + this.lightDownBaseWidth / (this.lightLineCount - 1) * n,
        y2: this.plaque.height + (this.plaque.height * this.lightLineHeightCoeff),
      };
    },
    getPosteMaskAttrs(poste) {
      const {
        y, x, width, height, rx, ry,
      } = this.getPosteAttrs(poste);
      return {
        y, x, width, height, rx, ry,
      };
    },
  },
  created() {
  },
};
</script>

<style lang="scss">

  .poste, .plaque {
    box-sizing: content-box;
  }
  .poste {
    position: absolute;
    border: 1px solid black;
  }
  .plaque {
    border: 2px solid black;
    position: relative
  }
</style>
