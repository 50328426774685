/**
 @typedef {Object} Fonction
 @property {string} id - identifiant de la Fonction
 @property {string} marque - Marque de la Fonction (Legrand, Bticino, Arnould …)
 @property {string} gamme - Gamme de la Fonction (Celiane, Axolute, Art …)
 @property {string[]} rule#ids - identifiants des Rules, faisant référence à {@link MYS.BB.MODELS.Rule}
 @property {string} module#id - Module de la Fonction
 @property {string} usage#id - Usage de la Fonction (Interrutpeur, Interrupteur-va-et-vient …)
 @property {string[]} categories - Identifiants des categories auxquelles est rattachée la Fonction
 @property {Doigt[]} doigts - Doigts possibles pour cette Finition
 */

/**
 @typedef {Object} Doigt
 @property {string} doigt - identifiant du Doigt
 @property {string[]} imgs - identifiants de l'{@link MYS.BB.MODELS.Img} composant l'object Doigt
 @property {string[]} refs - liste des références de {@link MYS.BB.MODELS.Produit} qu'utilise l'object Doigt
 */

/**
 * Représente le Modèle Fonction.
 * @constructor
 * @param {Fonction} finition - Données de la Fonction, permettant sa génération.
 * @augments Backbone.Model
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 */
MYS.BB.MODELS.Fonction = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Fonction.prototype */
    {
        isLuminous: function() {
            var out = []
            _.each(this.get('doigts'), function(doigt) {
                out.push(!_.isEmpty(doigt['imgs-light']))
            })
            // if (_.every(out)) {
            //     console.info(this.id, 'is luminous', this)
            // }
            return _.every(out)
        },
        /**
         * Permet d'obtenir directement l'object {@link MYS.MODELS.Usage Usage} lié à cette Fonction.
         * @return {MYS.BB.MODELS.Usage} Usage ayant pour identifiant celui précisé dans Fonction.usage#id
         */
        getUsage: function() {
            return MYS.USAGES.get(this.get("usage#id"));
        },
        /**
         * Récupérer au sein d'une Fonction les détails d'un doigt en précisant en entré la couleur souhaitée et éventuellement la finition qui vient décorer le doigt.
         * @param  {string} doigt couleur du doigt (graphite, blanc, titane …) dont l'on souhaite obtenir l'objet complet
         * @param  {string} finitionSyncedWith finition qui peut décorer le doigt
         * @return {Doigt} doigt répondant à la couleur demandée (graphite, blanc, titane …) et éventuellement à la finition rattachée
         */
        getDoigtDatas: function(doigt, finitionSyncedWith) {
            if (doigt === undefined) {
                console.error("ERROR : Il faut connaitre la couleur du doigt pour définir les références de la fonction ' " + this.id);
            }

            var doigtData = undefined;
            if (finitionSyncedWith !== undefined && this.canSyncWithFinition()) {
              var doigtDataWithFinition = _.findWhere(this.get("doigts"), { "doigt": doigt, "finition": finitionSyncedWith });
              if (typeof doigtDataWithFinition !== "undefined") {
                doigtData = doigtDataWithFinition;
              }
            }
            if (doigtData === undefined) {
              doigtData = _.findWhere(this.get("doigts"), {
                "doigt": doigt
              });
            }

            if (doigtData === undefined) {
              var syncedErrorMessage = "";
              if (finitionSyncedWith !== undefined && this.canSyncWithFinition()) {
                syncedErrorMessage = " et la finition '" + finitionSyncedWith + "' ";
              }
              console.error("ERROR : le doigt '" + doigt + "' " + syncedErrorMessage + " n'est pas disponible pour la fonction : " + this.id);
            }
            return doigtData;
        },
        /**
         * Détermine si la fonction doit visuellement se synchroniser avec la finition en cours
         */
        canSyncWithFinition: function() {
          return _.some(this.get("doigts"), (doigt) => {
            return typeof doigt.finition !== "undefined";
          });
        },

        /**
         * Liste les références Produit qui composent la fonction.
         * @param  {string} doigt couleur du doigt (graphite, blanc, titane …) dont l'on souhaite obtenir les références
         * @param  {string} finitionSyncedWith finition qui peut décorer le doigt
         * @return {string[]} références composant le doigt à la couleur demandée (graphite, blanc, titane …)
         */
        getRefs: function(doigt, finitionSyncedWith) {
            var doigtDatas = this.getDoigtDatas(doigt, finitionSyncedWith);
            return doigtDatas.refs;
        },
        /**
         * Calcul le prix total de la fonction d'après la couleur de doigt demandée.
         * @param  {string} doigt couleur du doigt (graphite, blanc, titane …) dont l'on souhaite obtenir le total
         * @return {number} total des références composant le doigt à la couleur demandée (graphite, blanc, titane …)
         */
        getPrix: function(doigt, finitionSyncedWith) {
            return MYS.PRODUITS.getTotalByProduits(this.getRefs(doigt, finitionSyncedWith));
        },
        /**
         * Invalide la Fonction s'il n'y a pas de toutes les couleurs de doigts requises, ou s'il manque des références des {@link MYS.BB.MODELS.Produit} et des {@link MYS.BB.MODELS.Img}
         * @return {string|void} La chaine retournée est un descriptif de l'erreur entrainant l'invalidation
         */
        validate: function() {
            var allRefs = [];
            if (this.has("doigts")) {
                var doigts = this.get("doigts");
                for (var i = 0; i < doigts.length; i++) {
                    if (typeof MYS.CONFIG.doigts === "undefined" || (MYS.CONFIG.doigts[this.get("gamme")] !== undefined && _.contains(MYS.CONFIG.doigts[this.get("gamme")], doigts[i].doigt))) {
                        allRefs.push(doigts[i].refs);
                    }
                }
                allRefs = _.flatten(allRefs);
            }
            if (this.has('usage#id') && typeof MYS.USAGES.get(this.get('usage#id')) == "undefined") {
                return "Des usages sont manquants : " + this.get('usage#id');
            }
            if (!this.has('usage#id') && !this.has('nom')) {
                return "Des usages sont manquants : " + this.id + ' nom ou usage#id';
            }

            if (!MYS.PRODUITS.has(allRefs)) {
                return "Des références sont manquantes : " + allRefs;
            }

            var allImgs = [];
            if (this.has("doigts")) {
                var doigts = this.get("doigts");
                for (var i = 0; i < doigts.length; i++) {
                    allImgs.push(doigts[i].imgs);
                }
            }
            allImgs = _.flatten(allImgs);

            if (!MYS.BIG.has(allImgs)) {
                return "Des images sont manquantes : " + allImgs;
            }
        }
    });
