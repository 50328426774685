import {
  isString, map, isFunction, camelCase,
} from 'lodash';
import { getParam, askToParentFrame } from '@/libs/core/utils';
import axios from 'axios';

const PARENT_URL = getParam('parent_url');

export default class SendSelection {
  constructor(addToSelectionMethod) {
    this.requiredDatasByMethods = {
      post: ['ajax_url', 'u'],
      get: ['ajax_url', 'u'],
    };
    this.addToSelectionMethod = addToSelectionMethod;
  }

  setContext(context) {
    this.context = context;
    return this;
  }

  get isValid() {
    const requiredDatasByMethodsForCurrentMethod = this.requiredDatasByMethods[this.addToSelectionMethod] || [];
    for (const prop of requiredDatasByMethodsForCurrentMethod) {
      if (!getParam(prop)) {
        console.error(`le paramètre d'url "${prop}" doit être définit pour envoyer le panier en  "${this.addToSelectionMethod}"`);
        return false;
      }
    }
    return true;
  }

  validate() {
    if (this.isValid) {
      return;
    }
    const modal = this.context.$modal;
    modal.show('dialog', {
      title: 'Fonction désactivée',
      text: 'La configuration actuelle de cette application ne permettra pas l\'ajout au panier',
      buttons: [
        {
          title: 'Bien compris !',
          default: true,
          class: 'vue-dialog-button vertical-bg-gradient',
        },
      ],
    });
  }

  sendGTM() {
    this.context.$gtm.push({
      event: 'eventGA',
      eventCategory: this.context.$getNativeMYS().CONFIG.contour,
      eventAction: 'Add to cart',
    });
  }

  _sendWithInternalMethod(inter) {
    this.context.$hub.project.get('wishlists').at(0).get('products').addWithDuplicateDetection(inter);
  }

  _sendWithPostMessageMethod(inter) {
    const cart = this._getSelectionToSendFromInterrupteur(inter);
    this.sendGTM();
    window.askToParentFrame('function', JSON.stringify(cart));
    const functionsNeedingStarterPack = this.starterPackIsNeeded(inter);
    if (functionsNeedingStarterPack.length) {
      this.context.$hub.project.trigger('warning.functionsNeedStarterPack', { functionsNeedingStarterPack });
    }
  }

  _sendWithPostMethod(inter) {
    this.sendGTM();
    this._sendWithHTTPMethod(inter, 'post');
  }

  _sendWithGetMethod(inter) {
    this.sendGTM();
    this._sendWithHTTPMethod(inter, 'get');
  }

  _sendWithHTTPMethod(inter, method) {
    const modal = this.context.$modal;
    const url = getParam('ajax_url');
    const sessionKey = getParam('u');
    if (!this.isValid) {
      modal.show('dialog', {
        title: 'Action impossible',
        text: 'L\'ajout à votre panier est impossible. Nous vous invitons à contacter le support technique du site',
        buttons: [
          {
            title: 'Ok',
            default: true,
            class: 'vue-dialog-button vertical-bg-gradient',
          },
        ],
      });
      return;
    }
    const cart = this._getSelectionToSendFromInterrupteur(inter);
    const params = {
      session_key: sessionKey,
      configuration: JSON.stringify(cart),
    };
    const stringifiedParams = map(params, (value, key) => `${key}=${encodeURIComponent(value)}`).join('&');
    // '{"title":"1 poste Argile","preview":"/action/IMAGE/?rotation=false&quantity=1&boite=null&format=1_poste_1_x_1poste_celiane&fonctions=&doigt=titane&finition=celiane-argile&size=small","content":[{"REF_LEG":"066711","REF_MAT":"LEG066711","qty":1},{"REF_LEG":"080251","REF_MAT":"LEG080251","qty":1}]}'
    let request;
    if (method === 'post') {
      console.log('post send');
      request = axios.post(url, stringifiedParams,
        {
          responseType: 'text',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
    } else if (method === 'get') {
      request = axios.get(url, { params, responseType: 'text' });
    }
    request.then((response) => {
      if (typeof response.data === 'object') {
        return response.data;
      }
      if (typeof response.data === 'string') {
        return JSON.parse(response.data);
      }
      throw new Error(response.type || 'Erreur dans la réponse du panier');
    }).then((response = {}) => {
      if (response.type !== 'SUCCESS') {
        throw new Error(response.type || 'Erreur dans la réponse du panier');
      }
      return response;
    }).then((response) => {
      const buttons = [{
        title: 'Poursuivre la création',
        default: true,
        class: 'vue-dialog-button',
        handler: () => {
          const { inter } = this.context.$hub;
          inter.deleteAllFonction();
          const bestPlaque = inter.getFinition().getPrivelegedPlaqueOrSmallest();
          if (bestPlaque) {
            inter.set('format', bestPlaque.get('format#id'));
          }
          modal.hide('dialog');
        },
      }];
      if (isString(response.url)) {
        buttons.push({
          title: 'Voir le panier',
          class: 'vue-dialog-button vertical-bg-gradient',
          handler: () => {
            modal.hide('dialog');
            let { url } = response;
            if (url.indexOf(PARENT_URL) === -1) {
              url = PARENT_URL.replace(/\/?$/, '/') + url.replace(/^\//, '');
            }
            this.context.$redirect(url);
          },
        });
      }
      const functionsNeedingStarterPack = this.starterPackIsNeeded(inter);
      const text = 'Votre produit vient d\'être ajouté à votre panier.';
      if (functionsNeedingStarterPack.length) {
        this.context.$hub.project.trigger('warning.functionsNeedStarterPack', { functionsNeedingStarterPack, beforeMessage: text });
      } else {
        modal.show('dialog', {
          title: 'Confirmation',
          text,
          buttons,
        });
      }
    }).catch((error) => {
      console.error(error);
      modal.show('dialog', {
        title: 'Action impossible',
        text: 'Une erreur s\'est produite lors de l\'ajout à votre panier. Nous vous invitons à recharge la page internet. Si l\'erreur persiste, contactez le support technique du site',
        buttons: [
          {
            title: 'Ok',
            default: true,
            class: 'vue-dialog-button vertical-bg-gradient',
          },
        ],
      });
    });
  }

  send(inter) {
    if (!this.context) console.error('le context n\'est pas préalablement défini');
    const functionName = `_${camelCase(`send with ${this.addToSelectionMethod} method`)}`;
    if (isFunction(this[functionName])) {
      this[functionName](inter);
    } else {
      console.error(`Le produit n'a pas pu être ajouté a un panier : fonction inexistante = ${functionName}`);
    }
  }

  starterPackIsNeeded(inter) {
    return inter.getFunctionsNeedingStarterPack();
  }

  _getSelectionToSendFromInterrupteur(inter) {
    const outputMappage = MYS.CONFIG.MAPPAGE.output;
    let useQuantity = false;
    for (const i in outputMappage) {
      if (outputMappage[i] === '$quantity$') {
        useQuantity = true;
        break;
      }
    }
    const cart = {
      title: inter.getShortTitle(),
      preview: inter.getImage('small'),
      content: [],
    };
    const refs = {};
    inter.getRefs().forEach((ref) => {
      refs[ref] = !refs[ref] ? 1 : refs[ref] + 1;
    });
    if (useQuantity) {
      // Dans le cas où l'on retourne les références regroupées avec la quantité
      for (const ref in refs) {
        const produit = MYS.PRODUITS.get(ref);
        if (typeof produit !== 'undefined') {
          const pdt = {};
          map(outputMappage, (modelKey, outputkey) => {
            if (modelKey === '$quantity$') {
              pdt[outputkey] = refs[ref];
            } else {
              pdt[outputkey] = produit.get(modelKey);
            }
          });
          cart.content.push(pdt);
        }
      }
    } else {
      // Dans le cas où l'on retourne une valeur par référence présente, "à plat"
      inter.getRefs().forEach((ref) => {
        const produit = MYS.PRODUITS.get(ref);
        if (typeof produit !== 'undefined') {
          const pdt = {};
          map(outputMappage, (modelKey, outputkey) => {
            pdt[outputkey] = produit.get(modelKey);
          });
          cart.content.push(pdt);
        }
      });
    }
    return cart;
  }
}
