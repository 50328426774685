<template lang="pug">
  .morph(:style="style", :class="state")
</template>

<script>
export default {
  name: 'morph',
  components: {
  },
  props: {
    id: {
      type: Number,
    },
    from: {
      type: String,
    },
    to: {
      type: String,
    },
  },
  data() {
    return {
      state: [],
      style: {},
    };
  },
  computed: {
  },
  methods: {
    getAndSetStyle(element) {
      const rect = element.getBoundingClientRect();
      return {
        top: `${rect.top}px`,
        left: `${rect.left}px`,
        width: `${rect.right - rect.left}px`,
        height: `${rect.bottom - rect.top}px`,
        background: window.getComputedStyle(element).background,
        borderRadius: window.getComputedStyle(element).borderRadius,
      };
    },
    play() {
      const to = document.querySelector(this.to);
      if (!to) return;
      this.state = ['loaded'];
      this.style = {
        ...this.getAndSetStyle(to),
        opacity: 1,
      };
      window.setTimeout(() => {
        this.style.opacity = 0;
      }, 300);
      window.setTimeout(() => {
        this.$emit('ended', this.id);
      }, 1000);
    },
    rewind() {
      const from = document.querySelector(this.from);
      if (!from) return;
      this.state = [];
      this.style = {
        ...this.getAndSetStyle(from),
        opacity: 0,
      };
    },
  },
  mounted() {
    window._morph = this;
    this.rewind();
    setTimeout(() => {
      this.play();
    }, 10);
  },
};
</script>

<style lang="scss">
.morph.loaded {
  transition: top .5s ease-out,
              left .5s ease-out,
              opacity .3s ease-out,
              width .5s cubic-bezier(0,.98,1,.99),
              height .5s cubic-bezier(0,.98,1,.99),
              background .5s ease-out,
              border-radius .5s ease-out;
}
.morph {
  opacity: 0;
  display : block;
  position: fixed;
  background-color: red;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
