import Backbone from '@/libs/backbone/backbone-light';

Backbone.Collection.prototype.removeWrongModels = function () {
  for (let i = this.length - 1; i >= 0; i--) {
    const model = this.at(i);
    if (!model.isValid()) {
      this.pushToList('warning', `${model.get('id')} : ${model.validationError}`);
      this.remove(model.id);
    }
  }
};
