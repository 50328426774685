/**
 * Représente une Collection de {@link MYS.BB.MODELS.Produit}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Produit[]} produits - Ensemble de produits
 * @augments Backbone.Collection
 */


MYS.BB.COLLECTIONS.Produits = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Produits.prototype */
    {
        model: MYS.BB.MODELS.Produit,
        /**
         * Vérifie si une ou plusieurs images sont présentes dans la collection
         * @param {Produit.id[]} ids - Liste d'identifiants d'{@link MYS.BB.MODELS.Produit}
         * @return {Boolean}
         */
        has: function(ids) {
            var retour = true
            if (ids !== undefined) {
                for (var i = 0; i < ids.length; i++) {
                    this.pushToList('called', ids[i]);
                    if (this.get(ids[i]) === undefined) {
                        this.pushToList('missing', ids[i]);
                        retour = false
                    }
                }
            }

            return retour;
        },
        /**
         * Vérifie si une ou plusieurs images sont présentes dans la collection
         * @param {Produit.id[]} ids - Liste d'identifiants / références de {@link MYS.BB.MODELS.Produit}
         * @return {number} total des prix des références listées
         */
        getTotalByProduits: function(refs) {
            if (_.isString(refs)) {
                refs = [refs];
            }
            var prix = 0;
            var scope = this
            _.each(refs, function(ref, index) {
                if (scope.get(ref) !== undefined) {
                    prix += scope.get(ref).get('prix');
                } else {
                    console.error("ERROR : la référence : " + ref + " n'a pas de prix renseigné");
                }
            });

            return numberize(prix);
        }
    });
