/**
 @typedef {Object} Usage
 @property {string} id - identifiant /  Référence de l'Usage
 @property {string} name - Nom, description de l'Usage
 @property {number} desc - Description de l'Usage
 */

/**
 * Représente le Modèle Usage.
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 * @constructor
 * @param {Usage} usage - Données de l'Usage, permettant sa génération.
 * @augments Backbone.Model
 */


MYS.BB.MODELS.Usage = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Usage.prototype */
    {}
);
