/**
 * Représente une Collection de {@link MYS.BB.MODELS.Interrupteur}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Interrupteur[]} Interrupteurs - Ensemble d'Interrupteurs
 * @augments Backbone.Collection
 */

MYS.BB.COLLECTIONS.Interrupteurs = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Interrupteurs.prototype */
    {
        model: MYS.BB.MODELS.Interrupteur
    }
);
