<template lang="pug">
  .list-item
    ul
      component(
        :is="getSelectorItem"
        v-for="item in items"
        v-bind="item"
        :key="item.id"
        :size.sync="size"
        @selectItem="clickHandler"
      )

</template>

<script>
import selectorItem from '@/components/Selector/Item/item.vue';
import selectorInter from '@/components/Selector/Item/inter.vue';

export default {
  name: 'list-elements',
  components: {
    selectorItem,
    selectorInter,
  },
  props: {
    size: {
      type: String,
      default: 'thumb',
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    closeHandler() {
      // console.log('CLOSE')
    },
    clickHandler(id) {
      this.$emit('selectItem', id);
    },
  },
  computed: {
    displayWholeInter() {
      const gamme = this.$hub.getCurrentInterGamme();
      return gamme && gamme.get('applyWholeInterFromFunctionMenu') === true;
    },
    getSelectorItem() {
      return `selector-${this.displayWholeInter ? 'inter' : 'item'}`;
    },
  },
  mounted() {
    window._vm_list_elements = this;
  },

};
</script>
<style lang="scss">
$btnSize: 30px;

.list-item {
  z-index: 1;

  & > ul {
    background: white;
    width: 100%;
    margin: 0 auto;
    & > li {
      touch-action: auto;
    }
  }
}
</style>
