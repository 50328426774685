import { get } from 'lodash';

export default {
  data() {
    const $MYS = (this.$getNativeMYS && this.$getNativeMYS()) || {};
    return {
      themePrimaryColor: get($MYS, 'CONFIG.themePrimaryColor', [248, 134, 64]),
      themeTransitionColor: get($MYS, 'CONFIG.themeTransitionColor', [230, 85, 106]),
      maxHeightSmallScreen: 568,
      viewportHeight: 0,
      themeAvailableLeftContainerWidth: get($MYS, 'CONFIG.themeLeftContainerWidth', false),
      themeAvailableLeftContainerLimit: get($MYS, 'CONFIG.themeLeftContainerLimit', false),
      themeAvailableRightContainerWidth: get($MYS, 'CONFIG.themeRightContainerWidth', false),
      themeAvailableRightContainerLimit: get($MYS, 'CONFIG.themeRightContainerLimit', false),
      themeAvailableLegrandLogo: get($MYS, 'CONFIG.themeAvailableLegrandLogo', false),
    };
  },
  computed: {
    availableLeftContainerWidth() {
      return this.themeAvailableLeftContainerWidth ? `${this.themeAvailableLeftContainerWidth}px` : false;
    },
    availableLeftContainerLimit() {
      return this.themeAvailableLeftContainerLimit ? this.themeAvailableLeftContainerLimit : false;
    },
    availableRightContainerWidth() {
      return this.themeAvailableRightContainerWidth ? `${this.themeAvailableRightContainerWidth}px` : false;
    },
    displayAvailableLeftContainer() {
      return this.$viewport.width >= this.availableLeftContainerLimit;
    },
    displayAvailableRightContainer() {
      return this.$viewport.width >= this.availableRightContainerLimit;
    },
    availableLegrandLogo() {
      return this.themeAvailableLegrandLogo;
    },
    screenIsTooSmall() {
      return this.$viewport.height <= this.maxHeightSmallScreen;
    },
    STYLES() {
      return {
        primaryColorValues: this.themePrimaryColor.join(', '),
        primaryColor: `rgb(${this.themePrimaryColor.join(', ')})`,
        transitionColor: `rgb(${this.themeTransitionColor.join(', ')})`,
      };
    },
  },
};
