import { remove, isFunction } from 'lodash';

const elements = [];

window.addEventListener('mouseup', mouseUpHandler);
window.addEventListener('touchend', touchEndHandler);

function mouseUpHandler(event) {
  const x = event.clientX;
  const y = event.clientY;
  emitter(x, y, event);
}
function touchEndHandler(event) {
  const x = event.changedTouches[0].clientX;
  const y = event.changedTouches[0].clientY;
  emitter(x, y, event);
}
function emitter(x, y, event) {
  const doc = document.documentElement;
  const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
  const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  const visualPath = document.elementsFromPoint(x - left, y - top);

  const elementsConcernedByRelease = elements.filter((element) => {
    if (visualPath.indexOf(element.el) >= 0 && element.is === 'in') {
      return true;
    }
    if (visualPath.indexOf(element.el) < 0 && element.is === 'out') {
      return true;
    }
    return false;
  });
  elementsConcernedByRelease.forEach(({ el, cb }) => cb(event));
}

function pattern(is) {
  return {
    inserted(el, binding, vnode) {
    },
    bind(el, binding, vnode) {
      if (!isFunction(binding.value)) {
        return;
      }
      elements.push({ el, cb: binding.value, is });
    },
    unbind(el, binding, vnode) {
      remove(elements, (element) => element.el === el);
    },
    update(el, binding, vnode, oldVnode) {
    },
    componentUpdated(el, binding, vnode, oldVnode) {
    },
  };
}

export const release = pattern('in');

export const releaseOutside = pattern('out');
