<template lang="pug">
  .camera-video-wrapper
    video.camera-video(
      playsinline
      autoplay
      :style="videoStyle"
      ref="camera-video"
    )
    .camera-video-information
      p
        | Déplacez votre téléphone lentement et dirigez-le vers le mur
      i.mobile-icon-deplacement
    .camera-controls
      button.camera-zoom.button-small.camera-zoom-less(
        @click="cameraUpdateZoom('out')"
        :disabled="cameraIsZoomMin"
      )
        i.mobile-icon-zoom-moins
      button.button-main.camera-take-picture(
        @click="cameraTakePicture"
      )
        span.hidden
          | prendre une photo
      button.camera-zoom.button-small.camera-zoom-plus(
        @click="cameraUpdateZoom('in')"
        :disabled="cameraIsZoomMax"
      )
        i.mobile-icon-zoom-plus
</template>
<script>
import { ImageCapture } from 'image-capture';

export default {
  name: 'cameraVideo',
  data() {
    return {
      devicesList: [],
      animationDuration: 4500,
      zoomFactor: 0.2,
      constraints: {
        audio: false,
        video: {
          facingMode: 'environment',
        },
      },
    };
  },
  computed: {
    videoStyle() {
      return {
        opacity: this.$camera.active ? 1 : 0,
      };
    },
    cameraIsZoomMin() {
      return parseFloat(this.$camera.zoomMin).toFixed(1) === parseFloat(this.$camera.zoom).toFixed(1);
    },
    cameraIsZoomMax() {
      return parseFloat(this.$camera.zoomMax).toFixed(1) === parseFloat(this.$camera.zoom).toFixed(1);
    },
  },
  watch: {
    '$camera.process': {
      handler(value) {
        if (value && this.$camera.process === 'camera-process-video') {
          this.$camera.interVisuel = document.querySelector('.interVisuel');
          return navigator.mediaDevices
            .getUserMedia(this.constraints)
            .then(this.gotStream)
            .then(this.gotDevices)
            .catch(this.handleError);
        }
        this.$camera.animation = false;
        if (!this.$camera.stream) return;
        this.$camera.stream.getTracks().forEach((track) => {
          track.stop();
        });
      },
      immediate: true,
    },
    '$camera.animation': {
      handler(value) {
        if (value && this.$camera.animation) {
          setTimeout(() => {
            this.$camera.animation = false;
          }, this.animationDuration); // 4500
        }
      },
      immediate: true,
    },
  },
  methods: {
    cameraUpdateZoom(type) {
      console.log('cameraUpdateZoom');
      if (type === 'in' && (this.$camera.zoom + this.zoomFactor) <= this.$camera.zoomMax) {
        this.$camera.zoom += this.zoomFactor;
      } else if (type === 'out' && (this.$camera.zoom - this.zoomFactor) >= this.$camera.zoomMin) {
        this.$camera.zoom -= this.zoomFactor;
      }
    },
    async cameraTakePicture() {
      if (!this.$camera.active) return;
      const track = this.$camera.stream.getTracks()[0];
      const imageCapture = new ImageCapture(track);
      imageCapture.takePhoto()
        .then((blob) => createImageBitmap(blob))
        .then((imageBitmap) => {
          if (!this.$camera.canvas) return;
          this.$camera.drawInCanvas(imageBitmap);
          this.$camera.setProcess('camera-process-photo');
        })
        .catch((error) => console.log(error));
    },
    gotDevices(deviceInfos) {
      this.devicesList = deviceInfos
        .filter((el) => el.kind === 'videoinput')
        .map((el) => el.label);
    },
    gotStream(stream) {
      this.$camera.stream = stream;
      this.$refs['camera-video'].srcObject = stream;
      return navigator.mediaDevices.enumerateDevices();
    },
    handleError(error) {
      console.warn('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
    },
  },
  mounted() {
    this.$camera.videoElement = document.querySelector('.camera-video');
  },
};
</script>
<style lang="scss">
  .camera-video-wrapper{
    .camera-zoom{
      background: none;
      max-width: 100px;
      border: 0;
      padding: 0;
      &:disabled{
        opacity: 0.3;
      }
    }
    .camera-video{
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      opacity: 0;
      transition: all 500ms ease;
    }
    .camera-video-information{
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: all 1000ms ease 0s;
      transform: translateY(-20px);
      flex-direction: column;
      p{
        color: #fff;
        width: 50%;
        font-size: 26px;
        line-height: 120%;
        font-weight: 700;
      }
      i{
        font-size: 114px;
        color: #fff;
        margin-top: 30px;
      }
    }
  }
  .configuration.camera-process-video{
    &.camera-enabled:not(.camera-animation){
      .camera-video-wrapper{
        .camera-controls{
          margin-bottom: 0;
          transition-delay: 500ms;
        }
      }
    }
    &.camera-animation{
      .camera-video-wrapper{
        .camera-video-information{
          transform: translateY(0px);
          opacity: 1;
          transition-delay: 1000ms;
        }
      }
    }
  }
</style>
