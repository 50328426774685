import { debounce } from 'lodash';

export default function (Vue) {
  return new Vue({
    data() {
      return {
        width: null,
        height: null,
      };
    },
    watch: {
    },
    computed: {
      useLateralConfigurationMenu() {
        return this.width > 768;
      },
      useDesktopCartButtons() {
        return this.width >= 1140;
      },
      displayTotalPriceInCart() {
        return this.width >= 1024;
      },
      displayLegrandLogo() {
        return this.width >= 1024;
      },
      displayVerticalFormats() {
        return this.height >= 670;
      },
    },
    methods: {
      onresize() {
        this.width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this.height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      },
    },
    created() {
      this.debouncedOnResize = debounce(this.onresize, 100);
      window.addEventListener('resize', this.debouncedOnResize, false);
      this.debouncedOnResize();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.debouncedOnResize, false);
    },
  });
}
