/**
 * Représente une Collection de {@link MYS.BB.MODELS.Gamme}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Gamme[]} Gammes - Ensemble de Gammes
 * @augments Backbone.Collection
 */

MYS.BB.COLLECTIONS.Gammes = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Gammes.prototype */
    {
        model: MYS.BB.MODELS.Gamme
    }
);
