<template lang="pug">
  li.item.text-align-center.little-padding.medium-padding-bottom.medium-padding-top(
    :class="itemClass"
    @click="clickHandler"
    @touchstart="clickStartHandler"
    @mousedown="clickStartHandler",
    @contextmenu="contextMenuHandler"
  )
    .visuel.left-col.display-inline-block(
      :class="size"
      :style="sizeStyle"
      v-html="visuel"
    )

    .text.tc-dark.medium-margin-top.little-margin-bottom.upper
      span {{ name }}

</template>

<script>
import selectorItem from '@/components/Selector/Item/item.vue';

export default {
  name: 'selector-inter',
  extends: selectorItem,
  components: {
  },
  props: {
    visuel: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    sizeStyle() {
      return {
        position: 'relative',
      };
    },
    itemClass() {
      return [
        this.id,
        `item-${this.type}`,
        'cursor-pointer',
      ];
    },
  },
  methods: {
    clickHandler(event) {
      const areas = this.$hub.inter.getPossibleAreasHTML(this.id, this.size, true);
      if (areas.length === 1) {
        setTimeout(() => {
          const { id } = this;
          const { poste, mod } = areas[0];
          this.$hub.inter.set('fonctions', { id, poste, mod });
          this.$hub.removeFunctionToAdd();
        }, 10);
      }
    },
  },
};
</script>
