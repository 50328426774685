import { getloadedImage } from '@/libs/core/utils';
/**
@typedef {Object} Interrupteur
@property {string} doigt - couleur du doigt
@property {string} finition - identifiant de la {@link MYS.BB.MODELS.Finition}
@property {object[]} fonctions - Liste des fonctions et de leurs position composant, l'interrupteur
@property {string} fonctions.id - identifiant de la {@link MYS.BB.MODELS.Fonction}
@property {integer} fonctions.poste - numéro du poste où est la fonction
@property {integer} fonctions.mod - numéro du module dans poste où est la fonction
@property {string} format - identifiant du {@link MYS.BB.MODELS.Format} de plaque souhaité
@property {integer} quantity - Quantité associé à l'interrupteur
@property {boolean} rotation - Indique si le produit est vertical ou non
 */


/**
 * Représente le Modèle Interrupteur.
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 * @see {@link https://github.com/berzniz/backbone.getters.setters Backbone.GSModel}
 * @param {Interrupteur} interrupteur - Données de la Interrupteur, permettant sa génération.
 * @augments Backbone.GSModel
 * @constructor
 */
MYS.BB.MODELS.Interrupteur = Backbone.GSModel.extend(
    /** @lends MYS.BB.MODELS.Interrupteur.prototype */
    {
        /**
         * Définition des valeurs par défaut le l'interrupteur
         * @return {object} valeurs par défaut
         */
        defaults: function() {
            return {
                rotation: undefined,
                quantity: 1,
                createdAt: null,
                boite: null,
            };
        },
        validate: function(attrs, options) {
            if (typeof this.getPlaque() === "undefined") {
                return "aucune plaque trouvée pour cet interrupteur";
            }
            try {
                this.getDatas()
            } catch (error) {
                return "des données sont obsolètes";
            }
            try {
                this.getVisuel()
            } catch (error) {
                return "impossible de générer l'image";
            }
            // if (this.get("invalide") === true) {
            //     return "Des données demandées ne sont plus existantes en base de données"
            // }
        },
        /**
         * Le constructeur d'Interrupteur
         * @param  {Interrupteur} datas - les données qui définissent l'interrupteur.
         */
        initialize: function(datas) {
            if (MYS.FORMATS) {
                var rotation = false;
                var formatId = this.get('format') || this.attributes.format;
                var format = MYS.FORMATS.get(formatId);
                if (format && format.attributes && format.attributes.horizontal === true) {
                    var rotation = false;
                } else if (format && format.attributes && format.attributes.vertical === true) {
                    var rotation = true;
                } else {
                    console.error('ERROR: Unable to set rotation of inter', format);
                }
                this.attributes.rotation = rotation;
            }
        },
        getters: {
            doigt: function() {
                if (!this.attributes.doigt) {
                    if (!this.attributes.doigt && this.getGamme() && MYS.CONFIG.doigts[this.getGamme()]) {
                        this.attributes.doigt = MYS.CONFIG.doigts[this.getGamme()][0];
                    }
                }
                return this.attributes.doigt
            },
            rotation: function() {
                // On assure la synchro avec la plaque

                if (this.has("format") && MYS.FORMATS) {
                    var format = MYS.FORMATS.get(this.get('format'));
                    if (format.get('horizontal') === true) {
                        this.attributes.rotation = false;
                    } else if (format.get('vertical') === true) {
                        this.attributes.rotation = true;
                    }
                }

                return this.attributes.rotation;
            }
        },
        /**
         * Liste des setters disponibles à utiliser sous la forme monInterrutpeur.set("quantity",2);
         * @enum {function}
         */
        setters: {
            /**
             * le setter "createdAt" Met en place un timestamp de la création de l'interrupteur. La valeur attendue est de type {integer}
             */
            createdAt: function(datas) {
                if (_.isNumber(datas)) {
                    return datas;
                } else {
                    return timestamp();
                }

            },
            /**
             * le setter "quantity" met à jour la quantité du produit. La valeur attendue est de type {integer}
             */
            quantity: function(value) {
                return parseInt(value, 10);
            },
            /**
             * le setter "finition" met à jour la finition du produit. La valeur attendue est un identifiant de {@link MYS.BB.MODELS.Finition}
             */
            finition: function(finitionId) {
                if (MYS.FINITIONS.get(finitionId) !== undefined) {
                    var gammeChange = MYS.FINITIONS.get(finitionId).get('gamme') !== this.getGamme();
                    if (this.has("format")) {
                        var compositionNeeded = JSON.stringify(MYS.FORMATS.get(this.get("format")).get('composition#id'))
                        var formatspossibles = MYS.FORMATS.filter(function(format) {
                            return JSON.stringify(format.get('composition#id')) === compositionNeeded

                        });

                        var plaquesPossibles = []
                        _.each(formatspossibles, function(format) {

                            var plaque = MYS.PLAQUES.findWhere({
                                "finition#id": finitionId,
                                "format#id": format.get('id')
                            });
                            if (typeof plaque != "undefined") {
                                plaquesPossibles.push(plaque);
                            }
                        })
                        if (typeof plaquesPossibles !== "undefined") {
                            plaquesPossibles = plaquesPossibles[0];
                        }
                        if (typeof plaquesPossibles === "undefined") {

                            var format = MYS.FINITIONS.get(finitionId).getPrivelegedPlaqueOrSmallest();

                            this.attributes.format = format.get("format#id");
                            this.attributes.fonctions = undefined;
                            if (gammeChange) {
                                this.attributes.doigt = undefined;
                            }
                            this.attributes.rotation = (format.get("vertical") === true && format.get("horizontal") !== true);
                            this.trigger("change:format");
                            this.trigger("change:fonctions");
                            if (gammeChange) {
                                this.trigger("change:doigt");
                            }
                            this.trigger("change:rotation");
                            this.trigger("change:finition");
                            return finitionId;

                        } else {
                            this.attributes.format = plaquesPossibles.get('format#id')
                            return finitionId;
                        }
                    } else {
                        var format = MYS.FINITIONS.get(finitionId).getPrivelegedPlaqueOrSmallest();

                        this.attributes.format = format.get("format#id");
                        this.attributes.fonctions = undefined;
                        if (gammeChange) {
                            this.attributes.doigt = undefined;
                        }
                        this.attributes.rotation = (format.get("vertical") === true && format.get("horizontal") !== true);
                        this.trigger("change:format");
                        this.trigger("change:fonctions");
                        if (gammeChange) {
                            this.trigger("change:doigt");
                        }
                        this.trigger("change:rotation");
                        this.trigger("change:finition");
                    }
                    return finitionId;
                } else {
                    this.set("invalide", true);
                    console.warn("WARNING : Cette finition n'existe pas/plus " + finitionId);
                }
            },
            /**
             * le setter "doigt" met à jour la couleur du doigt. La valeur attendue est un identifiant de couleur de {@link Doigt}
             */
            doigt: function(doigtId) {
                return doigtId;
            },
            /**
             * le setter "fonctions" met à jour la liste de fonctions qui composent l'interrupteur : {@link Interrupteur}.fonctions
             */
            fonctions: function(fonctionId) {
                if (_.isObject(fonctionId) || _.isArray(fonctionId)) {
                    fonctionId = JSON.parse(JSON.stringify(fonctionId))
                }
                var fonctions;
                var scope = this;
                if (typeof fonctionId !== "undefined") {
                    if (!this.attributes.doigt && this.getGamme() && MYS.CONFIG.doigts[this.getGamme()]) {
                        this.attributes.doigt = MYS.CONFIG.doigts[this.getGamme()][0];
                    }
                    fonctions = this.get("fonctions");
                    if (!this.has("fonctions")) {
                        if (_.isArray(fonctionId)) {
                            fonctions = _.clone(fonctionId);
                        } else {
                            fonctions = [fonctionId];
                        }
                    } else {
                        fonctions = _.reject(fonctions, function(fonction) {
                            var fonctionToInsert = MYS.FONCTIONS.get(fonctionId.id)
                            var fonctionToCheck = MYS.FONCTIONS.get(fonction.id)
                            if (fonction.poste !== fonctionId.poste) {
                                return false
                            }
                            // if fonctionToInsert is larger (contains more modules) than the current function to check and is superimposed on it we remove current function to check
                            if (fonction.mod >= fonctionId.mod && fonction.mod < (fonctionId.mod + fonctionToInsert.get('q'))) {
                                return true
                            }
                            // when fonctionToInsert is smaller (less module) than the current function to check and is superimposed on it we remove current fonction to check
                            if (fonctionId.mod >= fonction.mod && fonctionId.mod < (fonction.mod + fonctionToCheck.get('q'))) {
                                return true
                            }
                            return false
                        })


                        var isCompatible = true
                        var ruleId = "fonction-incompatible-format"
                        var FonctionToInsert = MYS.FONCTIONS.get(fonctionId.id)
                        if (FonctionToInsert.has("rule#ids") && _.contains(FonctionToInsert.get("rule#ids"), ruleId)) {
                            if (typeof MYS.RULES.get(ruleId) != "undefined") {
                                var rule = MYS.RULES.get(ruleId);
                                _.each(_.where(rule.get("actions"), {
                                    "fonction": MYS.FONCTIONS.get(fonctionId.id).getRefs(scope.attributes.doigt, this.get("finition"))
                                }), function(action) {
                                    isCompatible = _.contains(action.formats, scope.getFormat().get('id')) ? false : isCompatible
                                });
                            }
                        }
                        if (!isCompatible) {
                            scope.trigger("message:warning.fonctionIncompatibleFormat")
                        } else {
                            fonctions.push(fonctionId);
                        }
                        // on trie les fonctions par ordre.
                        fonctions = _.sortBy(fonctions, function(fonction) {
                            return parseInt(fonction.poste, 10) * 100 + parseInt(fonction.mod, 10);
                        });
                    }
                }
                var validesFonctions = true;
                _.each(fonctions, function(f) {
                    var Fonction = MYS.FONCTIONS.get(f.id);
                    if (typeof Fonction === "undefined") {
                        scope.set("invalide", true);
                        console.warn("WARNING : Cette fonction n'existe pas/plus : ", fonctionId);
                        validesFonctions = false;
                    }
                });
                if (validesFonctions) {
                    this.trigger("change:fonctions change");
                    return fonctions;
                }
            },
            /**
             * le setter "format" met à jour le format de plaque souhaité, il contrôle que ce changement n'engendre pas de problématiques sur la configuration déjà renseignée. Le paramêtre attendu est un identifiant de {@link MYS.BB.MODELS.Format}.
             */
            format: function(formatId) {
                if (typeof this.attributes.rotation == "undefined") {
                    this.attributes.rotation = false;
                }
                var format = MYS.FORMATS.get(formatId);
                if (typeof format == "undefined") {
                    this.set("invalide", true);
                    console.warn("WARNING : Ce format n'existe pas/plus " + formatId);
                    return
                }

                var isCompatible = true
                var scope = this
                this.getActions('formatIsValid', 'fonctionIncompatibleFormat', function(action) {
                    _.each(action.fonction, function(fonction) {
                        isCompatible = _.contains(scope.getRefs(), fonction) && _.contains(action.formats, format.get('id')) ? false : isCompatible
                    });
                });
                if (!isCompatible) {
                    this.set("invalide", true);
                    this.trigger("message:warning.fonctionIncompatibleFormat")
                    return this.attributes.format;
                }

                var rotation = this.attributes.rotation = format.getOrientation();
                // var rotation = this.get("rotation");
                this.trigger("change:rotation");
                var format = MYS.FORMATS.getFormat(formatId, rotation);
                if (typeof format === "undefined") {
                    rotation = !rotation;
                    format = MYS.FORMATS.getFormat(formatId, !this.get("rotation"));
                }
                if (typeof format === "undefined") {
                    return
                }
                // on verifie si les fonctions logent
                if (!this.formatIsValid(format)) {
                    var solution = this.findDisposition(format);
                    if (_.isObject(solution)) {
                        console.warn('la solutions est : ', solution)
                        this.trigger("change:fonctions change");
                        this.attributes.fonctions = solution;
                        if (rotation !== this.get("rotation")) {
                            this.attributes.rotation = rotation;
                            this.trigger("change:rotation change");
                        }
                        return format.id;
                    } else {
                        this.trigger(solution, {
                            formatId: formatId
                        })
                    }
                    // this.attributes.grid = undefined
                    this.trigger("change:format change");
                    return this.attributes.format;
                } else {
                    // this.attributes.grid = undefined
                    this.attributes.format = format.id
                    this.trigger("change:format change");
                    if (rotation !== this.get("rotation")) {
                        this.attributes.rotation = rotation;
                        this.trigger("change:rotation change");
                    }
                    return format.id;
                }
            },
            /**
             * le setter "rotation" met à jour l'orientation de la plaque. TRUE si le format est vertical, FALSE dans le cas où il est horizontal.
             */
            rotation: function(r) {
                r = !!r;
                var askedOrientation = r ? "vertical" : "horizontal";

                if (!this.has("format")) {
                    return r;
                }

                var format = MYS.FORMATS.getFormat(this.get("format"), r);

                if (typeof format == "undefined") {
                    return !r;
                }

                // ON MODIFIE SANS PASSER PAR LE GETTER !
                // sinon on perd la rotation
                // MODIF 22/04/2016 : On enlève la vérification qu'on a une fonction
                // Toutes les fonctions peuvent être posées en vertical ou horizontal
                // Au cas ou, la logique enlevé est en commentaire
                if (typeof format != "undefined" /*&& _.isArray(this.attributes.fonctions)*/ ) {
                    // var _fonctions = _.clone(this.attributes.fonctions);
                    // for (var i = _fonctions.length - 1; i >= 0; i--) {
                    //     var fonction = MYS.FONCTIONS.get(_fonctions[i].id);
                    //     if (typeof fonction != "undefined" && fonction.has(askedOrientation)) {
                    //         _fonctions[i].id = fonction.get(askedOrientation);
                    //     }
                    // };
                    this.attributes.format = format.id;
                    // this.attributes.fonctions = _fonctions;
                    this.trigger("change:format change");
                    return r;
                }


            }
        },
        getFunctionsNeedingStarterPack: function () {
          var functionsNeedingStarterPack = [];
          var ruleId = "fonction-celiane-need-starter-pack";

          _.each(this.get('fonctions'), function(fonction) {
            var Fonction = MYS.FONCTIONS.get(fonction.id);
            if (Fonction.has("rule#ids") && _.contains(Fonction.get("rule#ids"), ruleId)) {
              if (typeof MYS.RULES.get(ruleId) != "undefined") {
                functionsNeedingStarterPack.push(Fonction);
              }
            }
          });
          return functionsNeedingStarterPack;
        },
        /**
         * Rotation de la plaque, si le format est vertical, il passe à l'horizontal, et inversement.
         * @return {void}
         */
        rotate: function() {
            var rotation = !this.get("rotation");
            if (this.rotatable(rotation)) {
                this.set("rotation", rotation);
                this.attributes.rotation = rotation;
            }
        },
        /**
         * Retourne TRUE si la plaque actuellement assignée peu être tournée dans le sens demandé.
         * @param  {boolean} orientation - Si l'on veut verifier que la plaque peut être vertical il faut saisir TRUE, pour contrôler si l'orientation horizontal est possbile, il faut mettre FALSE
         * @return {boolean} - Retourne TRUE si l'orientation demandée est possible.
         */
        rotatable: function(orientation) {
            var format;
            if (this.has("format")) {
                format = MYS.FORMATS.getFormat(this.get("format"), orientation);
            }
            // On vérifie si le format existe bien
            if (typeof format === "undefined") {
                return false;
            } else {
                // On procède à une vérification complète du format
                var scope = this;

                var seek_format = MYS.PLAQUES.findWhere({
                    'format#id': MYS.FORMATS.getFormat(scope.get("format"), orientation).get('id'),
                    'finition#id': scope.get('finition')
                });
                if (!seek_format) {
                    return false;
                } else {
                    return format.id;
                }
            }
        },
        canSetFinition: function(finitionId, silent) {
            var finition = MYS.FINITIONS.get(finitionId);
            if (!finition) {
                if (silent) {
                    return 'warning.thisFinitionDoesntExists';
                } else {
                    this.trigger('warning.thisFinitionDoesntExists', {
                        finitionId: finitionId
                    });
                    return false;
                }
            }
            if (!this.has("format") && finition.getPrivelegedPlaqueOrSmallest()) {
                return true;
            }
            var plaque = MYS.PLAQUES.findWhere({
                'finition#id': finitionId,
                'format#id': this.get('format')
            })
            var functionLength = (this.attributes.fonctions && this.attributes.fonctions.length) || 0
            var datas = this.toJSON()
            var interForTest = new MYS.BB.MODELS.Interrupteur(datas)
            interForTest.set('finition', finitionId)
            var functionTestLength = (interForTest.attributes.fonctions && interForTest.attributes.fonctions.length) || 0
            var willEraseFunction = functionLength > 0 && functionLength !== functionTestLength
            var gamme = MYS.GAMMES.findWhere({
                id: finition.attributes.gamme
            })
            if (gamme.attributes.syncFingersWithFinition && functionTestLength > 0) {
                var functionsWithFinitionExists = true
                interForTest.attributes.fonctions.forEach((funct) => {
                    var f = MYS.FONCTIONS.findWhere({
                        id: funct.id
                    })
                    if (!f.attributes.doigts.find(doigt => {
                            return doigt.doigt == finition.attributes.id
                        })) {
                        functionsWithFinitionExists = false
                    }
                })
                if (!functionsWithFinitionExists) {
                    if (silent) {
                        return 'warning.noFunctionWithThisFinition';
                    } else {
                        this.trigger('warning.noFunctionWithThisFinition', {
                            finitionId: finitionId
                        });
                        return false
                    }
                }
            }
            if (gamme.attributes.canSyncFunctionWithFinition && functionTestLength > 0) {
              var syncedDoigtsWithFinitionExists = true;
              var functionsToRemove = [];

              interForTest.attributes.fonctions.forEach((funct) => {
                  var f = MYS.FONCTIONS.findWhere({
                      id: funct.id
                  })

                  if (f.canSyncWithFinition() && !f.attributes.doigts.find(doigt => {
                    return (interForTest.attributes.doigt === doigt.doigt && doigt.finition == finition.attributes.id)
                      ||  (interForTest.attributes.doigt === doigt.doigt && typeof doigt.finition === 'undefined');
                  })) {
                    syncedDoigtsWithFinitionExists = false;
                    functionsToRemove.push(funct);
                  }
              })
              if (!syncedDoigtsWithFinitionExists) {
                  if (silent) {
                      return 'warning.noSyncedDoigtWithThisFinition';
                  } else {
                      this.trigger('warning.noSyncedDoigtWithThisFinition', {
                          finitionId: finitionId,
                          functionsToRemove: functionsToRemove,
                      });
                      return false
                  }
              }
          }
          if (!plaque && willEraseFunction) {
              if (silent) {
                  return 'warning.noPlaqueFoundWithThisFinitionAndFormatAndWillEraseFunctions';
              } else {
                  this.trigger('warning.noPlaqueFoundWithThisFinitionAndFormatAndWillEraseFunctions', {
                      finitionId: finitionId
                  });
                  return false
              }
          }
          if (!plaque && !willEraseFunction) {
              if (silent) {
                  return 'warning.noPlaqueFoundWithThisFinitionAndFormat';
              } else {
                  this.trigger('warning.noPlaqueFoundWithThisFinitionAndFormat', {
                      finitionId: finitionId
                  });
                  return false
              }
          }
          return true
        },
        /**
         * Permet de trouver une organisation possible avec les fonctions qui composent l'interrupteur, et un format de plaque.
         * @param  {MYS.BB.MODELS.Format} format - Format à tester avec les fonctions en place
         * @return {string|array} Retourne un tableau de la nouvelle disposition, ou une chaine de caractère spécifant une erreur
         */
        findDisposition: function(format) {
            var _fonctions = _.clone(this.get("fonctions"));
            var fonctions = [];
            for (var w = 0; w < _fonctions.length; w++) {
                fonctions.push(_.clone(_fonctions[w]));
            }
            var compositions = format.get("composition#id");
            var currentFonction = 0;
            var modifiedFonctions = 0;

            for (var currentPoste = 0; currentPoste < compositions.length; currentPoste++) {
                var currentModule = 0;
                var combinaison = MYS.COMPOSITIONS.get(compositions[currentPoste]);
                var combinaisonModulesCount = combinaison.get("q");
                var combinaisonModuleId = combinaison.get("module#id");

                for (currentFonction; currentFonction < fonctions.length; currentFonction++) {
                    var fonctionLargeur = MYS.FONCTIONS.get(fonctions[currentFonction].id).get("q");
                    var fonctionModule = MYS.FONCTIONS.get(fonctions[currentFonction].id).get("module#id");
                    if (currentModule + fonctionLargeur <= combinaisonModulesCount && fonctionModule === combinaisonModuleId) {
                        fonctions[currentFonction].poste = currentPoste;
                        fonctions[currentFonction].mod = currentModule;
                        modifiedFonctions++;
                        currentModule += fonctionLargeur;
                    } else {
                        break;
                    }
                }
            }
            if (modifiedFonctions < fonctions.length) {
                return "warning.noSolutionPleaseAdjust";
            }
            return fonctions;

        },
        /**
         * Permet d'identifier sur un format est compatible avec les fonctions en cours.
         * @param  {MYS.BB.MODELS.Format} format - Format à tester avec les fonctions en place
         * @return {string|boolean} Retourne TRUE si le format est valide, ou une chaine de caractère spécifant une erreur
         */
        formatIsValid: function(format) {
            var formatModulesCount = 0;
            var fonctionsModulesCount = 0;

            var modulesFonctionsIdentiques = true;

            var modulesCombinaisons = null;
            var currentModule;
            var compositions = format.get("composition#id");

            for (var u = 0; u < compositions.length; u++) {
                var combinaison = MYS.COMPOSITIONS.get(compositions[u]);
                currentModule = combinaison.get("module#id");
                if (modulesCombinaisons === null) {
                    modulesCombinaisons = currentModule;
                }
                formatModulesCount += combinaison.get("q");

            }

            var notInFormat = false;
            if (this.has("fonctions")) {
                var fonctions = this.get("fonctions");
                for (var i = 0; i < fonctions.length; i++) {
                    currentModule = MYS.FONCTIONS.get(fonctions[i].id).get("module#id");
                    if (modulesCombinaisons !== currentModule) {
                        modulesFonctionsIdentiques = false;
                    }
                    if (fonctions[i].poste >= compositions.length) {
                        notInFormat = true;
                    } else if (fonctions[i].mod + MYS.FONCTIONS.get(fonctions[i].id).get("q") > MYS.COMPOSITIONS.get(compositions[fonctions[i].poste]).get("q")) {
                        notInFormat = true;
                    }
                    fonctionsModulesCount += MYS.FONCTIONS.get(fonctions[i].id).get("q");
                }

                if (!modulesFonctionsIdentiques) {
                    this.trigger("message:warning.modulesDifferents", format)
                    return false;
                }
                if (fonctionsModulesCount > formatModulesCount) {
                    this.trigger("message:warning.notEnoughtModules")
                    return false;
                }
                if (notInFormat) {
                    this.trigger("message:warning.notInFormat")
                    return false;
                }
            }
            return true;

        },
        /**
         * Retourne un tabeau des espaces occupées sous forme de tableau de rectangles en Millimètres.
         * @return {Rectangle[]}
         */
        getOccupiedAreas: function() {

            var areas = [];
            if (!this.has("fonctions")) {
                console.info("INFO : cet interrupteur n'a pas de fonctions");
            } else {
                var fonctions = this.get("fonctions");
                for (var i = 0; i < fonctions.length; i++) {
                    var fonctionDatas = fonctions[i];
                    var position = MYS.FORMATS.get(this.get("format")).getRectangleCoordonates(fonctionDatas.poste, fonctionDatas.mod);
                    var fonction = MYS.FONCTIONS.get(fonctionDatas.id);
                    areas.push(new MYS.BB.MODELS.Rectangle({
                        left: position.left,
                        top: position.top,
                        width: fonction.get('q') * MYS.MODULES.get(fonction.get("module#id")).get("width"),
                        height: MYS.MODULES.get(fonction.get("module#id")).get("height")
                    }));
                }
            }
            return areas;
        },
        /**
         * Retourne un tabeau des espaces disponibles sous forme de tableau de rectangles en Millimètres.
         * @param {string} fonctionID - identifiant d'une {@link MYS.BB.MODELS.Fonction}
         * @return {Rectangle[]}
         */
        getPossibleAreas: function(fonctionId, allowReplacement) {
            if (!this.has("format")) {
                console.error("ERROR : L'interrupteur n'a pas de format défini");
            }
            var format = MYS.FORMATS.get(this.get("format"));
            if (typeof format == "undefined") {
                console.error("ERROR : L'ID de l'interrupteur est incorrect : '" + this.get("format") + "'");
            }
            var fonction = MYS.FONCTIONS.get(fonctionId);
            if (typeof fonction == "undefined") {
                console.error("ERROR : La fonction sélectionnée n'existe pas : '" + fonctionId + "'");
            }

            var moduleId = fonction.get("module#id");
            var module = MYS.MODULES.get(moduleId);
            if (typeof module == 'undefined') {
                console.error("ERROR : Le module '" + moduleId + "' n'existe pas");
                return [];
            }
            var allAreas = format.getRectangles(fonction.get("module#id"), fonction.get("q"));
            if (!allAreas.length) {
                if (MYS.DRAG && _.isFunction(MYS.DRAG.disable)) {
                    MYS.DRAG.disable();
                }
                this.trigger("message:notCompatiblePlaque", {
                    fonctionId: fonctionId,
                    finitionId: this.get('finition'),
                    doigtId: this.get('doigt'),
                });
                return [];
            }



            var freeAreas;
            if (allowReplacement) {
                freeAreas = allAreas;
            } else {
                var occupiedAreas = this.getOccupiedAreas();
                // on croise les rectangles pour définir ceux qui ne sont pas en contact.
                freeAreas = [];

                for (var i = 0; i < allAreas.length; i++) {
                    var hit = false;
                    for (var u = 0; u < occupiedAreas.length; u++) {
                        if (allAreas[i].intersect(occupiedAreas[u])) {
                            hit = true;
                            break;
                        }
                    }
                    if (!hit) {
                        freeAreas.push(allAreas[i]);
                    }
                }
            }
            if (!freeAreas.length) {
                if (MYS.DRAG && _.isFunction(MYS.DRAG.disable)) {
                    MYS.DRAG.disable();
                }
                this.trigger("message:noMoreSpace");
                return [];
            }
            if (MYS.DRAG && _.isFunction(MYS.DRAG.enable)) {
                MYS.DRAG.enable();

            }
            return freeAreas;
        },
        /**
         * Retourne un tabeau des espaces disponibles sous forme de tableau de rectangles en Pixels.
         * @param {string} fonctionID - identifiant d'une {@link MYS.BB.MODELS.Fonction}
         * @param {string} size - taille des visuels
         * @return {Rectangle[]}
         */
        getPossibleAreasHTML: function(fonctionId, size, allowReplacement) {
            allowReplacement = !!allowReplacement;
            if (typeof size === "undefined") {
                size = "SMALL";
            }
            size = size.toUpperCase();
            if (typeof MYS[size] === "undefined") {
                console.error("ERROR : format d'image non définit");
            }
            var IMAGES = MYS[size];
            var coef = IMAGES.infos.mm2pixels;


            var areas = this.getPossibleAreas(fonctionId, allowReplacement);
            var datas = this.getDatas(size);

            var plaqueWidth = datas.images.plaque.w;
            var plaqueHeight = datas.images.plaque.h;
            var fonction = MYS.FONCTIONS.get(fonctionId);
            var output = [];
            var format = MYS.FORMATS.get(this.get("format"));
            for (var i = 0; i < areas.length; i++) {
                var poste = areas[i].get("poste");
                var mod = areas[i].get("mod");
                var rec = format.getRectangleCoordonates(poste, mod, fonction.get("q"));
                var areaHTML = {
                    x: (plaqueWidth / 2 + rec.left * coef + rec.width * coef / 2 - rec.width * coef / 2),
                    y: (plaqueHeight / 2 + rec.top * coef + rec.height * coef / 2 - rec.height * coef / 2),
                    w: (rec.width * coef),
                    h: (rec.height * coef),
                    poste: poste,
                    mod: mod,
                    fonction: fonctionId
                };
                output.push(areaHTML);
            }
            return output;
        },

        /**
             @typedef {Object} interrupteurExternalDatas
             @property {Object} images - liste des images composant l'interrupteur

             @property {Object[]} images.fonctions - liste des données nécessaires pour afficher les images des fonctions
             @property {string} images.fonctions.id - nom du fichier image sans l'extension (.png)
             @property {number} images.fonctions.h - hauteur en pixels
             @property {number} images.fonctions.w - largeur en pixels
             @property {number} images.fonctions.x - position à gauche en pixels
             @property {number} images.fonctions.y - hauteur à droite en pixels

             @property {Object[]} images.plaque - liste des données nécessaires pour afficher l'image de la plaque
             @property {string} images.plaque.id - nom du fichier image sans l'extension (.png)
             @property {number} images.plaque.h - hauteur en pixels
             @property {number} images.plaque.w - largeur en pixels
             @property {number} images.plaque.x - position à gauche en pixels
             @property {number} images.plaque.y - hauteur à droite en pixels

             @property {Object} positions - liste des positions des composants de l'interrupteur

             @property {Object[]} positions.fonctions - liste des fonctions composant l'interrupteurs
             @property {string} positions.fonctions.id - identifiant faisant référence à une {@link MYS.BB.MODELS.Fonction}
             @property {number} positions.fonctions.h - hauteur en millimètres
             @property {number} positions.fonctions.w - largeur en millimètres
             @property {number} positions.fonctions.x - position à gauche en millimètres
             @property {number} positions.fonctions.y - hauteur à droite en millimètres
             @property {integer} positions.fonctions.poste - index du poste dans lequel est la fonction
             @property {integer} positions.fonctions.mod - index du module qu'occupe la fonction au sein du poste

             @property {Object[]} positions.swaps - liste des positions X et Y où il est possible d'interchanger deux fonctions.
             @property {interger} positions.swaps.from - index de la fonction 1 à interchanger
             @property {interger} positions.swaps.to - index de la fonction 2 à interchanger
             @property {number} positions.swaps.x - position en X du swap se situant entre deux fonctions
             @property {number} positions.swaps.y - position en Y du swap se situant entre deux fonctions
             */

        /**
         * Retourne un tabeau des espaces libres sous forme de tableau de rectangles en Millimètres.
         * @param {string} fonctionID - identifiant d'une {@link MYS.BB.MODELS.Fonction}
         * @return {Rectangle[]}
         */
        getFreeAreas: function() {
            if (!this.has("format")) {
                console.error("ERROR : L'interrupteur n'a pas de format défini");
            }
            var format = MYS.FORMATS.get(this.get("format"));
            if (typeof format == "undefined") {
                console.error("ERROR : L'ID de l'interrupteur est incorrect : '" + this.get("format") + "'");
            }

            var moduleId = MYS.COMPOSITIONS.get(this.getPlaque().getFormat().get('composition#id')[0]).get('module#id');
            var module = MYS.MODULES.get(moduleId);
            if (typeof module == 'undefined') {
                console.error("ERROR : Le module '" + moduleId + "' n'existe pas");
                return [];
            }
            var allAreas = format.getRectangles(moduleId, 1);
            if (!allAreas.length) {
                return [];
            }

            var freeAreas;
            var occupiedAreas = this.getOccupiedAreas();
            // on croise les rectangles pour définir ceux qui ne sont pas en contact.
            freeAreas = [];

            for (var i = 0; i < allAreas.length; i++) {
                var hit = false;
                for (var u = 0; u < occupiedAreas.length; u++) {
                    if (allAreas[i].intersect(occupiedAreas[u])) {
                        hit = true;
                        break;
                    }
                }
                if (!hit) {
                    freeAreas.push(allAreas[i]);
                }
            }
            if (!freeAreas.length) {
                return [];
            }
            return freeAreas;
        },
        /**
         * Retourne un tabeau des espaces disponibles sous forme de tableau de rectangles en Pixels.
         * @param {string} fonctionID - identifiant d'une {@link MYS.BB.MODELS.Fonction}
         * @param {string} size - taille des visuels
         * @return {Rectangle[]}
         */
        getFreeAreasHTML: function(size) {
            if (typeof size === "undefined") {
                size = "SMALL";
            }
            size = size.toUpperCase();
            if (typeof MYS[size] === "undefined") {
                console.error("ERROR : format d'image non définit");
            }
            var IMAGES = MYS[size];
            var coef = IMAGES.infos.mm2pixels;


            var areas = this.getFreeAreas();
            var datas = this.getDatas(size);

            var plaqueWidth = datas.images.plaque.w;
            var plaqueHeight = datas.images.plaque.h;
            var output = [];
            var format = MYS.FORMATS.get(this.get("format"));
            for (var i = 0; i < areas.length; i++) {
                var poste = areas[i].get("poste");
                var mod = areas[i].get("mod");
                var rec = format.getRectangleCoordonates(poste, mod, 1);
                var areaHTML = {
                    x: (plaqueWidth / 2 + rec.left * coef + rec.width * coef / 2 - rec.width * coef / 2),
                    y: (plaqueHeight / 2 + rec.top * coef + rec.height * coef / 2 - rec.height * coef / 2),
                    w: (rec.width * coef),
                    h: (rec.height * coef),
                    poste: poste,
                    mod: mod,
                    fonction: null
                };
                output.push(areaHTML);
            }
            return output;
        },
        /**
         * Retourne un tabeau structuré permettant de reconstruire un interrupteur sans base de données.
         * @param {string} size - taille des visuels ( SMALL | BIG | THUMB )
         * @return {interrupteurExternalDatas}
         */
        getDatas: function(size, options) {
            if (typeof size === "undefined") {
                size = "SMALL";
            }
            size = size.toUpperCase();
            if (typeof MYS[size] === "undefined") {
                console.error("ERROR : format d'image non définit : " + size);
            }
            var syncFunctionWithFinition = false;
            if (typeof options !== "undefined" && options.syncFunctionWithFinition !== "undefined") {
              syncFunctionWithFinition = options.syncFunctionWithFinition;
            }
            var IMAGES = MYS[size];
            var coef = IMAGES.infos.mm2pixels;
            var output = {};
            // IMAGES
            output.images = {};
            output.imagesLight = {};
            output.positions = {};
            // PLAQUE
            var pl_image = IMAGES.get(this.getPlaque().get("imgs"));
            output.images.plaque = {
                x: 0,
                y: 0,
                xFromCenter: 0 - pl_image.get("w") / 2,
                yFromCenter: 0 - pl_image.get("h") / 2,
                w: pl_image.get("w"),
                h: pl_image.get("h"),
                id: pl_image.get("id")
            };
            var pl_image_light = IMAGES.get(this.getPlaque().get("imgs-light"));
            if (pl_image_light) {
                output.imagesLight.plaque = {
                    x: 0,
                    y: 0,
                    xFromCenter: 0 - pl_image_light.get("w") / 2,
                    yFromCenter: 0 - pl_image_light.get("h") / 2,
                    w: pl_image_light.get("w"),
                    h: pl_image_light.get("h"),
                    id: pl_image_light.get("id")
                }
            } else {
                output.imagesLight.plaque = {}
            }
            // MYS.FONCTIONS
            var i;
            output.images.fonctions = [];
            output.imagesLight.fonctions = [];
            output.positions.fonctions = [];
            var format = MYS.FORMATS.get(this.get("format"));
            var rec;
            if (this.has("fonctions")) {
                var fonctions = this.get("fonctions");
                for (i = 0; i < fonctions.length; i++) {
                    //FONCTION
                    var fonction = MYS.FONCTIONS.get(fonctions[i].id);

                    function fillFunctionsImages(key, fonction, containerWidth, containerHeight) {
                        var to = []
                        var fonctions = this.get("fonctions");
                        var doigtData = fonction.getDoigtDatas(this.get("doigt"));
                        if (this.has("finition")) {
                          doigtData = fonction.getDoigtDatas(this.get("doigt"), this.get("finition"));
                        }
                        var imgs = doigtData[key] || [];

                        for (var u = 0; u < imgs.length; u++) {
                            // COMPOSANT D'UNE FONCTION
                            var fo_image = IMAGES.get(IMAGES.get(imgs[u]));
                            if (!fo_image) {
                                console.warn('l\'image de lumière n\'existe pas', imgs[u])
                                continue;
                            }
                            rec = format.getRectangleCoordonates(fonctions[i].poste, fonctions[i].mod, MYS.FONCTIONS.get(fonction.id).get("q"));
                            to.push({
                                x: containerWidth / 2 + rec.left * coef + rec.width * coef / 2 - fo_image.get("w") / 2,
                                y: containerHeight / 2 + rec.top * coef + rec.height * coef / 2 - fo_image.get("h") / 2,
                                xFromCenter: rec.left * coef + rec.width * coef / 2 - fo_image.get("w") / 2,
                                yFromCenter: rec.top * coef + rec.height * coef / 2 - fo_image.get("h") / 2,
                                w: fo_image.get("w"),
                                h: fo_image.get("h"),
                                id: fo_image.get("id")
                            });
                        }
                        return to
                    }
                    var f_imgs_to_add = fillFunctionsImages.bind(this)("imgs", fonction, pl_image.get("w"), pl_image.get("h"))
                    output.images.fonctions = [].concat([], output.images.fonctions, f_imgs_to_add)
                    var f_imgs_light_to_add = fillFunctionsImages.bind(this)("imgs-light", fonction, pl_image.get("w"), pl_image.get("h"))
                    output.imagesLight.fonctions = [].concat([], output.imagesLight.fonctions, f_imgs_light_to_add)

                    rec = format.getRectangleCoordonates(fonctions[i].poste, fonctions[i].mod, MYS.FONCTIONS.get(fonction.id).get("q"));
                    output.positions.fonctions.push({
                        x: pl_image.get("w") / 2 + rec.left * coef,
                        y: pl_image.get("h") / 2 + rec.top * coef,
                        w: rec.width,
                        h: rec.height,
                        id: fonctions[i].id,
                        poste: fonctions[i].poste,
                        mod: fonctions[i].mod
                    });
                }
            }
            output.positions.swaps = [];
            var x1, y1, q1, w1, h1, x2, y2, q2, w2, h2;
            if (format !== undefined) {
                var compositions = format.get("composition#id");
                for (i = 0; i < compositions.length; i++) {
                    var compositionObject = MYS.COMPOSITIONS.get(compositions[i]);
                    var module = MYS.MODULES.get(compositionObject.get("module#id"));
                    if (typeof x1 === "undefined") {
                        x1 = compositionObject.get("x") * coef;
                        y1 = compositionObject.get("y") * coef;
                        q1 = compositionObject.get("q") * coef;
                        w1 = module.get("width") * coef;
                        h1 = module.get("height") * coef;
                    } else {
                        x2 = compositionObject.get("x") * coef;
                        y2 = compositionObject.get("y") * coef;
                        q2 = compositionObject.get("q") * coef;
                        w2 = module.get("width") * coef;
                        h2 = module.get("height") * coef;
                        var hasFrom = false;
                        var hasTo = false;
                        _.each(this.get("fonctions"), function(fonction) {
                            if (fonction.poste == i) {
                                hasTo = true;
                            }
                            if (fonction.poste == i - 1) {
                                hasFrom = true;
                            }
                        });
                        if (hasTo || hasFrom) {
                            output.positions.swaps.push({
                                x: (x1 + w1 + x2) / 2,
                                y: (y1 + y2 + h1) / 2,
                                to: i,
                                from: i - 1
                            });
                        }
                        x1 = x2;
                        y1 = y2;
                        q1 = q2;
                        w1 = w2;
                        h1 = h2;
                    }
                }
            }
            return output;
        },
        /**
         * Retourne l'objet {@link MYS.BB.MODELS.Finition} de la plaque en court
         * @return {MYS.BB.MODELS.Finition}
         */
        getFinition: function() {
            return MYS.FINITIONS.get(this.getPlaque().get("finition#id"));
        },
        /**
         * Retourne l'objet {@link MYS.BB.MODELS.Format} à la plaque en court
         * @return {MYS.BB.MODELS.Format}
         */
        getFormat: function() {
            return MYS.FORMATS.get(this.getPlaque().get("format#id"));
        },
        /**
         * Retourne l'objet {@link MYS.BB.MODELS.Plaque} en court
         * @return {MYS.BB.MODELS.Plaque}
         */
        getPlaque: function() {
            return MYS.PLAQUES.findWhere({
                "finition#id": this.get("finition"),
                "format#id": this.get("format")
            });
        },
        /**
         * Retourne un contenu HTML d'un interrupteur complet
         * @param  {string} size - taille des images ( SMALL | BIG | THUMB )
         * @param  {object} options
         * @return {string} contenu HTML
         */
        getVisuel: function(size, options) {

            if (typeof options === "undefined") {
                options = {};
            }
            if (typeof options.retina === "undefined") {
                options.retina = false;
            }
            if (typeof options.withoutFinition === "undefined") {
                options.withoutFinition = false;
            }
            var retinaFolder = "";
            if (options.retina) {
                retinaFolder = "@2x";
                retina = retinaFolder.toUpperCase();
            }
            if (typeof size === "undefined") {
                size = "small";
            }

            if (typeof size === "undefined") {
                size = "small";
            }
            size = size.toUpperCase();
            if (typeof MYS[size] === "undefined") {
                console.error("ERROR : format d'image non défini");
                size = "SMALL";
            }
            var sizeFolder = size.toLowerCase();
            var datas = this.getDatas(size);
            var html = "";
            var classRotate;
            if (this.get("rotation")) {
                classRotate = 'vertical';
            } else {
                classRotate = 'horizontal';
            }
            var uid = this.get('createdAt') + '_' + Math.round(Math.random() * 10000)
            // var canvasT = +(window.getComputedStyle(document.querySelector('#step2 .step-content')).paddingTop.replace('px', ''))
            // var canvasH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - (+(window.getComputedStyle(document.querySelector('#step2 .step-content')).paddingTop.replace('px', '')))
            var canvasW = 3000;
            var canvasH = 3000;
            var plaqueLightW = datas.imagesLight.plaque.w || 0;
            var plaqueLightH = datas.imagesLight.plaque.h || 0;

            canvasW = Math.max(canvasW, plaqueLightW)
            canvasH = Math.max(canvasH, plaqueLightH)
            var htmlLight = "";
            if (this.isLuminous()) {
                var style = [
                    'position:absolute',
                    'opacity:.87',
                    'top:' + (datas.images.plaque.h - canvasH) / 2 + 'px',
                    'left:' + (datas.images.plaque.w - canvasW) / 2 + 'px',
                    'z-index: 1000000',
                ].join(';');
                htmlLight += '<div class="lights">';
                htmlLight += '<svg viewBox="0 0 ' + canvasW + ' ' + canvasH + '" width="' + canvasW + '" height="' + canvasH + '" style="' + style + '">';
                htmlLight += '<filter id="' + uid + '_invert"><feColorMatrix in="SourceGraphic" type="matrix" values="-1 0 0 0 1   0 -1 0 0 1   0 0 -1 0 1  0 0 0 1 0"/></filter>';
                htmlLight += '<mask id="mask-' + uid + '" >';
                htmlLight += '    <rect x="' + 0 + '" y="' + 0 + '" width="' + canvasW + '" height="' + canvasH + '" fill="white" />';
                if (this.getPlaque().isLuminous()) {
                    if (!_.isEmpty(datas.imagesLight.plaque)) {
                        var x = (canvasW / 2) + datas.imagesLight.plaque.xFromCenter
                        var y = (canvasH / 2) + datas.imagesLight.plaque.yFromCenter
                        htmlLight += '    <image xlink:href="' + PATH() + '/static/2/imgs/' + sizeFolder + retinaFolder + '/' + datas.imagesLight.plaque.id + '.png" height="' + datas.imagesLight.plaque.h + '" width="' + datas.imagesLight.plaque.w + '" x="' + x + '" y="' + y + '" />';
                    }
                }
                for (var i = 0; i < datas.imagesLight.fonctions.length; i++) {
                    var f = datas.imagesLight.fonctions[i]
                    var x = (canvasW / 2) + f.xFromCenter
                    var y = (canvasH / 2) + f.yFromCenter
                    htmlLight += '    <image xlink:href="' + PATH() + '/static/2/imgs/' + sizeFolder + retinaFolder + '/' + f.id + '.png" height="' + f.h + '" width="' + f.w + '" x="' + x + '" y="' + y + '" />';
                }
                htmlLight += '</mask>';
                htmlLight += '<mask id="mask-' + uid + '_2">';
                htmlLight += '    <rect style="" x="' + 0 + '" y="' + 0 + '" width="' + canvasW + '" height="' + canvasH + '" fill="white" />';
                htmlLight += '    <rect style="" x="' + 0 + '" y="' + 0 + '" width="' + canvasW + '" height="' + canvasH + '" fill="black" mask="url(#mask-' + uid + ')" />';
                htmlLight += '</mask>';
                // filter:url(#' + uid + '_invert);
                // html += '<image xlink:href="' + PATH() + '/static/2/imgs/' + sizeFolder + retinaFolder + '/' + f.id + '.png" x="0" y="0" height="' + f.get('h') + '" width="' + f.get('w') + '" />';
                htmlLight += '<rect x="0" y="0" width="' + canvasW + '" height="' + canvasH + '" fill="white" mask="url(#mask-' + uid + '_2)" />';
                htmlLight += '<rect x="0" y="0" width="' + canvasW + '" height="' + canvasH + '" fill="#1f1f23" mask="url(#mask-' + uid + ')" />';
                htmlLight += '</svg>';

                htmlLight += '</div>';
            }

            html += '<div class="interVisuel ' + classRotate + ' ' + size + ' ' + this.getGamme() + ' bgOutsideScreen" style="width:' + datas.images.plaque.w + 'px;height:' + datas.images.plaque.h + 'px;';
            if (!options.withoutFinition) {
                html += 'background-image:url(' + PATH() + '/static/2/imgs/' + sizeFolder + retinaFolder + '/' + datas.images.plaque.id + '.png);';
            }
            html += '">';
            for (var i = 0; i < datas.images.fonctions.length; i++) {
                var f = datas.images.fonctions[i];
                html += '<img height="' + f.h + '" width="' + f.w + '" src="' + PATH() + '/static/2/imgs/' + sizeFolder + retinaFolder + '/' + f.id + '.png" style="z-index:' + (datas.images.fonctions.length - i) + ';position:absolute;left:' + f.x + 'px;top:' + f.y + 'px;" />';
            }
            html += htmlLight;
            html += '</div>';
            // shadow

            html += '<div class="interVisuelShadowContainer ' + classRotate + '"><div class="interVisuelShadow ' + classRotate + ' ' + size + ' ' + this.getGamme() + ' ' + MYS.FINITIONS.get(this.get("finition")).get("ombre") + ' bgOutsideScreen" style="width:' + (datas.images.plaque.w - 4) + 'px;height:' + (datas.images.plaque.h - 4) + 'px;"></div></div>';
            return html;

        },
        /* Retourne un contenu HTML d'un interrupteur EDITABLE
         * @param  {string} size - taille des images ( SMALL | BIG | THUMB )
         * @param  {object} options
         * @return {string} contenu HTML
         */
        getHTML: function(size) {

            if (typeof size === "undefined") {
                size = "small";
            }

            //


            size = size.toUpperCase();
            if (typeof MYS[size] === "undefined") {
                console.error("ERROR : format d'image non définit");
                size = "SMALL";
            }
            var datas = this.getDatas(size);
            var i;
            var html = "";
            var style;
            var classRotate = this.get("rotation") ? 'vertical' : 'horizontal';

            if (this.rotatable(!this.get("rotation"))) {
                html += '<div class="interControllers interControllersRotate ' + classRotate + '" >';

                html += '<input type="button" value=""  class="interControllerRotate" />';

                html += '</div>';
            }

            html += '<div class="interControllers interControllersSwappers ' + classRotate + '" ';
            if (!this.get("rotation")) {
                html += 'style="width:' + datas.images.plaque.w + 'px;height:20px"';
            } else {
                html += 'style="height:' + datas.images.plaque.h + 'px;width:20px"';
            }
            html += '>';

            for (i = 0; i < datas.positions.swaps.length; i++) {

                var s = datas.positions.swaps[i];
                if (this.get("rotation")) {
                    style = 'top:' + (s.y + datas.images.plaque.h / 2) + 'px';
                } else {
                    style = 'left:' + (s.x + datas.images.plaque.w / 2) + 'px';
                }
                html += '<input type="button" value="" data-swap-poste-to="' + s.to + '"';
                html += 'data-swap-poste-from="' + s.from + '"';
                html += 'style="position:absolute;' + style + '"';
                html += 'class="interControllerSwapPostes" />';
            }
            html += '</div>';


            html += this.getVisuel(size, {
                retina: MYS.CONFIG.retina
            });


            html += '<div class="interControllers interControllersDeleters ' + classRotate + '" ';
            if (!this.get("rotation")) {
                html += 'style="width:' + datas.images.plaque.w + 'px;height:20px"';
            } else {
                html += 'style="height:' + datas.images.plaque.h + 'px;width:20px"';
            }
            html += '>';
            for (i = 0; i < datas.positions.fonctions.length; i++) {
                var f = datas.positions.fonctions[i];

                if (this.get("rotation")) {
                    style = 'top:' + (f.y + f.h / 2) + 'px';
                } else {
                    style = 'left:' + (f.x + f.w / 2) + 'px';
                }
                html += '<input type="button" value="" data-fonction-poste="' + f.poste + '" data-fonction-mod="' + f.mod + '" style="position:absolute;' + style + '" class="interControllerDeleteFonction" />';
            }
            html += '</div>';
            return html;
        },
        /** Retourne l'url de l'image générée en PHP
         * @param  {string} size - taille des images ( SMALL | BIG | THUMB )
         * @return {string} URL de l'image compilée au format .png
         */
        getImage: function(size) {
            if (typeof size === "undefined") {
                size = "big@2x";
            }
            var json = this.toJSON();
            delete json.createdAt
            delete json.updatedAt
            return "/action/IMAGE/?" + this.getUrlString(json) + "&size=" + size;
        },
        getUrlString: function(params, keys, isArray) {
            if (keys === undefined) {
                keys = []
            }
            if (isArray === undefined) {
                isArray = false
            }
            var that = this
            var p = _.map(_.keys(params), function(key) {
                var val = params[key]
                if ("[object Object]" === Object.prototype.toString.call(val) || Array.isArray(val)) {
                    keys.push(key)
                    return that.getUrlString(val, keys, false) // Array.isArray(val)
                } else {
                    var tKey = key
                    if (keys.length > 0) {
                        var tKeys = isArray ? keys : keys.concat(key)
                        tKey = _.reduce(tKeys, function(str, k) {
                            return "" === str ? k : (str + '[' + k + ']')
                        }, "")
                    }
                    if (typeof val === "undefined") {
                        val = ""
                    }
                    if (isArray) {
                        return tKey + '[]=' + encodeURIComponent(val)
                    } else {
                        return tKey + '=' + encodeURIComponent(val)
                    }
                }
            }).join('&')
            keys.pop()
            return p
        },
        /**
         * Interverti deux fonctions entre elles
         * @param  {integer} to   index de la fonction 1 à interchanger
         * @param  {integer} from index de la fonction 2 à interchanger
         */
        swapPostes: function(to, from) {
            to = parseInt(to, 10);
            from = parseInt(from, 10);
            var fonctions = this.get("fonctions");
            var changes = false;
            var scope = this;
            _.each(fonctions, function(fonction) {
                if (fonction.poste == from) {
                    fonction.poste = to;
                    changes = true;
                } else if (fonction.poste == to) {
                    fonction.poste = from;
                    changes = true;
                }
            });
            if (changes) {
                this.trigger("change:fonctions change");
            }
        },
        /**
         * Supprime la fonction se situant à une position précise.
         * @param  {integer} poste - index du poste occupé
         * @param  {integer} mod   - index du module au sein du poste occupé
         */
        deleteFonction: function(poste, mod) {
            poste = parseInt(poste, 10);
            mod = parseInt(mod, 10);
            var update = false;
            var newFonctions = [];
            _.each(this.get("fonctions"), function(fonction) {
                if (fonction.poste !== poste || fonction.mod !== mod) {
                    newFonctions.push(_.clone(fonction));
                } else {
                    update = true;
                }
            });
            if (update) {
                this.attributes.fonctions = newFonctions;
                this.trigger("change:fonctions change");
            }
        },
        deleteAllFonction: function() {
            this.attributes.fonctions = [];
            this.trigger("change:fonctions change");
        },
        /**
         * Retoune l'objet {@link MYS.BB.MODELS.Boite} associé à l'interrupteur.
         * @return {MYS.BB.MODELS.Boite}
         */
        getBoite: function() {
            var scope = this;
            var profondeur = this.getProfondeur();
            var boite = undefined;
            var requiredFormatId = scope.getRequiredBoiteFormatId();
            if (window.MYS.BOITES !== undefined && profondeur > 0) {
                boite = MYS.BOITES.find(function(item) {
                    var containsFormat = _.contains(item.get('format#id'), requiredFormatId);
                    var sameType = item.get("type") === scope.get("boite");
                    var goodProfondeur = profondeur <= item.get("prof");
                    return containsFormat && sameType && goodProfondeur;
                });
            }
            if (typeof boite == "undefined" && this.attributes.boite !== null) {
                this.set("boite", null);
            }
            return boite;
        },

        /**
         * Retoune le format requis pour la {@link MYS.BB.MODELS.Boite} associé à l'interrupteur.
         * @return {MYS.BB.MODELS.Format}
         */
        getRequiredBoiteFormatId: function() {
            var fonctionName = "getRequiredBoiteFormatId";
            var formatId = this.get('format');
            this.getActions(fonctionName, 'replace', function(action) {
                if (formatId === action.from && typeof MYS.FORMATS.get(action.to) != "undefined") {
                    formatId = action.to;
                }
            });
            return formatId;
        },

        /**
         * Retoune la profondeur de l'interrupteur
         * @return {integer}
         */
        getProfondeur: function() {
            // s'il n'y a pas de fonction on retourne 1 par défaut
            if (!this.has('fonctions') || this.get('fonctions').length == 0) return 1;
            var profondeurs = [0];
            var _fonctions = this.get('fonctions');
            for (var i = _fonctions.length - 1; i >= 0; i--) {
                var _fonction = MYS.FONCTIONS.get(_fonctions[i].id);
                if (_fonction.has("prof")) {
                    profondeurs.push(_fonction.get("prof"));
                } else {
                    // on assure qu'une boite soit associée.
                    profondeurs.push(1);
                }
            };
            return Math.max.apply(null, profondeurs);
        },

        /**
         * Retoune l'objet {@link MYS.BB.MODELS.Support} associé à l'interrupteur.
         * @return {MYS.BB.MODELS.Support}
         */
        getSupport: function() {
            var fonctionName = "getSupport";
            var scope = this;


            if (window.MYS.SUPPORTS !== undefined) {
                return MYS.SUPPORTS.find(function(item) {
                    // test de base :
                    // on vérifie que le format#id du support est cohérent avec format de la plaque
                    var isCompatible = _.contains(item.get('format#id'), scope.get('format'));
                    //on vérifie que la ref du support existe dans le contour
                    if (isCompatible) {
                        var supportsRefs = item.get('refs')
                        for (var u = 0; u < supportsRefs.length; u++) {
                            if (!MYS.PRODUITS.findWhere({
                                    'id': supportsRefs[u]
                                })) {
                                isCompatible = false
                            }
                        }
                    }
                    // on applique les regles précisant que le support doit contenir certaines références.
                    scope.getActions(fonctionName, 'contains', function(action) {
                        _.each(action.value, function(value) {
                            isCompatible = (isCompatible && _.contains(item.get('refs'), value));
                        });
                    });
                    return isCompatible;
                });
            } else {
                return undefined;
            }
        },
        /**
         * Liste l'ensemble des Actions contenues dans les {@link MYS.BB.MODELS.Rule} qui concernent cet interrupteur
         * @return {Action[]}
         */
        getActions: function(_target, _function, _do) {
            var scope = this;
            var actions = [];
            _.each(this.get('fonctions'), function(fonction) {
                var Fonction = MYS.FONCTIONS.get(fonction.id);
                if (Fonction.has("rule#ids")) {
                    var rules = Fonction.get("rule#ids");

                    for (var i = rules.length - 1; i >= 0; i--) {
                        var ruleId = rules[i];
                        if (typeof MYS.RULES.get(ruleId) != "undefined") {
                            var rule = MYS.RULES.get(ruleId);
                            _.each(_.where(rule.get("actions"), {
                                "target": _target,
                                "function": _function
                            }), function(action) {
                                _do(action);
                            });
                        }

                    };



                }
            });
        },
        /**
         * Liste l'ensemble des références {@link MYS.BB.MODELS.Produit} qui composent l'interrupteur.
         * @return {string[]}
         */
        getRefs: function() {

            var fonctionName = "getRefs";
            var refs = [];
            // plaque :
            if (typeof this.getPlaque() === "undefined") {
                console.error("this.getPlaque()", this);
            } else {
                refs.push(this.getPlaque().get("refs"));
            }
            var scope = this

            // fonctions :
            _.each(this.get("fonctions"), function(fonction) {
                refs.push(MYS.FONCTIONS.get(fonction.id).getDoigtDatas(scope.get("doigt"), scope.get("finition")).refs);
            });

            if (typeof this.getSupport() !== "undefined") {
                refs.push(this.getSupport().get('refs'))
            }

            if (typeof this.getBoite() !== "undefined") {
                refs.push(this.getBoite().get('refs'))
            }

            refs = _.flatten(refs);
            this.getActions(fonctionName, 'delete', function(action) {
                _.each(action.value, function(value) {
                    var index = refs.indexOf(value)
                    if (index >= 0) {
                        refs.splice(index, 1);
                    } else {
                        //console.warn("RULES : la référence " + value + " n'a pas pu être supprimée, car non présente dans la composition actuelle")
                    }
                });
            });
            var alternatives = MYS.RULES.get('alternatives-gsb')

            if (!!alternatives && MYS.CONFIG.ALLOWMERGEDPRODUCT == true) {
                var alternativesActions = alternatives.get('actions')
                var replace = true

                while (replace) {
                    replace = false
                    for (var actionIndex in alternativesActions) {
                        var action = alternativesActions[actionIndex]
                        var refsWorking = _.clone(refs)
                        var allReplaced = true
                        for (var k in action.from) {
                            var fRef = action.from[k]
                            if (!MYS.PRODUITS.get(fRef)) {
                                allReplaced = false
                                break
                            }
                            var index = _.indexOf(refsWorking, fRef)
                            if (index >= 0) {
                                refsWorking.splice(index, 1);
                            } else {
                                allReplaced = false
                                break
                            }
                        }
                        if (allReplaced) {
                            refs = _.clone(refsWorking)
                            refs = refs.concat(action.to)
                            replace = true
                        }
                    }
                }
            }
            return refs;
        },
        /**
         * Calcul le prix unitaire de l'interrupteur
         * @return {integer}
         */
        getPrix: function() {
            var refs = this.getRefs();
            var amount = MYS.PRODUITS.getTotalByProduits(refs);
            return numberize(amount);
        },
        /**
         * Calcul le prix total de l'interrupteur (prix unitaire *  quantité)
         * @return {integer}
         */
        getTotalPrix: function() {
            var prix = this.getPrix() * this.get("quantity");
            return numberize(prix);
        },
        /**
         * retourne le prix de la plaque seulement
         * @return {integer}
         */
        getTarifPlaque: function() {
            return this.getPlaque().getPrix();
        },
        /**
         * Liste l'ensemble des noms des fonctions et leur tarif respectif.
         * @return {object} sous la forme [{tarif:0,usage:""},…]
         */
        getProductFonctions: function() {
            var output = [];
            if (this.has("fonctions")) {
                var scope = this;
                _.each(this.get("fonctions"), function(f) {
                    var fonctionOutput = {};
                    var fonction = MYS.FONCTIONS.get(f.id);
                    var doigt = scope.get("doigt");
                    fonctionOutput.tarif = fonction.getPrix(doigt, scope.get("finition"));

                    if (fonction.has('nom') && fonction.has('usage')) {
                        fonctionOutput.usage = fonction.get('nom')
                    } else if (fonction.has('usage#id')) {
                        fonctionOutput.usage = MYS.USAGES.get(fonction.get("usage#id")).get("name");
                    }
                    // fonctionOutput.usage = MYS.USAGES.get(fonction.get("usage#id")).get("name");
                    output.push(fonctionOutput);
                });
            }

            return output;
        },
        /**
         * Retourne un nom structuré pour définir l'interrupteur, en empilant les différant usages.
         * @return {string}
         */
        getName: function() {
            var output = "";
            if (this.has("fonctions")) {
                var scope = this;
                _.each(this.get("fonctions"), function(fonction) {
                    if (MYS.FONCTIONS.get(fonction.id).has('nom')) {
                        output += MYS.FONCTIONS.get(fonction.id).get('nom') + "\\n";
                    } else if (MYS.FONCTIONS.get(fonction.id).has("usage#id")) {
                        var usageId = MYS.FONCTIONS.get(fonction.id).get("usage#id");
                        output += MYS.USAGES.get(usageId).get("name") + "\\n";
                    }
                });
            }
            return output;
        },
        /**
         * Retourne l'ensemble des descriptifs des usage ssous forme de chaine.
         * @return {string}
         */
        getUsage: function() {
            var output = '';
            if (this.has("fonctions")) {
                _.each(this.get("fonctions"), function(fonction) {
                    if (MYS.FONCTIONS.get(fonction.id).has('usage')) {
                        output += MYS.FONCTIONS.get(fonction.id).get('usage') + "\\n";
                    } else if (MYS.FONCTIONS.get(fonction.id).has("usage#id")) {
                        var usageId = MYS.FONCTIONS.get(fonction.id).get("usage#id");
                        output += MYS.USAGES.get(usageId).get("desc") + "\\n";
                    }
                });
            }

            return output;
        },
        /**
         * Retourne la Gamme à laquelle l'interrupteur est rattaché
         * @return {string}
         */
        getGamme: function() {
            var finition = MYS.FINITIONS.get(this.get("finition"));
            if (!finition) {
                return;
            }
            return finition.get("gamme");
        },
        /**
         * Retourne la Marque à laquelle l'interrupteur est rattaché
         * @return {string}
         */
        getMarque: function() {
            return MYS.FINITIONS.get(this.get("finition")).get("marque");
        },
        /**
         * Retourne l'identifiant de l'image de chaque fonction, ainsi que les positionnement.
         * @return {object}
         */
        getFonctionsVisuels: function() {
            var visuels = [];
            var size = "BIG";
            var datas = this.getDatas(size);
            for (var i = 0; i < datas.images.fonctions.length; i++) {
                var f = datas.images.fonctions[i];
                visuels.push({
                    url: f.id,
                    x: f.x,
                    y: f.y
                });
            }

            return visuels;
        },
        /**
         * Retourne l'identifiant de l'image de la plaque
         * @return {string}
         */
        getPlaqueImage: function() {
            var size = "BIG";
            var datas = this.getDatas(size);
            return datas.images.plaque.id;
        },
        /**
         * Retourne un nom structuré pour définir l'interrupteur, en tenant compte du nombre de postes, la finition, et la couleur des doigts.
         * @return {string}
         */
        getShortTitle: function() {
            var output = [];
            if (this.has("format")) {
                output.push(L(this.get("format")));
            }

            if (this.has("finition")) {
                output.push(L(this.get("finition")).replace(/\s+$/, ''));
            }

            if (this.has("fonctions") && _.isArray(this.get("fonctions")) && this.get("fonctions").length > 0) {
                if (this.has("doigt")) {
                    output[output.length - 1] += ",";

                    if (this.get("fonctions").length == 1) {
                        output.push("fonction");
                    } else {
                        output.push("fonctions");
                    }
                    output.push(L(this.get("doigt")));
                }
            }
            if (this.has("boite")) {
                output[output.length - 1] += ",";
                output.push("boîte " + L(this.get("boite")));
            }
            return output.join(" ");
        },
        getProductCanvas: async function (expectedWidth, expectedHeight) {
          let datas = this.getDatas('big', { retina: true })
          let ratio = Math.max(expectedWidth / datas.images.plaque.w, expectedHeight / datas.images.plaque.h)
          let plaqueWidth = datas.images.plaque.w * ratio;
          let plaqueHeight = datas.images.plaque.h * ratio;
          let canvas = document.createElement('canvas')
          canvas.width = plaqueWidth
          canvas.height = plaqueHeight
          let plaqueImg = await getloadedImage(PATH() + '/static/2/imgs/big@2x/' + datas.images.plaque.id + '.png', plaqueWidth, plaqueHeight)
          canvas.getContext('2d').drawImage(plaqueImg, 0, 0, plaqueWidth, plaqueHeight)
          for (let i = 0; i < datas.images.fonctions.length; i++) {
            const fonction = datas.images.fonctions[i]
            let fonctionImg = await getloadedImage(PATH() + '/static/2/imgs/big@2x/' + fonction.id + '.png', fonction.w, fonction.h)
            canvas.getContext('2d').drawImage(fonctionImg, fonction.x * ratio, fonction.y * ratio, fonction.w * ratio, fonction.h * ratio)
          }
          return canvas
        },
        /**
         * Retourne la comparaison avec un autre interrupteur
         * @param {Interrupteur} Interrupteur - {@link MYS.BB.MODELS.Interrupteur}
         * @return {boolean}
         */
        isEqual: function(inter) {
            var _inter = JSON.parse(JSON.stringify(this.toJSON()))
            var interToCompare = JSON.parse(JSON.stringify(inter.toJSON()))
            // on copie la quantité pour ne pas en tenir compte dans la comparaison.
            // on supprime les createdAt et updatedAt
            interToCompare.quantity = _inter.quantity = null
            interToCompare.createdAt = _inter.createdAt = null
            interToCompare.updatedAt = _inter.updatedAt = null
            return deepCompare(interToCompare, _inter)
        },
        isLuminous: function() {
            var out = []
            _.each(this.get('fonctions'), function(fonction) {
                var fonction = MYS.FONCTIONS.get(fonction.id)
                if (!fonction) {
                    out.push(false)
                    return
                }
                out.push(fonction.isLuminous())
            })
            out.push(this.getPlaque() && this.getPlaque().isLuminous())
            return !_.isEmpty(out) && _.some(out)
        }
    }
);
