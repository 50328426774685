// Groupe d'interrupteurs
MYS.BB.COLLECTIONS.SwitchLot = Backbone.Collection.extend({
    model: MYS.BB.MODELS.Interrupteur,
    addWithDuplicateDetection: function(element) {
        if (!element.getRefs) {
            element = new MYS.BB.MODELS.Interrupteur(element)
        }
        var refs = {}
        _.each(element.getRefs(), function(ref) {
            if (refs[ref] === undefined) {
                refs[ref] = 1
            } else {
                refs[ref] = refs[ref] + 1
            }
        });

        var interToAdd = JSON.parse(JSON.stringify(element.toJSON()))

        interToAdd.createdAt = null
        interToAdd.updatedAt = null

        var found = false;
        for (var i = 0; i < this.length; i++) {
            var interObserved = this.at(i)
            // S'il existe un produit équivalent, on met à jour la quantité.
            if (element.isEqual(interObserved)) {
                found = true;
                interObserved.set("quantity", interObserved.get("quantity") + 1)
                break
            }
        }
        // Sinon on ajoute le sans 
        if (!found) {
            this.add(interToAdd);
        }
    }
});

// MYS.BB.MODELS.Wishlist regroupe une liste de produits
MYS.BB.MODELS.Wishlist = Backbone.GSModel.extend({
    defaults: {
        name: "Salon",
        createdAt: null,
        updatedAt: null,
        activatedAt: 0,
        products: []
    },
    initialize: function() {
        this.on("change", function() {
            this.set("updatedAt", null, {
                silent: true
            });
        });
        if (this.has("products")) {
            this.get("products").on('add', this.productUpdated, this);
            this.get("products").on("change", this.productUpdated, this);
            this.get("products").on("remove", this.productUpdated, this);
        }
    },
    setters: {
        products: function(datas) {
            var products = new MYS.BB.COLLECTIONS.SwitchLot(datas)
            var scope = this;

            for (var i = products.length - 1; i >= 0; i--) {
                var product = products.at(i);
                if (product.get("invalide") === true || !product.isValid()) {
                    products.remove(product);
                }
            }
            return products;
        },

        createdAt: function(datas) {
            if (_.isNumber(datas)) {
                return datas;
            } else {
                return timestamp();
            }

        },
        updatedAt: function(datas) {
            if (_.isNumber(datas)) {
                return datas;
            } else {
                return timestamp();
            }
        },
        activatedAt: function(datas) {
            if (_.isNumber(datas)) {
                return datas;
            } else {
                return timestamp();
            }

        }
    },

    productUpdated: function() {
        this.trigger("change change:products");
    },
    getPrix: function() {
        var prix = 0;
        for (var i = 0; i < this.get("products").length; i++) {
            var inter = this.get("products").at(i);
            if (!inter) {
                continue
            }
            prix += inter.getTotalPrix();
        }
        return numberize(prix);
    },
    getProductsQuantity: function() {
        var quantity = 0;
        for (var i = 0; i < this.get("products").length; i++) {
            var inter = this.get("products").at(i);
            quantity += inter.get("quantity");
        }
        return quantity;
    },
    getRefs: function() {
        var refs = {};
        for (var i = 0; i < this.get("products").length; i++) {
            var all_refs = this.get("products").at(i).getRefs();
            for (var u = 0; u < all_refs.length; u++) {
                var ref = all_refs[u]
                if (refs[ref] === undefined) {
                    refs[ref] = this.get("products").at(i).get('quantity');
                } else {
                    refs[ref] = refs[ref] + this.get("products").at(i).get('quantity');
                }
            }
        }
        return refs;
    }
});

// groupe de "MYS.BB.MODELS.Wishlist"
MYS.BB.COLLECTIONS.Wishlists = Backbone.Collection.extend({
    model: MYS.BB.MODELS.Wishlist,
    getProductsQuantity: function() {
        var quantity = 0;
        this.each(function(room) {
            quantity += room.getProductsQuantity();
        });
        return quantity;
    },
    getActive: function() {
        return this.max(function(el) {
            return el.get('activatedAt')
        });
    }
});

// Un projet (wishlists + BULK)
MYS.BB.MODELS.Project = Backbone.GSModel.extend({
    defaults: {
        name: "Mon Projet",
        bulk: {
            name: "Ma Sélection de produits"
        },
        wishlists: [{
            name: "Ma Sélection"
        }],
        createdAt: null,
        updatedAt: null
    },
    initialize: function() {
        if (this.has("wishlists")) {
            this.get("wishlists").on("change", this.sendChange, this);
            this.get("wishlists").on("add", this.sendChange, this);
            this.get("wishlists").on("remove", this.sendChange, this);
        }
        if (this.has("bulk")) {
            this.get("bulk").on("add", this.sendChange, this);
            this.get("bulk").on("change", this.sendChange, this);
            this.get("bulk").on("remove", this.sendChange, this);
        }

        this.on("change", function() {
            this.set("updatedAt", null, {
                silent: true
            });
        });
    },
    sendChange: function() {
        this.trigger("change");
    },
    getActive: function() {
        if (this.isActive === undefined) {
            this.isActive = false;
            this.trigger("activate");
        }
        return this.isActive;
    },
    setActive: function(datas) {
        if (datas !== this.isActive) {
            this.isActive = datas;
            this.trigger("activate");
        }
    },
    getters: {
        bulk: function() {
            return this.get("wishlists").at(0)
        }
    },
    setters: {
        createdAt: function(datas) {
            if (_.isNumber(datas)) {
                return datas;
            } else {
                return timestamp();
            }

        },
        updatedAt: function(datas) {
            if (_.isNumber(datas)) {
                return datas;
            } else {
                return timestamp();
            }
        },
        wishlists: function(datas) {
            return new MYS.BB.COLLECTIONS.Wishlists(datas);
        },
        bulk: function(datas) {
            return new MYS.BB.MODELS.Wishlist(datas);
        }
    },
    getBulkRefs: function() {
        var refs = this.get("bulk").getRefs()
        return refs;

    },

    getBulkPrix: function() {
        var prix = this.get("bulk").getPrix();
        return numberize(prix);
    },
    getHouseRefs: function() {
        var refs = {}
        var rooms = this.get("wishlists");
        for (var i = 0; i < rooms.length; i++) {
            var room_refs = rooms.at(i).getRefs();
            for (ref in room_refs) {
                if (refs[ref] === undefined) {
                    refs[ref] = room_refs[ref];
                } else {
                    refs[ref] += room_refs[ref];
                }
            }
        }
        return refs;

    },
    getHousePrix: function() {
        var prix = 0;
        var rooms = this.get("wishlists");
        for (var i = 0; i < rooms.length; i++) {
            prix += rooms.at(i).getPrix();
        }
        return numberize(prix);
    },
    getPrix: function() {
        var prix = this.getHousePrix() + this.getBulkPrix();
        return numberize(prix);
    },
    getBulkProductsQuantity: function() {
        var quantity = 0;
        quantity += this.get("bulk").getProductsQuantity();

        return quantity;

    },
    getHouseProductsQuantity: function() {
        var quantity = 0;
        var rooms = this.get("wishlists");
        for (var i = 0; i < rooms.length; i++) {
            quantity += rooms.at(i).getProductsQuantity();
        }
        return quantity;
    },
    getProductsQuantity: function() {
        return this.getBulkProductsQuantity() + this.getHouseProductsQuantity();
    }
});

// Tous les projets :
MYS.BB.COLLECTIONS.Projects = Backbone.Collection.extend({
    model: MYS.BB.MODELS.Project,
    initialize: function() {

        this.bind("change", this.needSave, this);
        this.bind("add", this.needSave, this);
        this.bind("remove", this.needSave, this);
    },
    needSave: function() {
        this.trigger("needSave");
    },

    destroy: function() {
        var model;
        while (model = this.at(0)) {
            model.destroy();
        }
    },
    getActive: function() {
        var output;

        this.comparator = function(model) {
            return model.get('updatedAt');
        }
        this.sort();

        for (var i = 0; i < this.length; i++) {
            if (this.at(i).getActive()) {
                output = this.at(i);
            }
        }
        if (_.isObject(output)) {
            return output;
        } else if (this.at(0) !== undefined) {
            this.at(0).setActive(true);
            return this.at(i);
        }
    }
});
