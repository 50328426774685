import Vue from 'vue';
import Vuex from 'vuex';
import morpher from '@/modules/morpher';

Vue.use(Vuex);

const store = {
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    morpher,
  },
};

export default new Vuex.Store({
  ...store,
});
