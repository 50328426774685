/**
 @typedef {Object} Plaque
 @property {string} id - identifiant de la Plaque
 @property {string} finition#id - Identifiant de la {@link MYS.BB.MODELS.Finition} à laquelle la plaque est rattachée
 @property {string[]} format#id - Liste des ids de {@link MYS.BB.MODELS.Format} compatibles avec la Plaque
 @property {string[]} refs - Liste des références des {@link MYS.BB.MODELS.Produit} composants la Plaque
 @property {string} imgs - identifiant de l'{@link MYS.BB.MODELS.Img} composant la Plaque
 */

/**
 * Représente le Modèle Plaque.
 * @constructor
 * @param {Plaque} plaque - Données de la Plaque, permettant sa génération.
 * @augments Backbone.Model
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 */
MYS.BB.MODELS.Plaque = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Plaque.prototype */
    {
        isLuminous: function() {
            return !_.isEmpty(this.get('imgs-light'))
        },
        /**
         * Calcule le prix de la Plaque, et se basant sur les références des {@link MYS.BB.MODELS.Produit} qui la compose.
         * @return {Number}
         */
        getPrix: function() {
            return MYS.PRODUITS.getTotalByProduits(this.get("refs"));
        },
        /**
         * Vérifie la validité de la Plaque en contrôlant l'existance des références des {@link MYS.BB.MODELS.Produit} et des {@link MYS.BB.MODELS.Img}
         * @return {string|void}  La chaine retournée est un descriptif de l'erreur entrainant l'invalidation
         */
        validate: function() {
            if (!MYS.PRODUITS.has(this.get("refs"))) {
                return "Des références sont manquantes " + this.get("refs");
            }
            if (!MYS.BIG.has(this.get("imgs"))) {
                return "Des images sont manquantes " + this.get("imgs");
            }
            if (MYS.FORMATS.get(this.get("format#id")) === undefined) {
                return "N'utilise pas un format connu : " + this.get("format#id");
            }
        },
        /**
         * Retroune le {@link MYS.BB.MODELS.Format} de  la Plaque
         * @return {MYS.BB.MODELS.Format}
         */
        getFormat: function() {
            return MYS.FORMATS.get(this.get('format#id'));
        },

        /**
         * Retroune le {@link MYS.BB.MODELS.Finition} de  la Plaque
         * @return {MYS.BB.MODELS.Finition}
         */
        getFinition: function() {
            return MYS.FINITIONS.get(this.get('finition#id'));
        },

    }
);
