<template lang="pug">
  .functionsNeedStarterPack
    div
      p(v-html="beforeMessage")
      p(v-html="listTitle")
      //- ul
      //-   li(v-for="functionName in functionsNameList")
      //-     | {{ functionName }}
      p(v-html="link")
      p(v-html="afterMessage")
    div.actions
      input(type="checkbox" v-model="checked")
      label
        | Ne plus m'informer
</template>

<script>

export default {
  name: 'functions-need-starter-pack',
  props: {
    functionsNeedingStarterPack: Array,
    beforeMessage: String,
    afterMessage: String,
    onClick: Function,
  },
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    checked: {
      handler(value) {
        this.$hub.avoidFunctionsNeedingStarterPackMessage = value;
      },
    },
  },
  computed: {
    functionsNameList() {
      const { FONCTIONS } = this.$getNativeMYS();
      return this.functionsNeedingStarterPack.map((funct) => {
        const fonction = FONCTIONS.get(funct.id);
        return fonction?.get('nom');
      });
    },
    listTitle() {
      return (this.functionsNeedingStarterPack.length > 1
        ? 'Les fonctions nécessitant un pack de démarrage :'
        : 'La fonction nécessitant un pack de démarrage :');
    },
    link() {
      return `${this.$getStarterPackMessage().title}<a style="text-decoration:underline;" rel="noopener noreferrer" target="_blank" href="${this.$getStarterPackMessage().url}">${this.$getStarterPackMessage().label}</a>`;
    },
  },
};
</script>

<style lang="scss">
.functionsNeedStarterPack {
  p {
    margin-top: 7px;
  }
}
.actions {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>
