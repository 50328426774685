/**
 * Représente une Collection de {@link MYS.BB.MODELS.USAGE}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {USAGE[]} USAGES - Ensemble de USAGES
 * @augments Backbone.Collection
 */

MYS.BB.COLLECTIONS.Usages = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.USAGES.prototype */
    {
        model: MYS.BB.MODELS.Usage
    });
