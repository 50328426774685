export default function (Vue) {
  return new Vue({
    data() {
      return {
        active: false,
        animation: false,
        showInterface: false,
        stream: undefined,
        videoElement: null,
        canvas: null,
        interVisuel: null,
        // imageCanvasLoaded: false,
        // savedPictureBase64: null,
        process: null,
        zoomEnabled: false,
        zoom: 1,
        zoomMax: 1,
        zoomMin: 0.2,
      };
    },
    computed: {},
    watch: {},
    methods: {
      setProcess(process) {
        // camera-process-video
        // camera-process-photo
        this.process = process;
      },
      getWidth() {
        if (!this.videoElement) return false;
        return this.videoElement.getBoundingClientRect().width;
      },
      getHeight() {
        if (!this.videoElement) return false;
        return this.videoElement.getBoundingClientRect().height;
      },
      savePicture(productImage, onScreenProductTop, onScreenProductLeft) {
        const canvasTMP = document.createElement('canvas');
        canvasTMP.setAttribute('id', 'camera-canvas-tmp');
        canvasTMP.width = this.getWidth();
        canvasTMP.height = this.getHeight();
        canvasTMP.getContext('2d').drawImage(this.canvas, 0, 0);
        const img = new Image();
        img.onload = () => {
          canvasTMP.getContext('2d').drawImage(img, onScreenProductLeft, onScreenProductTop, img.width, img.height);
          const link = document.createElement('a');
          link.download = 'download.png';
          link.href = canvasTMP.toDataURL();
          link.click();
        };
        img.src = productImage.toDataURL();
      },
      loadImgBase64OnCanvas(img64) {
        if (!this.getWidth() && !this.getHeight() && !this.canvas) {
          return;
        }
        const img = new Image();
        img.onload = () => {
          this.canvas.getContext('2d').drawImage(img, 0, 0, this.getWidth(), this.getHeight());
          this.imageCanvasLoaded = true;
        };
        this.canvas.width = this.getWidth();
        this.canvas.height = this.getHeight();
        img.width = this.getWidth();
        img.height = this.getHeight();
        img.src = img64;
      },
      clearCanvas() {
        if (!this.canvas) return;
        this.canvas.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height);
      },
      drawInCanvas(img) {
        if (!this.canvas) return;
        this.canvas.width = this.getWidth();
        this.canvas.height = this.getHeight();
        const ratio = Math.max(this.canvas.width / (img.width), this.canvas.height / (img.height));
        const x = (this.canvas.width - img.width * ratio) / 2;
        const y = (this.canvas.height - img.height * ratio) / 2;
        this.clearCanvas();
        this.canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height, x, y, img.width * ratio, img.height * ratio);
      },
    },
  });
}
