<template lang="pug">
  .inter(:class="interClasses" :style="interStyles")
    .visuel-container(:style="visuelContainerSize")
      .wrapper(v-click-outside="outSideInterhandler" :style="visuelSize")
        .interactive-layer.free-areas()
          drop-area(
            v-for="(freeArea, freeAreaIndex) in freeAreas"
            :key="`free-area-${freeAreaIndex}`"
            :style="freeArea.style"
            :radius="areaBorderRadius"
            v-release="freeAreaHandler"
            )
        .interactive-layer()
          drop-area(
            v-for="(area, areaIndex) in areas"
            :key="`area-${areaIndex}`"
            :style="area.style"
            :radius="areaBorderRadius"
            v-release="getSetFonction(area.fonction, area.poste, area.mod)"
            v-release-outside="outSideInterhandler"
            )
        .visuel
          interrupteur-render(
            v-if="inter"
            :isLuminous="isLuminous"
            :isInteractive='true'
            :value="inter"
            :size="size")

    div.setDarkMode(
      :class="buttonClass"
      v-if="isLuminous"
      @click="changeDarkMode")
      div.sun
      div.toggle
        div.state
      div.moon

    h4.titre
      | {{ title }}

    tarif(v-if="canShowPrice" :price="tarif")
</template>

<script>
import { L } from '@/libs/core/utils';
import { release, releaseOutside } from '@/directives/release';
import { get, reduce } from 'lodash';
import ClickOutside from 'vue-click-outside';
import { setTimeout } from 'timers';
import tarif from '@/components/Interrupteur/tarif.vue';
import dropArea from '@/components/Interrupteur/drop-area/drop-area.vue';
import interrupteurRender from '@/components/Interrupteur/render/render.vue';

export default {
  name: 'interrupteur',
  props: {
    gamme: String,
  },
  components: {
    tarif,
    dropArea,
    interrupteurRender,
  },
  directives: {
    ClickOutside,
    release,
    releaseOutside,
  },
  data() {
    return {
      requireMounted: false,
      maxCoef: 1.7,
      minCoef: 0.69, // basé sur dooxie 4 poste sur iphone 5
      size: 'BIG',
    };
  },
  watch: {
    visuelContainerSizeDatas: {
      handler(value) {
        this.$hub.interVisualHeight = this.visuelContainerSizeDatas.height;
      },
      immediate: true,
    },
  },
  computed: {
    isLuminous() {
      if (!this.inter) return '';
      return this.$hub.inter.isLuminous();
    },
    buttonClass() {
      return this.$hub.darkMode ? 'on' : 'off';
    },
    canShowPrice() {
      return get(this.$getNativeMYS(), 'CONFIG.layout.showPrice', true);
    },
    areaBorderRadius() {
      if (!this.$getNativeMYS().GAMMES) return;
      const gamme = this.$getNativeMYS().GAMMES.get(this.gamme);
      if (!gamme) return;
      return gamme.get('dropAreaBorderRadius') || 0;
    },
    transformCoeff() {
      this.requireMounted; // eslint-disable-line no-unused-expressions
      const detailsHeight = this.screenIsTooSmall ? 120 : 200; // hauteur aproximative
      const margin = 10;
      const selectorsMinWidth = 364; // largeur minimal approximative
      const fingersMinHeight = 56; // hauteur minimal approximative

      const screenWidth = this.$viewport.width;
      const screenHeight = this.$viewport.height;

      // HAUTEUR DE LA NAV
      const navElement = document.querySelector('#nav');
      const navHeight = navElement ? navElement.getBoundingClientRect().height : 0;

      // HAUTEUR DES DOIGTS
      const fingerElement = document.querySelector('.interrupteur-fingers');
      let fingerHeight = fingerElement ? fingerElement.getBoundingClientRect().height : 0;
      fingerHeight = Math.max(fingerHeight, fingersMinHeight);

      let selectorsWidth = get(document.getElementsByClassName('selectors'), '[0].offsetWidth', 0);
      selectorsWidth = Math.max(selectorsMinWidth, selectorsMinWidth);
      // FREE WIDTH SPACE
      let widthWithMargin;
      if (this.$viewport.useLateralConfigurationMenu) {
        widthWithMargin = Math.abs(screenWidth - margin * 2) - selectorsWidth;
      } else {
        widthWithMargin = Math.abs(screenWidth - margin * 2);
      }
      // FREE HEIGHT SPACE
      const freeHeightSpace = screenHeight - fingerHeight - navHeight - detailsHeight;
      // RATIOS
      const idealRatioForWidth = widthWithMargin / this.roundMore(get(this.$hub.inter.getDatas(this.size), 'images.plaque.w', 1));
      const idealRatioForHeight = freeHeightSpace / this.roundMore(get(this.$hub.inter.getDatas(this.size), 'images.plaque.h', 1));
      const idealRatio = Math.min(idealRatioForWidth, idealRatioForHeight);
      return Math.min(Math.max(idealRatio, this.minCoef), this.maxCoef) * (this.$camera.zoomEnabled && !this.$camera.showInterface ? this.$camera.zoom : 1);
    },
    areas() {
      const fonctionId = this.$hub.fonctionToAddToInter;
      const isDraging = this.$hub.isDragingFunction;
      if (!fonctionId || !isDraging) {
        return [];
      }
      const areas = this.$hub.inter.getPossibleAreasHTML(fonctionId, this.size, false);
      areas.forEach((area) => { area.style = this.getStyleForAreaElement(area); });
      return areas;
    },
    freeAreas() {
      const isDraging = this.$hub.isDragingFunction;
      const fonctionId = this.$hub.fonctionToAddToInter;
      if (isDraging || !this.$hub.inter || fonctionId) {
        return [];
      }
      const areas = this.$hub.inter.getFreeAreasHTML(this.size);
      areas.forEach((area) => { area.style = this.getStyleForAreaElement(area); });
      return areas;
    },
    interClasses() {
      return [this.gamme];
    },
    interStyles() {
      return {
        opacity: this.$hub.displayInter ? '1' : '0',
        transition: this.$hub.displayInter ? 'opacity 0.15s linear' : 'none',
      };
    },
    inter() {
      return this.$hub.inter;
    },
    visuelSize() {
      if (!this.inter) return {};
      return { transform: `scale(${this.transformCoeff})`, 'transform-origin': 'top' };
    },
    visuelContainerSizeDatas() {
      if (!this.inter) return {};
      const interDatas = this.$hub.inter.getDatas(this.size);
      return {
        marginLeft: (-1 * Math.max(0, interDatas.images.plaque.w - this.$viewport.width)) / 2,
        marginBottom: this.roundMore((interDatas.images.plaque.h * (this.transformCoeff - 1)) + 20),
        height: this.roundMore(interDatas.images.plaque.h * this.transformCoeff),
      };
    },
    visuelContainerSize() {
      if (!this.inter) return {};
      return {
        'margin-left': `${this.visuelContainerSizeDatas.marginLeft}px`,
        height: `${this.visuelContainerSizeDatas.height}px`,
      };
    },
    visuel() {
      if (!this.inter) return '';
      return this.$hub.inter.getVisuel(this.size, { retina: true, withoutFinition: this.displayWholeInter });
    },
    displayWholeInter() {
      if (!this.inter) return false;
      const gamme = this.$hub.getCurrentInterGamme();
      return gamme && gamme.get('applyWholeInterFromFunctionMenu') === true;
    },
    title() {
      if (!this.inter) return '';
      const finition = this.$getNativeMYS().FINITIONS.get(this.$hub.inter.get('finition'));
      const gamme = finition.get('gamme');
      const format = this.$hub.inter.get('format');
      return `${L(gamme)} ${L(finition.id)} ${L(format)}`;
    },
    tarif() {
      if (!this.inter) return '';
      return this.$hub.inter.getPrix();
    },
  },
  methods: {
    changeDarkMode() {
      this.$hub.darkMode = !this.$hub.darkMode;
    },
    freeAreaHandler(event) {
      this.$hub.$emit('freeAreaClicked');
    },
    outSideInterhandler(event) {
      this.$hub.removeFunctionToAdd();
    },
    getSetFonction(id, poste, mod) {
      return (event) => this.setFonction(id, poste, mod, event);
    },
    setFonction(id, poste, mod, event) {
      setTimeout(() => {
        this.inter.set('fonctions', { id, poste, mod });
        this.$hub.removeFunctionToAdd();
      }, 10);
    },
    getStyleForAreaElement(area) {
      return {
        'border-radius': this.areaBorderRadius,
        top: `${area.y}px`,
        left: `${area.x}px`,
        width: `${area.w}px`,
        height: `${area.h}px`,
      };
    },
    clickHandler() {
    },
  },
  mounted() {
    this.requireMounted = Date.now();
  },
  created() {
    window._inter = this;
  },
};
</script>

<style lang="scss">
$color: rgb(126, 126, 126);
$animation-speed: .15s;

.inter {
  font-size: 14px;
  max-width: 100%;
  .visuel-container {
    position: relative;
    display: block;
    margin-bottom: 15px;
    transition: margin-bottom $animation-speed linear, height $animation-speed linear;
    .wrapper {
      display: inline-block;
      transition: transform $animation-speed linear, width $animation-speed linear;
    }
    .interactive-layer {
      .area {
        position: absolute;
        z-index: 2;
      }
      .area-dots {
        $space: 2px;
        top: $space;
        left: $space;
        border: 2px dashed white; // var(--primary-color);
        width: calc(100% - #{$space * 2});
        height: calc(100% - #{$space * 2});
        position: absolute;
        display: flex;
        align-items: center;
      }
      .area-inner {
        $space: 0px;
        position: absolute;
        top: -$space;
        left: -$space;
        background-color: rgba(var(--primary-color-values), .4);
        width: calc(100% + #{$space * 2});
        height: calc(100% + #{$space * 2});
      }
      span {
        font-size: 2em;
        color: white;
        margin: 0 auto;
      }
    }
    .free-areas {
      opacity: 0;
    }
    .visuel {
      z-index: 1;
      transition: all 500ms ease;
      filter:
        drop-shadow(2px 3px 2px rgba($color, .30))
        drop-shadow(6px 7px 6px rgba($color, .20))
        drop-shadow(10px 11px 12px rgba($color, .10))
        drop-shadow(16px 18px 16px rgba($color, .06))
        drop-shadow(20px 24px 20px rgba($color, .02));

      margin-left: auto;
      margin-right: auto;

      .interVisuel {
        background-size: contain;
      }
    }
  }
  .setDarkMode {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 25px;
    position: relative;
    & > div {
      height: 18px;
      display: inline-block;
      cursor: pointer;
    }
    .moon,
    .sun {
      width: 18px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .toggle {
      width: 30px;
      border-style: solid;
      border-width: 1px;
      border-radius: 9px;
      box-sizing: border-box;
      margin: 0 10px;
      height: 16px;
      .state {
        transition: margin-left .25s linear;
        height: 10px;
        width: 10px;
        margin: 2px;
        border-radius: 6px;
      }
    }
    &.off {
      .state {
        background-color: black;
      }
      .toggle {
        border-color: black;
      }
      .moon {
        background-image: url('/static/mobile/imgs/moon-black.png');
      }
      .sun {
        background-image: url('/static/mobile/imgs/sun-black.png');
      }
    }
    &.on {
      .state {
        margin-left: calc(100% - 12px);
        background-color: white;
      }
      .toggle {
        border-color: white;
      }
      .moon {
        background-image: url('/static/mobile/imgs/moon-white.png');
      }
      .sun {
        background-image: url('/static/mobile/imgs/sun-white.png');
      }
    }
  }
  .titre {
    font-size:15px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .tarif {
    font-size:15px;
    font-weight: 600;
  }
}
.interVisuelShadow {
  display: none;
}
.darkModeEnabled {
  .titre, .tarif, .addToCart, .interrupteur-details {
    color: white;
    position: relative;
  }
}
.camera-process-video,
.camera-process-photo,
.camera-enabled{
  .titre, .tarif{
    display: none;
  }
  .wrapper .visuel{
    transform: scale(1);
    opacity: 1;
  }
  .inter .setDarkMode{
    opacity: 0;
  }
}
.camera-animation{
  .wrapper .visuel{
    transform: scale(.4);
    opacity: 0;
  }
}
</style>
