import { isEqual, trim, deburr } from 'lodash';

export function capitaliseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
window.capitaliseFirstLetter = capitaliseFirstLetter;

export const deepCompare = isEqual;

window.deepCompare = isEqual;

export function PATH() {
  if (window.domainOverride) {
    return window.domainOverride;
  }
  if (process.env.VUE_APP_HOST) {
    return process.env.VUE_APP_HOST;
  }
  if (process.env.NODE_ENV !== 'production') {
    return 'http://local.mon-interrupteur.fr';
  }
  return '';
}
window.PATH = PATH;

export function timestamp() {
  return Date.now();
}
window.timestamp = timestamp;

export function numberize(prix) {
  const price = Number(prix);
  return Number(price.toFixed(2));
}
window.numberize = numberize;

export function noAccent(str) {
  str = trim(deburr(str));
  return str;
}
window.numberize = numberize;
export function L(attribute, options) {
  if (!window.localLexic) {
    return attribute;
  }
  return window.localLexic[attribute] || attribute;
}
window.L = L;

export function getQueryParamsObject() {
  const query = window.location.search.substring(1);

  const re = /([^&=]+)=?([^&]*)/g;
  const decodeRE = /\+/g;

  const decode = function (str) {
    return decodeURIComponent(str.replace(decodeRE, ' '));
  };

  const params = {};
  let e;
  while ((e = re.exec(query)) !== null) {
    let k = decode(e[1]);
    const v = decode(e[2]);
    if (k.substring(k.length - 2) === '[]') {
      k = k.substring(0, k.length - 2);
      if (!params[k]) {
        params[k] = [];
      }
      params[k].push(v);
    } else {
      params[k] = v;
    }
  }

  const assign = function (obj, keyPath, value) {
    const lastKeyIndex = keyPath.length - 1;
    for (let i = 0; i < lastKeyIndex; ++i) {
      const key = keyPath[i];
      if (!(key in obj)) {
        obj[key] = {};
      }
      obj = obj[key];
    }
    obj[keyPath[lastKeyIndex]] = value;
  };

  for (const prop in params) {
    const structure = prop.split('[');
    if (structure.length > 1) {
      const levels = [];
      structure.forEach((item, i) => {
        const key = item.replace(/[?[\]\\ ]/g, '');
        levels.push(key);
      });
      assign(params, levels, params[prop]);
      delete (params[prop]);
    }
  }
  return params;
}
export function getQueryStringAsObject() {
  let b;
  let cv;
  let e;
  let k;
  let sk;
  let v;
  const r = {};
  const d = function (v) {
    return decodeURIComponent(v).replace(/\+/g, ' ');
  };
  const q = window.location.search.substring(1);
  const s = /([^&;=]+)=?([^&;]*)/g;
  const ma = function (v) {
    if (typeof v !== 'object') {
      cv = v;
      v = {};
      v.length = 0;
      if (cv) {
        Array.prototype.push.call(v, cv);
      }
    }
    return v;
  };
  while (e = s.exec(q)) { // eslint-disable-line no-cond-assign
    b = e[1].indexOf('[');
    v = d(e[2]);
    if (b < 0) {
      k = d(e[1]);
      if (r[k]) {
        r[k] = ma(r[k]);
        Array.prototype.push.call(r[k], v);
      } else {
        r[k] = v;
      }
    } else {
      k = d(e[1].slice(0, b));
      sk = d(e[1].slice(b + 1, e[1].indexOf(']', b)));
      r[k] = ma(r[k]);
      if (sk) {
        r[k][sk] = v;
      } else {
        Array.prototype.push.call(r[k], v);
      }
    }
  }
  return r;
}
window.getQueryStringAsObject = getQueryStringAsObject;

export function getParam(id) {
  return getQueryParamsObject()[id];
}
window.getParam = getParam;
export function getImgDatas(refId, size) {
  const imgDatas = {};
  // var retina = ''
  let retinaFolder = '';
  if (MYS.CONFIG.retina) {
    retinaFolder = '@2x';
    // retina = retinaFolder.toUpperCase()
  }

  if (!size) {
    size = 'big';
  }
  const sizeConfig = size.toUpperCase();

  imgDatas.u = `${PATH()}/static/2/imgs/${size.toLowerCase()}${retinaFolder}/${refId}.png`;
  imgDatas.w = MYS[sizeConfig].get(refId).get('w');
  imgDatas.h = MYS[sizeConfig].get(refId).get('h');

  return imgDatas;
}

const PARENT_URL = getParam('parent_url');
export function askToParentFrame(type, datas) {
  window.parent.postMessage(`${type}:${datas}`, PARENT_URL);
}
window.askToParentFrame = askToParentFrame;

export async function getloadedImage(path, w, h) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.width = w;
    img.height = h;
    img.src = path;
  });
}
window.getloadedImage = getloadedImage;

if (!('createImageBitmap' in window)) {
  window.createImageBitmap = async function (blob) {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      img.addEventListener('load', function () {
        resolve(this);
      });
      img.src = URL.createObjectURL(blob);
    });
  };
}

export function appendClick2buy() {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = 'https://legrand.click2buy.com/wishlist-mon-interrupteur.js';
  document.body.appendChild(s);
}
window.appendClick2buy = appendClick2buy;
