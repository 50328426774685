import { isEmpty, throttle, get } from 'lodash';

export default function (Vue) {
  return new Vue({
    data() {
      return {
        inter: null,
        displayInter: true, // permet de définir l'affichage de l'inter depuis partout
        project: null,
        projectKey: 'project-XXXX', // @todo a changer
        fonctionToAddToInter: null, // utilisé pour stocker la fonction à glisser déposer sur l'inter
        isDragingFunction: false, // donne une indication si nous sommes en condition de drag & drop ou pas
        interVisualHeight: 0, // se met à jour lorsque l'affichage de l'interrupteur principal est mis à jour
        darkMode: false, // booleen pour passer en mode nuit sur les inter mosaic
        formElecOn: false,
        isCtaVisible: true,
        avoidFunctionsNeedingStarterPackMessage: false,
      };
    },
    watch: {
      '$MYS.isLoaded': {
        handler(value) {
          if (value) {
            this.createProject();
          }
        },
        immediate: true,
      },
    },
    methods: {
      removeFunctionToAdd() {
        this.fonctionToAddToInter = null;
      },
      createInterrupteur(options = { gamme: 'celiane' }) {
        const { gamme } = options;
        const finition = this.$getNativeMYS().FINITIONS.findWhere({ gamme });
        const formatId = finition ? finition.getPrivelegedPlaqueOrSmallest().get('format#id') : undefined;
        if (!formatId) {
          console.error('PAS DE FORMAT ID');
          this.$modal.show('dialog', {
            title: 'Erreur',
            text: 'Un soucis technique rend le configurateur non fonctionnel : si l\'erreur persiste, n\'hésitez pas à envoyer un message à notre support technique',
            buttons: [
              {
                title: 'Compris !',
                default: true,
                class: 'vue-dialog-button vertical-bg-gradient',
              },
            ],
          });
          return;
        }
        const doigt = this.$getNativeMYS().CONFIG.doigts[gamme][0];
        let fonctions = this.$getNativeMYS()
          .FONCTIONS
          .filter((fonction) => fonction.attributes.gamme === gamme && get(fonction, 'attributes.categories', []).indexOf('les-essentiels') > -1);
        if (isEmpty(fonctions)) {
          fonctions = this.$getNativeMYS().FONCTIONS.where({ gamme });
        }
        fonctions = fonctions.filter((fonction) => get(fonction, 'attributes.doigts', []).some((d) => d.doigt === doigt));
        const fonctionId = fonctions[0].get('id');
        const finitionId = this.$getNativeMYS().FINITIONS.findWhere({ gamme }).get('id');
        const inter = this.$create('Interrupteur', {
          rotation: false,
          quantity: 1,
          createdAt: Date.now(),
          boite: null,
          format: formatId,
          fonctions: [
            {
              id: fonctionId,
              poste: 0,
              mod: 0,
            },
          ],
          doigt,
          finition: finitionId,
        });
        this.$set(this, 'inter', this.$setProxyfiedModel(inter));
        this.inter.trigger('change');
      },
      createProject() {
        let loadedProject = {};
        try {
          loadedProject = JSON.parse(localStorage.getItem(this.projectKey));
          const project = this.$create('Project', loadedProject);
          this.$set(this, 'project', this.$setProxyfiedModel(project));
        } catch (error) {
          console.error('CREATION PROJECT ERROR', error);
          const project = this.$create('Project', loadedProject);
          this.$set(this, 'project', this.$setProxyfiedModel(project));
        }
        this.initProjectChangeGTM();
        this.project.on('change', this.saveProjectToLocalStorageDebounced());
        this.project.trigger('change'); // permet de supprimer les données du localstorage qui n'ont plus de sens
      },
      initProjectChangeGTM() {
        const obj = {
          model: this.project,
          collection: this.project.get('wishlists').at(0),
          refs: this.project.getHouseRefs,
          callbacks: {
            add: (products) => {
              this.$gtm.push({
                event: 'addToCart',
                ecommerce: {
                  currencyCode: 'EUR',
                  add: {
                    products,
                  },
                },
              });
            },
            remove: (products) => {
              this.$gtm.push({
                event: 'removeFromCart',
                ecommerce: {
                  currencyCode: 'EUR',
                  remove: {
                    products,
                  },
                },
              });
            },
          },
        };
        this.$gtm.watch([obj]).init();
      },
      saveProjectToLocalStorage() {
        const { localStorage } = window;
        if (!localStorage || !localStorage.setItem) {
          return;
        }
        localStorage.setItem(this.projectKey, JSON.stringify(this.project.toJSON()));
      },
      saveProjectToLocalStorageDebounced() {
        return throttle(this.saveProjectToLocalStorage, 500, { leading: false, trailing: true });
      },
      getCurrentInterGamme() {
        if (!this.$hub.inter) return false;
        let gamme = this.$hub.inter.getGamme();
        if (!gamme || !this.$getNativeMYS().GAMMES) return false;
        gamme = this.$getNativeMYS().GAMMES.get(gamme);
        if (!gamme) return false;
        return gamme;
      },
    },
    created() {
    },
  });
}
