export default {
  state: {
    morpherEmits: [],
  },
  getters: {
    morpherEmits: (state, getters) => state.morpherEmits,
  },
  mutations: {
    add(state, { from, to }) {
      if (document.querySelector(from) && document.querySelector(to)) {
        state.morpherEmits.push({ from, to, id: Date.now() });
      }
    },
    remove(state, id) {
      for (let morpherIndex = state.morpherEmits.length - 1; morpherIndex >= 0; morpherIndex--) {
        const morpher = state.morpherEmits[morpherIndex];
        if (morpher.id === id) {
          state.morpherEmits.splice(morpherIndex, 1);
        }
      }
    },
  },
  actions: {
  },
  namespaced: true,
};
