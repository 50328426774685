<template lang="pug">
  li.item.text-align-center.little-padding.medium-padding-bottom.medium-padding-top(
    :class="itemClass"
    @click="clickHandler"
    )
    .visuel(:class="size" :style="backgroundImg")

    .text.tc-dark.medium-margin-top.little-margin-bottom.upper
      span {{ name }}

</template>

<script>
import selectorItem from '@/components/Selector/Item/item.vue';

export default {
  name: 'selector-item-finish',
  extends: selectorItem,
  methods: {
    clickHandler(event) {
      this.debouncedEmitHandler(event);
    },
  },
};
</script>

<style lang="scss">
</style>
