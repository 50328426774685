import {
  isFunction, isEmpty, isArray, debounce,
} from 'lodash';
import { numberize } from '@/libs/core/utils';

const { dataLayer } = window;
function GTM() {
  const DEBOUNCE_ACTIONS = 1000;
  const DEBOUNCE_PURCHASE = 9000;
  const memories = {};
  let watchedDatas = [];
  const init = function () {
    for (const i in watchedDatas) {
      const watchedData = watchedDatas[i];
      try {
        const refs = watchedData.refs.call(watchedData.model);
        const _refs = formatRefsAsObject(refs);
        memories[watchedData.id] = JSON.parse(JSON.stringify(_refs));
      } catch (e) {
        // console.log(e)
      }
    }
    // console.info('[ GTM ] init')
    return _gtm;
  };

  function makeid(id) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    const o = `${text}/${id}`;
    return o;
  }

  function getAdd(oldDatas, newDatas) {
    const add = {};
    for (const i in newDatas) {
      if (!oldDatas[i]) {
        add[i] = newDatas[i];
      } else if (oldDatas[i] < newDatas[i]) {
        add[i] = newDatas[i] - oldDatas[i];
      }
    }
    return add;
  }

  function getRemove(oldDatas, newDatas) {
    const remove = {};
    for (const i in oldDatas) {
      if (!newDatas[i]) {
        remove[i] = oldDatas[i];
      } else if (oldDatas[i] > newDatas[i]) {
        remove[i] = oldDatas[i] - newDatas[i];
      }
    }
    return remove;
  }

  let formatRefsAsObject = function (refs) {
    let _refs = {};
    if (isArray(refs)) {
      for (const key in refs) {
        const value = refs[key];
        if (typeof _refs[value] === 'undefined') {
          _refs[value] = 0;
        }
        _refs[value]++;
      }
    } else {
      _refs = refs;
    }
    return _refs;
  };
  const changement = function (evt) {
    let refs;
    try {
      refs = this.refs.call(this.model);
    } catch (e) {
      refs = {};
    }

    const _refs = formatRefsAsObject(refs);
    const chgts = {};
    if (this.callbacks && isFunction(this.callbacks.add)) {
      chgts.add = getAdd(memories[this.id], _refs);
    }
    if (this.callbacks && isFunction(this.callbacks.remove)) {
      chgts.remove = getRemove(memories[this.id], _refs);
    }
    if (this.callbacks && isFunction(this.callbacks.change)) {
      chgts.change = _refs;
    }
    const pdts = {};
    for (const u in chgts) {
      const chgt = chgts[u];
      pdts[u] = populate(chgt);
    }
    memories[this.id] = JSON.parse(JSON.stringify(_refs));
    for (const u in pdts) {
      if (!isEmpty(pdts[u]) && isFunction(this.callbacks[u])) {
        this.callbacks[u](pdts[u]);
      }
    }
  };

  let populate = function (refs) {
    const output = [];
    for (const key in refs) {
      const val = refs[key];
      const pdt = MYS.PRODUITS.get(key);
      if (!pdt) continue;
      const more = pdt.more();
      output.push({
        id: key,
        name: pdt.get('name'),
        brand: more.gamme,
        price: numberize(pdt.get('prix').toFixed(2)),
        category: more.category,
        variant: more.postes,
        // 'color': more.color,
        quantity: val,
      });
    }
    return output;
  };

  const watch = function (datas) {
    watchedDatas = datas;
    for (const i in datas) {
      const data = datas[i];
      data.id = makeid(i);
      memories[data.id] = {};
      if (data.collection) {
        data.collection.bind('change:products change add remove update all', debounce(changement, DEBOUNCE_ACTIONS), data);
      } else {
        data.model.bind('change', debounce(changement, DEBOUNCE_ACTIONS), data);
      }
    }
    // console.info('[ GTM ] watch')
    return _gtm;
  };
  const push = function (layer) {
    if (typeof dataLayer === 'undefined') {
      // console.warn('[ GTM ] datalayer isn\'t defined')
    } else if (typeof layer === 'undefined') {
      // console.warn('[ GTM ] data to send aren\'t defined')
    } else {
      // console.info('[ GTM ] Layer', JSON.stringify(layer, null, 2))
      dataLayer.push(layer);
    }
  };

  function _purchase(refs, callback) {
    if (isFunction(callback)) {
      const _refs = formatRefsAsObject(refs);
      callback(populate(_refs));
    }
  }

  const purchase = debounce(_purchase, DEBOUNCE_PURCHASE, true);
  let _gtm = {
    init,
    push,
    watch,
    formatRefsAsObject,
    populate,
    purchase,
    memories,
  };
  return _gtm;
}

export default GTM;
