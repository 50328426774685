/**
 @typedef {Object} Rule
 @property {string} id - identifiant de la Rule
 @property {Action[]} Actions - Actions de la Rule
 */
/**
 @typedef {Object} Action
 @property {string} target - identifiant de la Rule
 @property {string} property - sur quelle propriété cette action va intervenir
 @property {string} function - à quel moment cette action peut être executée
 @property {mixte} value - données nécessaires à l'action
 */



/**
 * Représente le Modèle Rule.
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 * @constructor
 * @param {Rule} Rule - Données de la Rule, permettant sa génération.
 * @augments Backbone.Model
 */
MYS.BB.MODELS.Rule = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Rule.prototype */
    {

    });
