<template lang="pug">
  a.camera-button-close(
    v-if='$camera.active'
    @click="closeCamera"
  )
</template>

<script>
export default {
  methods: {
    closeCamera() {
      this.$camera.active = false;
      this.$camera.zoomEnabled = false;
      this.$camera.setProcess(null);
      this.$camera.clearCanvas();
    },
  },
};
</script>

<style lang="scss">
  $btnSize: 40px;
  .camera-button-close{
    display: flex;
    justify-content: center;
    align-items: center;
    width: $btnSize;
    height: $btnSize;
    position: absolute;
    top: -70px;
    right: 10px;
    z-index: 12;
    overflow: hidden;

    &:before {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $btnSize - 10px;
      height: $btnSize - 10px;
      font-size: 30px;
      box-sizing: border-box;
      content: "\2715";
      color: white;
    }
  }
</style>
