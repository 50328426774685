import Backbone from '@/libs/backbone/backbone-light';
import _ from '../lodash/index';

Backbone.Collection.prototype.pushToList = function (list, element) {
  if (this[`list_${list}`] === undefined) {
    this[`list_${list}`] = [];
  }
  this[`list_${list}`].push(element);
};

Backbone.Model.prototype.pushToList = Backbone.Collection.prototype.pushToList;

Backbone.Collection.prototype.readList = function (list) {
  if (this[`list_${list}`] !== undefined) {
    this[`list_${list}`] = _.uniq(this[`list_${list}`]);
    let str = JSON.stringify(this[`list_${list}`]);
    str = str.replace(/'/g, '');
    str = str.replace(/\]/g, '');
    str = str.replace(/\[/g, '');
    str = str.replace(/,/g, '\n');
    return str;
  }
  return undefined;
};

Backbone.Model.prototype.readList = Backbone.Collection.prototype.readList;
