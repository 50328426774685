<template lang="pug">
  .foundOnePlaqueForThisFunction
    | {{ text }}
    div.allSolutionFound
      .text-align-center.medium-margin-top.medium-margin-bottom(v-for="inter in inters" :key="inter.attributes.finitionId")
        .display-inline-block.position-relative(v-html='inter.getVisuel()' @click="select(inter)")
</template>

<script>
import { map } from 'lodash';

export default {
  name: 'found-one-plaque-for-this-function',
  props: {
    text: String,
    inter: Object,
    plaqueIds: Array,
    select: Function,
  },
  data() {
    return {
    };
  },
  watch: {
  },
  computed: {
    inters() {
      return map(this.plaqueIds, (plaqueId) => {
        const plaque = this.$getNativeMYS().PLAQUES.get(plaqueId);
        const format = plaque.getFormat();
        const finition = plaque.get('finition#id');
        const rotation = format.get('vertical') === true && format.get('horizontal') !== true;
        let inter = {
          ...this.inter, finition, format: plaque.get('format#id'), rotation,
        };
        inter = this.$create('Interrupteur', inter);
        return inter;
      });
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
.allSolutionFound {
  max-height: 50vh;
  overflow: auto;
}
</style>
