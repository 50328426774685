/**
 * Représente une Collection de {@link MYS.BB.MODELS.Fonction}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Fonction[]} fonctions - Ensemble de fonctions
 * @augments Backbone.Collection
 */
MYS.BB.COLLECTIONS.Fonctions = Backbone.Collection.extend({
    model: MYS.BB.MODELS.Fonction,
    findAllByGamme: function(gamme) {
        var filteredFONCTIONS = _.filter(this.models, function(fonction) {
            return gamme === fonction.get("gamme");
        })
        var sorter = _.sortBy || _.orderBy
        filteredFONCTIONS = sorter(filteredFONCTIONS, function(fonction) {
            return fonction.get('position-marketing')
        })
        return new MYS.BB.COLLECTIONS.Fonctions(filteredFONCTIONS);
    },
    getCheapestFonctionByGamme: function(gamme) {
        var fonctions = this.findAllByGamme(gamme)
        var fonctionsSortedByPrice = fonctions.sortBy(function(fonction) {
            var prices = _.map(fonction.get('doigts'), function(doigt) {
                return MYS.PRODUITS.getTotalByProduits(doigt.refs)
            })
            return Math.max.apply(null, prices)
        })
        return fonctionsSortedByPrice[0]
    },
    findAllDoigtsByGamme: function(gamme) {
        var allDoigts = [];
        this.findAllByGamme(gamme).each(function(fonction) {
            var doigts = fonction.get("doigts");
            for (var i = 0; i < doigts.length; i++) {
                var doigt = doigts[i];
                if (
                    MYS.CONFIG.doigts === undefined || (MYS.CONFIG.doigts[gamme] !== undefined && _.contains(MYS.CONFIG.doigts[gamme], doigt.doigt))
                ) {
                    allDoigts.push(doigt.doigt);
                }

            }
        });
        return _.uniq(allDoigts);
    }
});
