<template lang="pug">
  .list-item
    ul
      li.item(
        v-for="item in items"
        :key="item.id"
        :class="previewClass(item.id)"
        @click="clickHandler(item.id)"
        )
        .preview-container
          format-preview(:id="item.id" v-bind="formatStructure(item.id)")
          .text.tc-dark.little-margin-bottom.upper(v-if="item.name")
            span {{ item.name }}

</template>

<script>
import { get } from 'lodash';
import formatPreview from '@/components/Selector/Format/preview.vue';

export default {
  name: 'list-formats',
  components: {
    formatPreview,
  },
  props: {
    size: {
      type: String,
      default: 'thumb',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    inter() {
      return this.$hub.inter;
    },
  },
  methods: {
    previewClass(id) {
      return {
        active: get(this.inter, 'attributes.format') === id,
      };
    },
    closeHandler() {
      // console.log('CLOSE')
    },
    clickHandler(id) {
      this.$emit('selectItem', id);
    },
    formatStructure(id) {
      if (!this.inter) return {};
      const finition = this.inter.get('finition');
      const isLuminous = this.$getNativeMYS().PLAQUES.findWhere({
        'finition#id': finition,
        'format#id': id,
      }).isLuminous();

      return Object.assign(this.$getNativeMYS().FORMATS.get(id).getStructure(1), { isLuminous });
    },
  },
  mounted() {
    window._vm_list_elements = this;
  },

};
</script>
<style lang="scss">
$color: rgb(0, 0, 0);
$btnSize: 30px;
.last-selector .list-formats {
  ul {
    align-items: center;
    li{
      cursor: pointer;
      .preview-container {
        padding: 10px;
      }
    }
  }
  li:hover,
  li.active {
    background-color: rgba(var(--primary-color-values), 0.1);
    .plaque-bg {
      fill: white
    }
  }

  svg {
    width: 100%;
    filter:
        drop-shadow(0px 0px 1px rgba($color, .15))
        drop-shadow(2px 2px 2px rgba($color, .10))
        drop-shadow(3px 3px 3px rgba($color, .05));
        // drop-shadow(4px 4px 4px rgba($color, .03))
        // drop-shadow(5px 5px 5px rgba($color, .01))
  }
}
</style>
