<template lang="pug">
  .camera-photo-wrapper
    canvas.camera-canvas
    .camera-controls
      button.button-small.camera-show-interface(
        @click="toggleShowInterface"
      )
        i.mobile-icon-reglage

      button.button-main.camera-take-picture(
        @click="cameraTakePicture"
      )
      button.button-small.camera-save-picture(
        @click="cameraSavePicture"
      )
        i.mobile-icon-telechargement

</template>

<script>
export default {
  name: 'cameraPhoto',
  methods: {
    cameraTakePicture() {
      this.$camera.setProcess('camera-process-video');
    },
    toggleShowInterface() {
      this.$camera.showInterface = !this.$camera.showInterface;
    },
    async cameraSavePicture() {
      if (!this.$camera.interVisuel) return;
      const {
        width, height, top, left,
      } = this.$camera.interVisuel.getBoundingClientRect();
      this.$hub.inter
        .getProductCanvas(width, height)
        .then((productImage) => {
          this.$camera.savePicture(productImage, top, left);
        });
    },
  },
  mounted() {
    this.$camera.canvas = document.querySelector('.camera-canvas');
  },
};
</script>

<style lang="scss">
  .camera-photo-wrapper{
    .camera-canvas{
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      opacity: 0;
      transition: all 500ms ease;
    }
  }
  .camera-process-photo{
    &:not(.show-interface){
      &.camera-enabled{
        .camera-photo-wrapper{
          .camera-controls{
            margin-bottom: 0;
            transition-delay: 500ms;
          }
        }
      }
    }
    &.show-interface{
      .camera-canvas{
        filter: blur(10px);
      }
    }
    .camera-canvas{
      opacity: 1;
    }
  }
</style>
