<template lang="pug">
  a.camera-button-activation(
      @click="activateCamera"
      v-if='!$camera.active'
    )
      i.mobile-icon-visualisation
      span Visualisez chez vous
</template>

<script>
export default {
  methods: {
    activateCamera() {
      this.$camera.active = true;
      this.$camera.animation = true;
      this.$camera.showInterface = false;
      this.$camera.setProcess('camera-process-video');
      this.$camera.zoomEnabled = true;
      this.$hub.darkMode = false;
      this.$gtm.push({
        event: 'eventGA',
        eventCategory: this.$getNativeMYS().CONFIG.contour,
        eventAction: 'activationRA',
        eventLabel: this.$hub.getCurrentInterGamme().get('id'),
      });
    },
  },
};
</script>

<style lang="scss">
.camera-button-activation{
  position: absolute;
  left: 50%;
  transform: translate(-50%, -40px);
  transition: all 250ms ease;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  i{
    font-size: 25px;
    margin-right: 4px;
  }
  span{
    text-decoration: underline;
    font-size: 12px;
    font-weight: 700;
    padding-top: 5px;
  }
}
</style>
