/**
 * Représente une Collection de {@link MYS.BB.MODELS.Finition}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Finition[]} finitions - Ensemble de finitions
 * @augments Backbone.Collection
 */
MYS.BB.COLLECTIONS.Finitions = Backbone.Collection.extend({
    model: MYS.BB.MODELS.Finition,
    /**
     * Retourne les moins cheres {@link MYS.BB.MODELS.Plaque Plaque} associée dans toutes les finitions,
     * parmis une liste de format prédéfinie.
     * @param {array} formatIds - identifiants de {@link MYS.BB.MODELS.Format}
     * @return {MYS.BB.MODELS.Plaque[]}
     */
    findAllCheapestPlaquesByFinitionByFormat: function(formatIds) {
        var plaques = [];
        for (var i = this.length - 1; i >= 0; i--) {
            var plaque = this.at(i).getCheapestPlaqueByFormat(formatIds);
            if (typeof plaque !== 'undefined') {
                plaques.push(plaque.get('id'));
            }
        };
        return plaques;
    }

});
