<template lang="pug">
  .lights
    svg(
      :viewBox="viewBoxValue"
      :width="canvasW"
      :height="canvasH"
      :style="viewBoxStyle")
      filter(
        :id="uid + '_invert'")
        feColorMatrix(
          in='SourceGraphic'
          type='matrix'
          values='-1 0 0 0 1   0 -1 0 0 1   0 0 -1 0 1  0 0 0 1 0')

      mask(:id="'mask-' + uid")
        rect(
          x='0'
          y='0'
          :width="canvasW"
          :height="canvasH"
          fill='white')
        image(
          v-if="plaqueIsLuminous && !isEmpty(this.datas.imagesLight.plaque)"
          :href="imageMaskPlaqueUrl"
          :height="datas.imagesLight.plaque.h"
          :width="datas.imagesLight.plaque.w"
          :x="xImagePlaque"
          :y="yImagePlaque")
        image(
          v-for="(f, fonctionIndex) in datas.imagesLight.fonctions"
          :key="'light-fonction-' + fonctionIndex"
          :href="PATH() + '/static/2/imgs/' + sizeFolder.toLowerCase() + retinaFolder + '/' + f.id + '.png'"
          :height="f.h"
          :width="f.w"
          :x="(canvasW / 2) + f.xFromCenter"
          :y="(canvasH / 2) + f.yFromCenter")
      mask(:id="'mask-' + uid + '_2'")
        rect(
          x='0'
          y='0'
          :width="canvasW"
          :height="canvasH"
          fill='white')
        rect(
          x='0'
          y='0'
          :width="canvasW"
          :height="canvasH"
          fill="black"
          :mask="'url(#mask-' + uid")

      rect(
        x='0'
        y='0'
        :width="canvasW"
        :height="canvasH"
        fill='white'
        :mask="'url(#mask-' + uid + '_2)'")
      rect(
        x='0'
        y='0'
        :width="canvasW"
        :height="canvasH"
        fill='#1f1f23'
        :mask="'url(#mask-' + uid + ')'")
</template>

<script>
import { PATH } from '@/libs/core/utils';
import { isEmpty } from 'lodash';

export default {
  name: 'interrupteur-render-light',
  props: {
    datas: String,
    uid: String,
    plaqueIsLuminous: {
      type: Boolean,
      default: false,
    },
    retinaFolder: {
      type: String,
      default: '',
    },
    sizeFolder: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {
    };
  },
  watch: {
  },
  computed: {
    xImagePlaque() {
      return (this.canvasW / 2) + this.datas.imagesLight.plaque.xFromCenter;
    },
    yImagePlaque() {
      return (this.canvasH / 2) + this.datas.imagesLight.plaque.yFromCenter;
    },
    imageMaskPlaqueUrl() {
      return `${PATH()}/static/2/imgs/${this.sizeFolder.toLowerCase()}${this.retinaFolder}/${this.datas.imagesLight.plaque.id}.png`;
    },
    viewBoxValue() {
      return `0 0 ${this.canvasW} ${this.canvasH}`;
    },
    viewBoxStyle() {
      return `position:absolute; opacity:.87; top:${(this.datas.images.plaque.h - this.canvasH) / 2}px; left:${(this.datas.images.plaque.w - this.canvasW) / 2}px; z-index: 1000000`;
    },
    canvasW() {
      return Math.max(3000, (this.datas.imagesLight.plaque.w || 0));
    },
    canvasH() {
      return Math.max(3000, (this.datas.imagesLight.plaque.h || 0));
    },
  },
  methods: {
    isEmpty,
    PATH,
  },
};
</script>
