import Backbone from '@/libs/backbone/backbone-light';
import _ from '../lodash/index';

Backbone.Model.prototype.toJSON = function (recursive) {
  const json = _.clone(this.attributes);
  recursive && _.each(json, (value, name) => {
    value && _.isFunction(value.toJSON) && (json[name] = value.toJSON(true));
  });
  return json;
};
