<template lang="pug">
  .tarif
    | {{ amount | pricize }}{{ $getCurrency() }} {{ $getTax() }}
</template>

<script>

export default {
  name: 'tarif',
  props: {
    price: {
      type: [Number, String],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    amount() {
      return this.price;
    },
  },
};
</script>

<style lang="scss" scoped>
  .tarif {
    color: var(--primary-color)
  }
</style>
