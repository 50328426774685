/**
 * Représente une Collection de {@link MYS.BB.MODELS.Rule}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Rule[]} Rules - Ensemble de Rules
 * @augments Backbone.Collection
 */

MYS.BB.COLLECTIONS.Rules = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Rules.prototype */
    {
        model: MYS.BB.MODELS.Rule
    });
