<template lang="pug">
  .gammes.view.vertical-bg-gradient
    gamme-list(:gammes='gammes' @gammeSelection="selectionHandler")
      | Choisissez votre collection

    .banner-container
      ad-banner(v-bind="adBanner")
</template>

<script>
import { L } from '@/libs/core/utils';
import { has } from 'lodash';
import gammeList from '@/components/Gamme/list.vue';
import adBanner from '@/components/adBanner.vue';

export default {
  name: 'gammes',
  components: {
    gammeList,
    adBanner,
  },
  data() {
    return {
    };
  },
  computed: {
    adBanner() {
      if (this.$getNativeMYS().CONFIG.adBanners.gammes && Boolean(this.$getNativeMYS().CONFIG.adBanners.gammes.enable)) {
        return this.$getNativeMYS().CONFIG.adBanners.gammes;
      }
      return {
      };
    },
    gammes() {
      const config = Object.keys(this.$getNativeMYS().CONFIG.doigts);
      const out = config.map((key) => {
        let gamme = this.$getNativeMYS().GAMMES.get(key);
        if (!gamme) {
          this.$getNativeMYS().GAMMES.add({ id: key });
          gamme = this.$getNativeMYS().GAMMES.get(key);
        }
        let description = '';
        let thumbUrl = '';
        let thumbRetinaUrl = '';
        if (this.$getNativeMYS().GAMMES) {
          gamme = this.$getNativeMYS().GAMMES.get(key);
          if (gamme.get('description')) {
            description = gamme.get('description');
          }
          if (gamme.get('thumbUrl')) {
            thumbUrl = gamme.get('thumbUrl');
          }
          if (gamme.get('thumbRetinaUrl')) {
            thumbRetinaUrl = gamme.get('thumbRetinaUrl');
          }
        }
        return {
          slug: key,
          name: L(key),
          description,
          thumbUrl,
          thumbRetinaUrl,
          active: gamme.isValid(),
        };
      });
      return out;
    },
  },
  methods: {
    selectionHandler(slug) {
      this.$gtm.push({
        event: 'eventGA',
        eventCategory: this.$getNativeMYS().CONFIG.contour,
        eventAction: 'Choix collection',
        eventLabel: slug,
      });
      this.$router.push({ name: 'config', params: { gamme: slug } }).catch();
    },
  },
  created() {
    window._gammes = this;
  },
};
</script>

<style lang="scss">
.gammes.view {
  min-height: 100%;
}
.banner-container {
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
</style>
