import './backbone/GSModel';
import './backbone/toJSON';
import './backbone/list';
import './backbone/removeWrongModels';
// import Backbone from '@/libs/backbone/backbone-light'
import {
  isString, get, isEmpty, each, isObject, uniq, find, includes, sortBy, map, every,
} from 'lodash';
import {
  PATH, timestamp, capitaliseFirstLetter, getParam,
} from './core/utils';

import 'latest-createjs/lib/preloadjs/preloadjs';
import MYS from './core/config';

const VERTICALE_ORIENTATION = 'verticale';
const FORCE_CONTEXT = getParam('force-context');
function getDoigtsFromParams() {
  const _gammes = getParam('gammes');
  if (isString(_gammes)) {
    const raw = _gammes.split('|').map((el) => el.split(':'));
    const _formattedGammes = {};
    for (const gamme of raw) {
      _formattedGammes[gamme.shift()] = gamme;
    }
    return _formattedGammes;
  }
  return undefined;
}

if (!MYS.CONFIG.MAPPAGE.DB_produits) {
  MYS.CONFIG.MAPPAGE.DB_produits = {
    id: 'id',
    id_reseller: 'gencod',
    name: 'name',
    prix: 'price',
  };
}

const directory = '/static/2/database/db/';
if (FORCE_CONTEXT === 'gsb') {
  MYS.CONFIG.themePrimaryColor = [248, 134, 64];
  MYS.CONFIG.themeTransitionColor = [230, 85, 106];
  MYS.CONFIG.doigts = {
    celiane: ['titane', 'blanc', 'graphite'],
    dooxie: ['blanc', 'alu', 'noir'],
    altege: ['neige', 'nuage', 'nuit'],
    mosaic: ['blanc', 'noir-mat'],
  };
  MYS.CONFIG.URLS.DB_produits.url = '/action/PRODUITS/get.php?contour=gsb';
  MYS.CONFIG.DATABASE.altege.fonctions = [`${directory}fonctions/altege_gsb.json`];
  MYS.CONFIG.DATABASE.altege.finitions = [`${directory}finitions/altege_gsb.json`];
  MYS.CONFIG.DATABASE.altege.plaques = [`${directory}plaques/altege_gsb.json`];

  MYS.CONFIG.DATABASE.celiane.fonctions = [`${directory}fonctions/celiane_gsb.json`];
  MYS.CONFIG.DATABASE.celiane.finitions = [`${directory}finitions/celiane_gsb.json`];
  MYS.CONFIG.DATABASE.celiane.plaques = [`${directory}plaques/celiane_gsb.json`];

  MYS.CONFIG.DATABASE.dooxie.fonctions = [`${directory}fonctions/dooxie_gsb.json`];
  MYS.CONFIG.DATABASE.dooxie.finitions = [`${directory}finitions/dooxie_gsb.json`];
  MYS.CONFIG.DATABASE.dooxie.plaques = [`${directory}plaques/dooxie_gsb.json`];

  MYS.CONFIG.URLS.LIST_fonctionsCategories.url = '/static/2/database/list/list_fonctionsCategories_gsb.min.json';

  if (MYS.CONFIG.displayElectricienButton === undefined) {
    MYS.CONFIG.displayElectricienButton = false; // gsb
  }
  if (!MYS.CONFIG.pdfTheme) {
    MYS.CONFIG.pdfTheme = 'gsb';
  }
  if (!MYS.CONFIG.contour) {
    MYS.CONFIG.contour = 'gsb';
  }
  if (!MYS.CONFIG.pdfUrlPath) {
    MYS.CONFIG.pdfUrlPath = '/action/PDF/distributor/selection/';
  }
} else if (FORCE_CONTEXT === 'pro') {
  MYS.CONFIG.themePrimaryColor = [107, 100, 216];
  MYS.CONFIG.themeTransitionColor = [125, 55, 152];
  MYS.CONFIG.doigts = {
    celiane: [
      'blanc', 'titane', 'graphite'],
    axolute: [
      'white', 'alu', 'anthracite'],
    living: [
      'blanc', 'tech', 'anthracite'],
    art: [
      'art-memoire-acier-brosse',
      'art-memoire-or-brosse',
      'art-memoire-cristal',
      'art-epure-acier-brosse',
      'art-epure-acier-satin',
      'art-epure-acier-miroir',
      'art-epure-or-miroir',
      'art-epure-noir-mat',
      'art-epure-bronze',
      'art-epure-blanc-satin',
    ],
    mosaic: [
      'blanc', 'alu', 'noir-mat'],
  };
  MYS.CONFIG.URLS.DB_produits.url = '/action/PRODUITS/get.php?contour=pro';

  if (MYS.CONFIG.displayElectricienButton === undefined) {
    MYS.CONFIG.displayElectricienButton = true; // gsb
  }
  if (!MYS.CONFIG.pdfTheme) {
    MYS.CONFIG.pdfTheme = 'pro';
  }
  if (!MYS.CONFIG.contour) {
    MYS.CONFIG.contour = 'pro';
  }
  if (!MYS.CONFIG.pdfUrlPath) {
    MYS.CONFIG.pdfUrlPath = '/action/PDF/distributor/selection/';
  }
  if (!MYS.CONFIG.cart) {
    MYS.CONFIG.cart = {};
  }
  if (!MYS.CONFIG.cart.additionnalLinks) {
    MYS.CONFIG.cart.additionnalLinks = [
      {
        title: 'Trouver un électricien',
        url: 'https://www.electriciencertifie.legrand.fr/',
      },
    ];
  }
}

function getDatasGammes() {
  const dbs = {};
  for (const gamme in MYS.CONFIG.doigts) {
    if (isObject(MYS.CONFIG.DATABASE[gamme]) && !isEmpty(MYS.CONFIG.DATABASE[gamme])) {
      for (const db in MYS.CONFIG.DATABASE[gamme]) {
        if (typeof dbs[db] === 'undefined') {
          dbs[db] = [];
        }
        dbs[db] = dbs[db].concat(dbs[db], MYS.CONFIG.DATABASE[gamme][db]);
      }
    } else {
      // eslint-disable-next-line
      console.error('error.setup.db');
    }
  }
  for (const db2 in dbs) {
    dbs[db2] = uniq(dbs[db2]);
    for (let i = 0; i < dbs[db2].length; i++) {
      MYS.CONFIG.URLS[`DB_${db2}_${i}`] = {
        url: dbs[db2][i],
      };
    }
  }
}

MYS.getAssetsManifest = function () {
  const manifest = [];
  const EXTERNAL_URL = new RegExp('http://');
  const JSON = new RegExp('.json');
  const HTML = new RegExp('.html');
  const TXT = new RegExp('.txt');

  each(MYS.CONFIG.URLS, (asset, key) => {
    const item = {};
    let type = 'TXT';

    item.id = key;
    item.src = asset.url;
    const query = [];

    if (!EXTERNAL_URL.test(asset.url)) {
      item.src = PATH() + item.src;
    }

    if (asset.cache === false) {
      query.push(`nocache=${timestamp()}`);
    }
    if (typeof window.lastBuild !== 'undefined') {
      query.push(`lastBuild=${window.lastBuild}`);
    }

    if (asset.type === undefined) {
      if (JSON.test(asset.url)) {
        type = 'JSON';
      } else if (TXT.test(asset.url) || HTML.test(asset.url)) {
        type = 'TXT';
      }
    } else {
      type = asset.type;
    }
    if (query.length) {
      if (item.src.indexOf('?') === -1) {
        item.src += '?';
      } else {
        item.src += '&';
      }
      item.src += query.join('&');
    }

    item.type = window.createjs.Types[type];
    manifest.push(item);
  });
  return manifest;
};
MYS.loadingComplete = () => {};
MYS.loadingProgress = (datas) => {};
MYS.loadingError = (error) => {
  if (error.title === 'FILE_LOAD_ERROR') {
    // eslint-disable-next-line
    console.error('FILE_LOAD_ERROR', get(error, 'data.src'));
  } else {
    // eslint-disable-next-line
    console.error(error);
  }
};
MYS.load = () => {
  getDatasGammes();
  const manifest = MYS.getAssetsManifest();
  // Dans le cas où on utilise un FILTER custom, on met le type en TEXT pour éviter la remontée d'erreur
  for (const db in MYS.CONFIG.DATAS_FILTER) {
    for (const item of manifest) {
      if (item.id === db && !isEmpty(MYS.CONFIG.DATAS_FILTER[db])) {
        item.type = 'text';
      }
    }
  }
  MYS.LOADER.loadManifest(manifest);
};
MYS.configuration = () => {
  const doigts = getDoigtsFromParams();
  if (doigts) {
    MYS.CONFIG.doigts = doigts;
  }
};
MYS.fixDoigtConfiguration = () => {
  for (const gammeName in MYS.CONFIG.doigts) {
    const gamme = MYS.GAMMES.get(gammeName);
    // si la gamme n'existe pas
    if (!gamme) {
      delete MYS.CONFIG.doigts[gamme];
      continue;
    }
    MYS.CONFIG.doigts[gammeName] = gamme.getDoigts(MYS.CONFIG.doigts[gammeName]);
    if (MYS.CONFIG.doigts[gammeName].length === 0) {
      delete MYS.CONFIG.doigts[gamme];
      continue;
    }
  }

  if (Object.keys(MYS.CONFIG.doigts).length <= 1) {
    // S'il y a qu'une gamme .. pas de listing de gamme
    MYS.CONFIG.layout.canShowGammePage = false;
  }
  // s'il n'y a pas de gammes
};
MYS.LOADER.on('complete', onComplete);
MYS.LOADER.on('progress', (datas) => MYS.loadingProgress(datas));
MYS.LOADER.on('error', (datas) => MYS.loadingError(datas));

MYS.findInterFromSku = (sku) => {
  const interrupteurConfiguration = {};
  const inter = new MYS.BB.MODELS.Interrupteur();
  // est ce que l'on a la ref ?
  const productBySku = MYS.PRODUITS.findWhere({
    id_reseller: sku,
  });
  const productById = MYS.PRODUITS.findWhere({
    id: sku,
  });
  if (!productBySku && !productById) {
    // eslint-disable-next-line
    console.warn(`le produit ${sku} n'est pas disponible dans ce contour d'offre`);
    return;
  }

  let ref = null;
  if (productBySku) {
    ref = productBySku.id;
  }
  if (productById) {
    ref = productById.id;
  }

  // Il faut trouver quel type de produit il sagit :
  // un merge de d'autres produit ?
  const merge = find(MYS.RULES.get('alternatives-gsb').get('actions'), (action) => includes(action.to, ref));
  const refs = merge ? merge.from : [ref];
  for (const currentRef of refs) {
    // Une Plaque ?
    const plaques = MYS.PLAQUES.filter((plaque) => includes(plaque.get('refs'), currentRef));
    const plaquesSorted = sortBy(plaques, (plaque) => (get(plaque.getFormat(), 'attributes.horizontal', false) === true ? 0 : 1));
    const plaque = plaquesSorted[0];
    if (plaque) {
      interrupteurConfiguration.finition = plaque.get('finition#id');
      interrupteurConfiguration.format = plaque.get('format#id');
      continue;
    }
    // Une Fonction (attention à la couleur de doigt ! )
    let doigtColor = null;
    const fonction = MYS.FONCTIONS.find((fonction) => {
      const doigts = fonction.get('doigts');
      const found = find(doigts, (doigt) => includes(doigt.refs, currentRef));
      if (found) {
        doigtColor = found.doigt;
      }
      return Boolean(found);
    });
    if (fonction && doigtColor) {
      interrupteurConfiguration.doigt = doigtColor;
      interrupteurConfiguration.fonctions = [{
        poste: 0,
        mod: 0,
        id: fonction.get('id'),
      }];
      continue;
    }
  }
  if (isEmpty(interrupteurConfiguration)) {
    // eslint-disable-next-line
    console.warn(`le produit ${ref} n'est pas une plaque ou une fonction, ou un produit complet`);
    return;
  }
  // Seule la fonction est en place, il est nécessaire de mettre une plaque .. la moins chère
  if (typeof interrupteurConfiguration.finition === 'undefined'
    || (typeof interrupteurConfiguration.format === 'undefined' && typeof interrupteurConfiguration.fonctions !== 'undefined')
  ) {
    const fonction = MYS.FONCTIONS.get(interrupteurConfiguration.fonctions[0].id);
    const formatsPossibles = MYS.FORMATS.findAllByModuleAndSize(fonction.get('module#id'), fonction.get('q'));
    const plaqueIds = MYS.FINITIONS.findAllCheapestPlaquesByFinitionByFormat(formatsPossibles);
    const plaques = map(plaqueIds, (plaqueId) => MYS.PLAQUES.get(plaqueId));
    const plaquesSorted = sortBy(plaques, (model) => model.getPrix());
    const plaque = plaquesSorted[0];
    if (plaque) {
      interrupteurConfiguration.finition = plaque.get('finition#id');
      interrupteurConfiguration.format = plaque.get('format#id');
    }
  }
  const fonctionToSet = get(interrupteurConfiguration, ['fonctions', 0]);
  delete interrupteurConfiguration.fonctions;
  inter.set(interrupteurConfiguration);
  inter.set('fonctions', fonctionToSet);
  return inter.isValid() ? inter : undefined;
};

/**
 * Load inter from url params. Not used and not tested at this time
 */
MYS.findInterFromUrlParams = (skuPlaque = null, fonctions = null, orientation = null) => {
  const interrupteurConfiguration = {};
  interrupteurConfiguration.fonctions = [];
  const inter = new MYS.BB.MODELS.Interrupteur();
  let plaque = null;

  if (skuPlaque) {
    const productPlaqueBySku = MYS.PRODUITS.findWhere({
      id_reseller: skuPlaque,
    });
    const productPlaqueById = MYS.PRODUITS.findWhere({
      id: skuPlaque,
    });
    if (!productPlaqueBySku && !productPlaqueById) {
      // eslint-disable-next-line
      console.warn(`la plaque ${sku} n'est pas disponible dans ce contour d'offre`);
      return;
    }

    let refPlaque = null;
    if (productPlaqueBySku) {
      refPlaque = productPlaqueBySku.id;
    }
    if (productPlaqueById) {
      refPlaque = productPlaqueById.id;
    }
    const plaques = MYS.PLAQUES.filter((plaque) => includes(plaque.get('refs'), refPlaque));
    const plaquesSorted = sortBy(plaques, (plaque) => (get(plaque.getFormat(), 'attributes.horizontal', false) === true ? 0 : 1));
    plaque = plaquesSorted[0];
  }

  if (plaque) {
    interrupteurConfiguration.finition = plaque.get('finition#id');
    interrupteurConfiguration.format = plaque.get('format#id');
  }

  // est ce que l'on a la ref ?
  if (fonctions) {
    const foundAllFonctionsBySku = every(fonctions, (fonction) => MYS.PRODUITS.findWhere({ id_reseller: fonction.sku }));
    const foundAllFonctionsById = every(fonctions, (fonction) => MYS.PRODUITS.findWhere({ id: fonction.sku }));
    if (!foundAllFonctionsBySku && !foundAllFonctionsById) {
      // eslint-disable-next-line
      console.warn('les fonctions ne sont pas disponibles dans ce contour d\'offre');
      return;
    }

    const attributeForSearch = foundAllFonctionsBySku ? 'id_reseller' : 'id';

    const fonctionsObj = map(fonctions, (fonction, key) => (
      {
        id: MYS.PRODUITS.findWhere({
          [attributeForSearch]: fonction.sku,
        }).id,
        sku: fonction.sku,
        // /!\ cast to int otherwise Inter won't be interactive
        poste: parseInt(fonction.poste, 10) || parseInt(key, 10) || 0,
        mod: parseInt(fonction.mod, 10) || 0,
      }
    ));

    fonctionsObj.forEach((currentFonction) => {
      let doigtColor = null;
      const fonction = MYS.FONCTIONS.find((fonction) => {
        const doigts = fonction.get('doigts');
        const found = find(doigts, (doigt) => includes(doigt.refs, currentFonction.id));
        if (found) {
          doigtColor = found.doigt;
        }
        return Boolean(found);
      });

      if (fonction && doigtColor) {
        let setFontion = true;
        if (plaque) {
          const format = MYS.FORMATS.get(plaque.get('format#id'));
          const allAreas = format.getRectangles(fonction.get('module#id'), fonction.get('q'));
          if (!allAreas.length) {
            setFontion = false;
            console.warn(`La fonction ${currentFonction.sku} ne correspond pas au format de la plaque`);
          }
        }

        if (setFontion) {
          interrupteurConfiguration.doigt = doigtColor;
          interrupteurConfiguration.fonctions.push({
            poste: currentFonction.poste,
            mod: currentFonction.mod,
            id: fonction.get('id'),
          });
        }
      }
    });
  }

  if (isEmpty(interrupteurConfiguration)) {
    // eslint-disable-next-line
    console.warn('le produit ne peut pas être reconstituer');
    return;
  }
  // Seule la fonction est en place, il est nécessaire de mettre une plaque .. la moins chère
  if (typeof interrupteurConfiguration.finition === 'undefined'
    || (typeof interrupteurConfiguration.format === 'undefined'
    && typeof interrupteurConfiguration.fonctions !== 'undefined')
  ) {
    const fonction = MYS.FONCTIONS.get(interrupteurConfiguration.fonctions[0].id);
    const formatsPossibles = MYS.FORMATS.findAllByModuleAndSize(fonction.get('module#id'), fonction.get('q'));
    const plaqueIds = MYS.FINITIONS.findAllCheapestPlaquesByFinitionByFormat(formatsPossibles);
    const plaques = map(plaqueIds, (plaqueId) => MYS.PLAQUES.get(plaqueId));
    const plaquesSorted = sortBy(plaques, (model) => model.getPrix());
    const plaque = plaquesSorted[0];
    if (plaque) {
      interrupteurConfiguration.finition = plaque.get('finition#id');
      interrupteurConfiguration.format = plaque.get('format#id');
    }
  }

  const fonctionsToSet = get(interrupteurConfiguration, 'fonctions');
  delete interrupteurConfiguration.fonctions;
  inter.set(interrupteurConfiguration);
  inter.set('fonctions', fonctionsToSet);

  if (orientation === VERTICALE_ORIENTATION) {
    inter.set('rotation', true);
  }

  return inter.isValid() ? inter : undefined;
};

function onComplete() {
  for (const db in MYS.CONFIG.DATAS_FILTER) {
    const filter = MYS.CONFIG.DATAS_FILTER[db];
    if (MYS.LOADER._loadedResults[db] && !isEmpty(filter)) {
      const rawDatas = MYS.LOADER._loadedResults[db];
      if (filter === 'atob+pako') {
        if (!window.pako) {
          // eslint-disable-next-line
          console.error('LIB pako.js manquante');
        } else {
          MYS.LOADER._loadedResults.DB_produits = JSON.parse(window.pako.inflate(atob(rawDatas), {
            to: 'string',
          }));
        }
      }
    }
  }
  each(MYS.LOADER.getItems(), (file) => {
    const itemId = file.item.id;
    const LIST = 'LIST_';
    const DB = 'DB_';
    const IMG = 'IMG_';

    let keyToStoreInMys;
    let key;
    let result;
    // Les listes (objets Array)
    if (itemId.indexOf(LIST) === 0) {
      key = itemId.substring(LIST.length);
      keyToStoreInMys = key.toLowerCase();

      MYS.CONFIG.MENUS[keyToStoreInMys] = file.result;
      // Les Collections Typées
    } else if (itemId.indexOf(DB) === 0) {
      key = itemId.substring(DB.length).split('_')[0];
      keyToStoreInMys = key.toUpperCase();
      const collectionKey = capitaliseFirstLetter(key.toLowerCase());
      result = file.result;
      if (typeof MYS[keyToStoreInMys] === 'undefined') {
        MYS[keyToStoreInMys] = new MYS.BB.COLLECTIONS[collectionKey]();
      }
      if (MYS.BRIDGES[key.toLowerCase()] !== undefined) {
        result = MYS.BRIDGES[key.toLowerCase()](result);
      }
      MYS[keyToStoreInMys].add(result);
      // Les Collections Images
    } else if (itemId.indexOf(IMG) === 0) {
      key = itemId.substring(IMG.length);
      keyToStoreInMys = key.toUpperCase();
      result = file.result;

      MYS[keyToStoreInMys] = new MYS.BB.COLLECTIONS.Imgs();

      if (MYS.BRIDGES[key.toLowerCase()] !== undefined) {
        result = MYS.BRIDGES[key.toLowerCase()](result);
      }
      MYS[keyToStoreInMys].add(result);
    }
  });

  MYS.SMALL.infos = {
    marges: 0,
    mm2pixels: 2.46 * (30 / 100),
  };
  MYS.BIG.infos = {
    marges: 0,
    mm2pixels: 2.46 * (50 / 100),
  };
  MYS.THUMB.infos = {
    marges: 0,
    // mm2pixels: 0.445
    mm2pixels: 2.46 * (17.5 / 100),
  };
  // big@2x : 2.46,
  // big : 2.46 / 2,    // small@2x : 2.46 / 5 * 3,
  // small : 2.46 / 10 * 3,    // thumbs@2x : 2.46 / 40 * 7,
  // thumbs : 2.46 / 80 * 7,

  // SUPPRESSION DES OBJETS aux liaisons manquantes
  MYS.FONCTIONS.removeWrongModels();
  MYS.PLAQUES.removeWrongModels();
  MYS.FORMATS.removeWrongModels();
  MYS.FINITIONS.removeWrongModels();
  MYS.SUPPORTS.removeWrongModels();
  MYS.fixDoigtConfiguration();
  MYS.loadingComplete();
}
export default MYS;
