<template lang="pug">
  .interrupteur-details
    template(v-if="hasRefs")
      a.cursor-pointer(@click="clicOnDetails")
        | {{ message }}
      transition(name="in-out-slide-fade" mode="out-in")
        ul.refs(v-if="active")
          li.ref(v-for="(ref, refIndex) in refsWithrelativeDatas" :key="`${ref}_${refIndex}`")
            | .
            span.qty {{ ref.qty }}x
            span.name {{ ref.name }}
            br
            span.id_reseller {{ refPrefix }} {{ ref.id_reseller || ref.id }}
            tarif(v-if="canShowPrice" :price="ref.prix")
</template>

<script>

import {
  isEmpty, map, pick, get, find,
} from 'lodash';
import { L, noAccent } from '@/libs/core/utils';
import tarif from '@/components/Interrupteur/tarif.vue';

export default {
  name: 'interrupteur-details',
  components: {
    tarif,
  },
  props: {
    refs: {
      type: Array,
      default() {
        return [];
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    canShowPrice() {
      return get(this.$getNativeMYS(), 'CONFIG.layout.showPrice', true);
    },
    hasRefs() {
      return !isEmpty(this.refs);
    },
    message() {
      return this.active ? 'Masquer les détails' : 'Afficher les détails';
    },
    refPrefix() {
      return L('réf.');
    },
    refsWithrelativeDatas() {
      const refArray = [];
      this.refs.forEach((ref) => {
        const product = this.$getNativeMYS().PRODUITS.get(ref);
        const attributes = pick(product.attributes, ['name', 'id_reseller', 'id', 'prix']);
        const foundProduct = find(refArray, { id: attributes.id });
        if (foundProduct) {
          foundProduct.qty++;
          foundProduct.prix += parseFloat(attributes.prix);
        } else {
          refArray.push({
            qty: 1,
            ...attributes,
          });
        }
      });
      return refArray;
    },
  },
  methods: {
    clicOnDetails(event) {
      this.pushGTM();
      this.$emit('update:active', !this.active);
    },
    pushGTM() {
      this.$gtm.push({
        event: 'eventGA',
        eventCategory: this.$getNativeMYS().CONFIG.contour,
        eventAction: noAccent(this.message),
      });
    },
  },
};
</script>

<style lang="scss">
.interrupteur-details {
  & > a {
    color: #333;
    text-decoration: underline;
    font-size: 0.8em;
    padding: 20px 20px 10px 20px;
    display: block;
  }
  .ref {
    display: block;
    margin: 1em auto;
    max-width: 60%;
    min-width: 250px;
    font-size: .8em;
    line-height: 1.4em;
    & > * {
      display: inline;
      margin: 0 .2em;
    }
    .tarif {
      font-weight: 500;
    }
  }
}
.darkModeEnabled {
  .interrupteur-details {
    & > a {
      color: white;
    }
  }
}
</style>
