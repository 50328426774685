var db_dir = "/static/2/database/db/";

MYS = {
    LOADER: new createjs.LoadQueue(),
    VIEWS: {},
    EVENT: {},
    KEYS: {},
    CONFIG: {
        money: '€',
        tax: 'ttc',
        DATABASE: {
            "altege": {
                compositions: [db_dir + "compositions/altege.json"],
                finitions: [db_dir + "finitions/altege_gsb.json"],
                fonctions: [db_dir + "fonctions/altege_gsb.json"],
                formats: [db_dir + "formats/altege.json"],
                modules: [db_dir + "modules/altege.json"],
                plaques: [db_dir + "plaques/altege_gsb.json"],
                boites: [],
                supports: [db_dir + 'db_supports.min.json'],
                gammes: [db_dir + 'gammes/altege.json']
            },
            "art": {
                compositions: [db_dir + "compositions/art.json"],
                finitions: [db_dir + "finitions/art_pro.json"],
                fonctions: [db_dir + "fonctions/art_pro.json"],
                formats: [db_dir + "formats/art.json"],
                modules: [db_dir + "modules/art.json"],
                plaques: [db_dir + "plaques/art_pro.json"],
                boites: [],
                supports: [db_dir + 'db_supports.min.json'],
                gammes: [db_dir + 'gammes/art.json']
            },
            "axolute": {
                compositions: [db_dir + "compositions/axolute.json"],
                finitions: [db_dir + "finitions/axolute_pro.json"],
                fonctions: [db_dir + "fonctions/axolute_pro.json"],
                formats: [db_dir + "formats/axolute.json"],
                modules: [db_dir + "modules/axolute.json"],
                plaques: [db_dir + "plaques/axolute_pro.json"],
                boites: [],
                supports: [db_dir + 'db_supports.min.json'],
                gammes: [db_dir + 'gammes/axolute.json']
            },
            "celiane": {
                compositions: [db_dir + "compositions/celiane.json"],
                finitions: [db_dir + "finitions/celiane_pro.json"],
                fonctions: [db_dir + "fonctions/celiane_pro.json"],
                formats: [db_dir + "formats/celiane.json"],
                modules: [db_dir + "modules/celiane.json"],
                plaques: [db_dir + "plaques/celiane_pro.json"],
                boites: [
                    db_dir + "boites/batibox-energy.json",
                    db_dir + "boites/cloison-seche.json",
                    db_dir + "boites/maconnerie.json",
                    db_dir + "boites/multimateriaux.json",
                    db_dir + "boites/pour-moulure.json",
                ],
                supports: [db_dir + 'db_supports.min.json'],
                gammes: [db_dir + 'gammes/celiane.json']
            },
            "dooxie": {
                compositions: [db_dir + "compositions/dooxie.json"],
                finitions: [db_dir + "finitions/dooxie_pro.json"],
                fonctions: [db_dir + "fonctions/dooxie_pro.json"],
                formats: [db_dir + "formats/dooxie.json"],
                modules: [db_dir + "modules/dooxie.json"],
                plaques: [db_dir + "plaques/dooxie_pro.json"],
                boites: [],
                supports: [],
                gammes: [db_dir + 'gammes/dooxie.json']
            },
            "mosaic": {
                compositions: [db_dir + "compositions/mosaic.json"],
                finitions: [db_dir + "finitions/mosaic_pro.json"],
                fonctions: [db_dir + "fonctions/mosaic_pro.json"],
                formats: [db_dir + "formats/mosaic.json"],
                modules: [db_dir + "modules/mosaic.json"],
                plaques: [db_dir + "plaques/mosaic_pro.json"],
                boites: [],
                supports: [db_dir + 'db_supports.min.json'],
                gammes: [db_dir + 'gammes/mosaic.json']
            },
            // "espace-evolution": {
            //     compositions: [db_dir + "compositions/espace-evolution.json"],
            //     finitions: [db_dir + "finitions/espace-evolution.json"],
            //     fonctions: [db_dir + "fonctions/espace-evolution.json"],
            //     formats: [db_dir + "formats/espace-evolution.json"],
            //     modules: [db_dir + "modules/espace-evolution.json"],
            //     plaques: [db_dir + "plaques/espace-evolution.json"],
            //     boites: [],
            //     supports: []
            // },
            "living": {
                compositions: [db_dir + "compositions/living.json"],
                finitions: [db_dir + "finitions/living_pro.json"],
                fonctions: [db_dir + "fonctions/living_pro.json"],
                formats: [db_dir + "formats/living.json"],
                modules: [db_dir + "modules/living.json"],
                plaques: [
                    db_dir + "plaques/living_pro.json",
                ],
                boites: [],
                supports: [db_dir + 'db_supports.min.json'],
                gammes: [db_dir + 'gammes/living.json']
            },
            // "niloe": {
            //     compositions: [db_dir + "compositions/niloe.json"],
            //     finitions: [db_dir + "finitions/niloe.json"],
            //     fonctions: [db_dir + "fonctions/niloe.json"],
            //     formats: [db_dir + "formats/niloe.json"],
            //     modules: [db_dir + "modules/niloe.json"],
            //     plaques: [db_dir + "plaques/niloe.json"],
            //     boites: [],
            //     supports: []
            // },

        },
        GAMMES: undefined,
        retina: (window.devicePixelRatio > 1),
        URLS: {
            // DATABASE
            "DB_usages": {
                url: db_dir + "db_usages.min.json"
            },
            "DB_produits": {
                url: '/action/PRODUITS/get.php?contour=pro', //db_dir + "db_produits.min.json",
                cache: false,
                type: "JSON"
            },
            "DB_rules": {
                url: db_dir + "db_rules.min.json"
            },
            // "DB_gammes": {
            //     url: db_dir + "db_gammes.min.json"
            // },
            // IMAGES
            "IMG_big": {
                url: "/static/2/imgs/big.json"
            },
            "IMG_small": {
                url: "/static/2/imgs/small.json"
            },
            "IMG_thumb": {
                url: "/static/2/imgs/thumb.json"
            },
            // LISTS
            "LIST_couleurs": {
                url: "/static/2/database/list/list_couleurs.min.json"
            },
            "LIST_fonctionsCategories": {
                url: "/static/2/database/list/list_fonctionsCategories_pro.min.json"
            },
            "LIST_formats": {
                url: "/static/2/database/list/list_formats.min.json"
            },
            "LIST_matieres": {
                url: "/static/2/database/list/list_matieres.min.json"
            },
            // TEMPLATE
            "templates": {
                url: "/static/2/js/templates/app.html"
            },
        },
        MAPPAGE: {
            output: {
                REF_LEG: 'id',
                REF_MAT: 'id_reseller',
                qty: '$quantity$'
            }
        },
        DATAS_FILTER: {},
        MENUS: {},
        ALLOWMERGEDPRODUCT: false,
        SYNCFINITIONSANDFONCTIONS: false
    },
    CALLBACK: {},
    BRIDGES: {
        produits: function(products) {
            var out = []
            if (MYS.CONFIG && MYS.CONFIG.MAPPAGE && MYS.CONFIG.MAPPAGE.DB_produits) {
                var mappage = MYS.CONFIG.MAPPAGE.DB_produits;
                for (var y = products.length - 1; y >= 0; y--) {
                    var products_res = products[y]
                    var obj = {};
                    _.each(mappage, function(value, key) {
                        obj[key] = products_res[value];
                    })
                    out.push(obj);
                };
            }

            if (MYS.CONFIG && MYS.CONFIG.add_tva) {
                for (var i = 0; i < out.length; i++) {
                    if (out[i].prix !== undefined) {
                        out[i].prix = out[i].prix * parseFloat(MYS.CONFIG.add_tva);
                    }
                }
            }
            return out;
        }
    },
    IMGS: {},
    BB: {
        MODELS: {},
        COLLECTIONS: {},
        VIEWS: {},
    },
};
