/**
 * Représente une Collection de {@link MYS.BB.MODELS.Module}
 * @see  {@link http://backbonejs.org/#Collection Backbone.Collection}
 * @constructor
 * @param {Module[]} Modules - Ensemble de Modules
 * @augments Backbone.Collection
 */

MYS.BB.COLLECTIONS.Modules = Backbone.Collection.extend(
    /** @lends MYS.BB.COLLECTIONS.Modules.prototype */
    {
        model: MYS.BB.MODELS.Module
    }
);
