<template lang="pug">
  .camera(v-if="isMediaDevicesEnabled")
    cameraBtnClose
    cameraBtnActivation
    cameraVideo
    cameraPhoto
  .cta-camera(v-else :style="ctaCameraStyles")
    transition(
      v-on:before-enter="ctaIsDisplaying"
      v-on:after-leave="ctaIsHidding"
      name="in-out-slide-fade"
      mode="out-in")
      .cta(v-if="isCtaVisible" @click="closeCta")
        .cta-content
          img(:src="imgUrl")
          div
            span.important Visualisez chez vous
            span sur votre smartphone
          i.close.mobile-icon-close
</template>
<script>
import cameraBtnClose from '@/components/Camera/cameraBtnClose.vue';
import cameraBtnActivation from '@/components/Camera/cameraBtnActivation.vue';
import cameraVideo from '@/components/Camera/cameraVideo.vue';
import cameraPhoto from '@/components/Camera/cameraPhoto.vue';

export default {
  name: 'camera',
  data() {
    return {
      debug: true,
      cameraEnabled: false,
      isCtaDisplay: true,
    };
  },
  components: {
    cameraBtnClose,
    cameraBtnActivation,
    cameraVideo,
    cameraPhoto,
  },
  methods: {
    defineCameraEnabled() {
      this.cameraEnabled = 'mediaDevices' in navigator && MYS.CONFIG.cameraEnabled;
      this.$hub.isCtaVisible = MYS.CONFIG.cameraEnabled;
    },
    closeCta() {
      this.$hub.isCtaVisible = false;
    },
    ctaIsDisplaying() {
      this.isCtaDisplay = true;
    },
    ctaIsHidding() {
      this.isCtaDisplay = false;
    },
  },
  computed: {
    isMediaDevicesEnabled() {
      return (this.cameraEnabled && !this.$viewport.useLateralConfigurationMenu);
    },
    imgUrl() {
      return '/static/mobile/imgs/cta-camera-img.png';
    },
    ctaCameraStyles() {
      return {
        'margin-bottom': this.isCtaDisplay ? '100px' : '0',
      };
    },
    isCtaVisible() {
      return this.$hub.isCtaVisible;
    },
  },
  watch: {
    cameraEnabled: {
      handler(value) {
        if (value && 'localStorage' in window) {
          localStorage.setItem('cameraEnabled', true);
        }
      },
    },
    '$viewport.useLateralConfigurationMenu': {
      handler(value) {
        if (value) {
          this.$camera.active = false;
        }
      },
    },
  },
  created() {
    this.defineCameraEnabled();
    this.isCtaDisplay = this.$hub.isCtaVisible || false;
  },
};
</script>
<style lang="scss">
  .in-out-slide-fade-enter-active, .in-out-slide-fade-leave-active {
    transition: all 0.2s;
  }
  .in-out-slide-fade-enter, .in-out-slide-fade-leave-active {
    opacity: 0;
  }
  .in-out-slide-fade-enter {
    transform: translateY(31px);
  }
  .in-out-slide-fade-leave-active {
    transform: translateY(31px);
  }
  .camera-controls{
    padding: 10px;
    position: fixed;
    left: 0px;
    bottom: 55px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    z-index: 10;
    transition: all 250ms ease 0s;
    margin-bottom: -200px;
    .button-small{
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      i{
        font-size: 20px;
      }
    }
  }
  .camera-process-video .camera-video-wrapper,
  .camera-process-photo .camera-photo-wrapper{
    .camera-controls{
      z-index: 11;
    }
  }
  .button-main{
    width: 54px;
    height: 54px;
    overflow: hidden;
    background: #fff;
    border-radius: 50%;
    border: 0;
    position: relative;
    overflow: visible;
    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(1.15);
      border: 4px solid var(--primary-color);
      transition: transform 250ms ease;
    }
    &:hover{
      &:before{
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }
  .cta-camera {
    position: relative;

    .cta {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      transition: all 250ms ease;

      .cta-content {
        display: flex;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.1) 0 10px 10px;
        position: relative;

        img {
          padding: 18px 3px 0 9px;
        }

        div {
          padding: 22px 18px 18px 4px;
          font-size: 14px;

          span {
            display: block;

            &.important {
              color: var(--primary-color);
              font-weight: bold;
            }
          }
        }

        .close {
          position: absolute;
          font-size: 11px;
          top: 7px;
          right: 7px;
        }
      }
    }
  }
</style>
