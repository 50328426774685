<template lang="pug">
  .foundOnePlaqueForThisFunction
    | {{ text }}
    .text-align-center.medium-margin-top.medium-margin-bottom
      .display-inline-block.position-relative(v-html='visuel')
</template>

<script>

export default {
  name: 'found-one-plaque-for-this-function',
  props: {
    text: String,
    inter: Object,
  },
  data() {
    return {
    };
  },
  watch: {
  },
  computed: {
    visuel() {
      const inter = this.$create('Interrupteur', this.inter);
      return inter.getVisuel();
    },
  },
  methods: {

  },
};
</script>

<style lang="scss">

</style>
