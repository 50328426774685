if (!document.elementsFromPoint) {
  document.elementsFromPoint = elementsFromPoint;
}

function elementsFromPoint(x, y) {
  const parents = [];
  let parent = void 0;
  do {
    if (parent !== document.elementFromPoint(x, y)) {
      parent = document.elementFromPoint(x, y);
      parents.push(parent);
      parent.style.pointerEvents = 'none';
    } else {
      parent = false;
    }
  } while (parent);
  parents.forEach((parent) => {
    parent.style.pointerEvents = 'all';
    return parent.style.pointerEvents;
  });
  return parents;
}
