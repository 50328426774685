export default {
  methods: {
    $download(url, output, totalPrice, turnstileToken) {
      const pdfForm = document.createElement('form');
      pdfForm.action = url;
      pdfForm.method = 'post';
      pdfForm.id = 'PDF';

      let input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'datas';
      input.value = JSON.stringify(output);
      pdfForm.appendChild(input);

      input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'theme';
      input.value = this.$getNativeMYS().CONFIG.pdfTheme;
      pdfForm.appendChild(input);

      input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'turnstileToken';
      input.value = turnstileToken;
      pdfForm.appendChild(input);

      input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'total';
      input.value = totalPrice;
      pdfForm.appendChild(input);

      document.getElementsByClassName('cart')[0].appendChild(pdfForm);
      pdfForm.submit();
      pdfForm.remove();
    },
  },
};
