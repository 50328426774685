/**
 @typedef {Object} Img
 @property {string} id - Nom du fichier image, sans l'extension
 @property {string} h - Hauteur de l'image, en pixel
 @property {string} w - Largeur de l'image, en pixel
 */

/**
 * Représente le Modèle Image.
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 * @constructor
 * @param {Img} image - Données de l'image, permettant sa génération.
 */
MYS.BB.MODELS.Img = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Img.prototype */
    {});
