/**
 @typedef {Object} Rectangle
 @property {number} left - Positionnement de la gauche du rectangle
 @property {number} top -  Positionnement du haut du rectangle
 @property {number} width - Lageur du rectangle
 @property {number} height - Hauteur du rectangmle
 */

/**
 * Représente le Modèle Rectangle.
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 * @constructor
 * @param {Rectangle} rectangle - Données du Rectangle, permettant sa génération.
 * @augments Backbone.Model
 */
MYS.BB.MODELS.Rectangle = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Rectangle.prototype */
    {
        /**
         * Retourne la réponse à la question "Est ce que ces deux rectangles se superposent ?"
         * @param  {Rectangle} rectangle - rectangle à comparer
         * @return {boolean} TRUE si les deux rectangles se superposent à cheval, FALSE dans le cas contraire.
         */
        intersect: function(rectangle) {
            function fixNumber(value) {
                return Math.round(value * 10000) / 10000
            }
            var out = !(fixNumber(rectangle.get("left")) >= fixNumber(this.get("left") + this.get("width")) ||
                fixNumber(rectangle.get("left") + rectangle.get("width")) <= fixNumber(this.get("left")) ||
                (fixNumber(this.get("left")) >= fixNumber(rectangle.get("left")) && fixNumber(this.get("left") + this.get("width")) <= fixNumber(rectangle.get("left") + rectangle.get("width"))) ||
                fixNumber(rectangle.get("top")) >= fixNumber(this.get("top") + this.get("height")) ||
                fixNumber(rectangle.get("top") + rectangle.get("height")) <= fixNumber(this.get("top")));
            return out;

        }
    });
