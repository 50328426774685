<template lang="pug">
  .loader.block-center
    .progress-container.block-center
      .progress(:style="progressStyle")
    .error(v-if="errorLoadingMessage")
      p {{ errorLoadingMessage }}

</template>

<script>
export default {
  name: 'loader',
  components: {
  },
  props: {
    messages: {
      type: Array,
      default() { return []; },
    },
    progress: Number,
  },
  data() {
    return {
    };
  },
  computed: {
    errorLoadingMessage() {
      if (this.messages.length === 1) {
        return '1 fichier n\'a pas pu être chargé';
      } if (this.messages.length > 1) {
        return `${this.messages.length} fichiers n'ont pas pu être chargés`;
      }
      return '';
    },
    progressPercent() {
      return Math.max(Math.min(this.progress * 100, 100), 0);
    },
    progressStyle() {
      return {
        width: `${this.progressPercent}%`,
        opacity: Math.min(this.progress * 2, 1),
      };
    },
  },
};
</script>

<style lang="scss">
$primaryColor: var(--primary-color);
.loader {
  margin-top: 40vh;
  max-width: 280px;
  .error {
    margin-top: 1em;
    color: $primaryColor;
  }
  .progress-container {
    width: 100px;
    position: relative;
    border: 2px solid rgba(0,0,0,.2);
    border-radius: 10px;
    padding: 2px;
  }
  .progress {
    border-radius: 5px;
    height: 10px;
    background-color: $primaryColor;
  }
}
</style>
