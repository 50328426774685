<template lang="pug">
  .selectors(
    :class="selectorsClass"
    )
    .last-selector.pn-product-nav-wrapper.desktop
      nav.pn-product-nav#pn-product-nav
        .pn-product-nav-contents#pn-product-nav-contents
          transition(
            v-on:enter="stopMenuIsAnimating"
            v-on:leave="startMenuIsAnimating"
            v-on:before-enter="stopMenuIsAnimating"
            v-on:after-leave="startMenuIsAnimating"
            v-on:afterEnter="sendSelectorHeight"
            v-on:afterLeave="sendSelectorHeight"
            name="in-out-slide-fade"
            mode="out-in")
            listFinishes(
              v-if='isActiveInLevel1("finishes")'
              :items="plaques"
              size="small"
              @selectItem="finitionClickHandler"
              class='list-plaques'
              )
            listFormats(
              v-if='isActiveInLevel1("formats")'
              :items="formats"
              @selectItem="formatClickHandler"
              class='list-formats'
              )
            listElements(
              v-else-if='isActiveInLevel1("functions")'
              :items="fonctions"
              :size="fonctionsSize"
              @selectItem="fonctionClickHandler"
              class="list-fonctions"
              )

    transition(name="in-out-slide-fade" mode="out-in")
      .second-selector.desktop(v-if='isActiveInLevel1("functions")')
        ul
          li(
            v-for="category in fonctionsLevel2"
            :class='[ { active: isActiveInLevel2(category.id)}, category.id ]'
            @click="selectMenuHandlerLevel2(category.id)"
            )
            | {{ category.name }}
      .second-selector.desktop(v-if='isActiveInLevel1("finishes")')
        ul
          li(
            v-for="category in finishesLevel2"
            :class='[ { active: isActiveInLevel2(category.id)}, category.id ]'
            @click="selectMenuHandlerLevel2(category.id)"
            )
            | {{ category.name }}

    .first-selector.upper(:class="[{ active: isOpened }]" )
        ul
          li.finishes(
            :class='{ active: isActiveInLevel1("finishes") }'
            @click="selectMenuHandlerLevel1('finishes')"
            ) Couleur
          li.formats(
            v-if="canDisplayFormats"
            :class='{ active: isActiveInLevel1("formats") }'
            @click="selectMenuHandlerLevel1('formats')"
            ) Format
          li.functions(
            :class='{ active: isActiveInLevel1("functions") }'
            @click="selectMenuHandlerLevel1('functions')"
            ) Fonction

</template>

<script>
import {
  map, find, get, uniq, flatten, isEmpty, intersection, debounce, reduce, compact, orderBy, forEach, filter, some,
} from 'lodash';
import { GTMProductPrintFinishes, GTMProductPrintFunctions } from '@/components/Selector/gtmSelectorProducts';
import { L } from '@/libs/core/utils';
import listElements from '@/components/Selector/listElements.vue';
import listFinishes from '@/components/Selector/listFinishes.vue';
import listFormats from '@/components/Selector/listFormats.vue';

export default {
  name: 'selector',
  components: {
    listFinishes,
    listElements,
    listFormats,
  },
  props: {
    gamme: String,
    isOpened: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menuIsAnimating: false,
      activatedMenuInLevel1: 'finishes',
      selectedMenuInLevel2: null,
      gtmMenuLevel1Label: {
        finishes: 'Choix couleur',
        formats: 'Choix format',
        functions: 'Choix fonction',
      },
      gtmMenuLevel2Label: {
        finishes: 'Choix couleur dominante',
        functions: 'Choix categorie',
      },
    };
  },
  watch: {
    selectedMenuInLevel2(id) {
      const eventLabel = this.activatedMenuInLevel2;
      if (!eventLabel) return;
      this.$gtm.push({
        event: 'eventGA',
        eventCategory: this.$getNativeMYS().CONFIG.contour,
        eventAction: this.gtmMenuLevel2Label[this.activatedMenuInLevel1],
        eventLabel,
      });
    },
    activatedMenuInLevel1(id) {
      const eventLabel = this.gtmMenuLevel1Label[id];
      if (!eventLabel) return;
      this.$gtm.push({
        event: 'eventGA',
        eventCategory: this.$getNativeMYS().CONFIG.contour,
        eventAction: 'Choix option',
        eventLabel,
      });
    },
    selectedFonction(id) {
      if (id) {
        this.pushGTMMenuInLevel3('fonction', id);
      }
    },
    isOpened: {
      handler(value) {
        if (!value) {
          this.selectMenuHandlerLevel1(null);
        } else if (value === 'finishes') {
          forEach(this.finishesForGTMProductsPrint, (a) => {
            this.sendGTMProductPrint(a);
          });
        } else if (value === 'functions') {
          forEach(this.functionsForGTMProductsPrint, (a) => {
            this.sendGTMProductPrint(a);
          });
        }
      },
    },
    visibleProducts: {
      handler(value, oldValue) {
        if (this.activatedMenuInLevel1 === 'finishes') {
          forEach(this.finishesForGTMProductsPrint, (a) => {
            this.sendGTMProductPrint(a);
          });
        } else if (this.activatedMenuInLevel1 === 'functions') {
          forEach(this.functionsForGTMProductsPrint, (a) => {
            this.sendGTMProductPrint(a);
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    selectorsClass() {
      return {
        hide: this.$camera.active,
      };
    },
    finishesForGTMProductsPrint() {
      return GTMProductPrintFinishes(this.$hub.inter, this.$getNativeMYS(), this.plaques, 15);
    },
    functionsForGTMProductsPrint() {
      return GTMProductPrintFunctions(this.fingerColor, this.$hub.inter.getFinition()?.id, this.$getNativeMYS(), this.fonctions, 15);
    },
    menuIsOpened() {
      return this.activatedMenuInLevel1 === null;
    },
    isMobileSelectorsActive() {
      return this.activatedMenuInLevel1 !== null;
    },
    fingerColor() {
      return this.$hub.inter.get('doigt') || get(this.$getNativeMYS().CONFIG.doigts, [this.gamme, 0]);
    },
    fonctions() {
      const { gamme } = this;
      const finitionToSync = this.$hub.inter.getFinition();
      const fonctions = this.$getNativeMYS().FONCTIONS.filter((fonction) => {
        if (fonction.attributes.gamme !== gamme || get(fonction, 'attributes.categories', []).indexOf(this.activatedMenuInLevel2) <= -1) {
          return false;
        }
        if (this.canSyncFunctionWithFinition) {
          return some(fonction.get('doigts'), (doigt) => doigt.finition === finitionToSync.id || typeof doigt.finition === 'undefined');
        }
        return true;
      });
      const retour = compact(map(fonctions,
        (fonction, key, list) => {
          let doigt = find(fonction.get('doigts'), { doigt: this.fingerColor });
          if (this.canSyncFunctionWithFinition) {
            doigt = find(fonction.get('doigts'), { doigt: this.fingerColor, finition: finitionToSync.id }) || doigt;
          }

          const img = get(doigt, ['imgs', 0]);
          if (!img) return;
          const fonctionToExport = {
            img,
            type: 'fonction',
            id: fonction.id,
            name: fonction.get('nom'),
            positionMarketing: fonction.get('position-marketing'),
          };
          if (this.displayWholeInter) {
            const finition = this.$hub.inter.getFinition();
            const inter = this.createInter(fonction, finition, doigt, gamme);
            if (!inter) return;
            fonctionToExport.visuel = inter.getVisuel(this.fonctionsSize, { withoutFinition: true });
          }
          return fonctionToExport;
        }));
      return orderBy(retour, (fonction) => fonction.positionMarketing);
    },
    fonctionsSize() {
      return this.displayWholeInter ? 'small' : 'big';
    },
    displayWholeInter() {
      const gamme = this.$hub.getCurrentInterGamme();
      return gamme && gamme.get('applyWholeInterFromFunctionMenu') === true;
    },
    canSyncFunctionWithFinition() {
      const gamme = this.$hub.getCurrentInterGamme();
      return gamme && gamme.get('canSyncFunctionWithFinition') === true;
    },
    finishesLevel2() {
      const categories = this.getAllFinishesCategories();
      categories.unshift('toutes');
      const retour = map(categories, (category) => ({
        id: category,
        name: L(category),
      }));
      return retour;
    },
    fonctionsLevel2() {
      const { gamme } = this;
      const categoriesFromConfig = this.$getNativeMYS().CONFIG.MENUS.fonctionscategories;
      let fonctions = this.$getNativeMYS().FONCTIONS.filter({ gamme });
      fonctions = map(fonctions,
        (fonction) => {
          const doigt = find(fonction.get('doigts'), { doigt: this.fingerColor });
          const img = get(doigt, ['imgs', 0]);
          if (!img) {
            return;
          }
          return fonction;
        });

      const categories = uniq(flatten(map(fonctions, 'attributes.categories')));
      if (isEmpty(categoriesFromConfig)) {
        return categories;
      }
      return map(intersection(categoriesFromConfig, categories), (category) => ({
        id: category,
        name: L(category),
      }));
    },
    activatedMenuInLevel2() {
      if (this.activatedMenuInLevel1 === 'functions') {
        if (find(this.fonctionsLevel2, { id: this.selectedMenuInLevel2 })) {
          return this.selectedMenuInLevel2;
        }
        return this.fonctionsLevel2[0].id;
      }
      if (this.activatedMenuInLevel1 === 'finishes') {
        if (find(this.finishesLevel2, { id: this.selectedMenuInLevel2 })) {
          return this.selectedMenuInLevel2;
        }
        return this.finishesLevel2[0].id;
      }
      return false;
    },
    canDisplayFormats() {
      const gamme = this.$hub.getCurrentInterGamme();
      return gamme && !gamme.get('noFormat') === true;
    },
    formats() {
      const { inter } = this.$hub;
      const gamme = this.$getNativeMYS().GAMMES.get(this.gamme);
      if (!inter) return;
      const finition = inter.get('finition');
      return this.$getNativeMYS().CONFIG.MENUS.formats
        .filter((el) => {
          const plaque = this.$getNativeMYS().PLAQUES.findWhere({
            'finition#id': finition,
            'format#id': el,
          });
          return Boolean(plaque);
        })
        .filter((el) => {
          const format = this.$getNativeMYS().FORMATS.get(el);
          if (!format) {
            return false;
          }
          if (format.get('vertical') === true && this.$viewport.displayVerticalFormats) {
            return true;
          }
          return format.get('horizontal') === true;
        })
        .map((el) => {
          const returnObj = {};
          returnObj.id = el;
          if (gamme.get('displayFormatName')) {
            returnObj.name = L(el);
          }
          return returnObj;
        });
    },
    visibleProducts() {
      if (this.activatedMenuInLevel1 === 'functions') {
        return this.fonctions;
      }
      if (this.activatedMenuInLevel1 === 'finishes') {
        return this.plaques;
      }
      return false;
    },
    plaques() {
      const { gamme } = this;
      let finitions = this.$getNativeMYS().FINITIONS;
      if (this.activatedMenuInLevel2 !== 'toutes') {
        finitions = finitions.filter((finition) => finition.attributes.gamme === gamme && finition.attributes.couleurs.indexOf(this.activatedMenuInLevel2) > -1);
      } else {
        const allCategories = this.getAllFinishesCategories();
        finitions = finitions.filter((finition) => finition.attributes.gamme === gamme && intersection(finition.attributes.couleurs, allCategories));
      }
      return map(finitions,
        (finition, key, list) => {
          const plaque = {
            type: 'plaque',
            id: finition.id,
            img: finition.getPrivelegedPlaqueOrSmallest().get('imgs'),
            name: L(finition.id),
          };
          return plaque;
        });
    },
    selectedFonction() {
      return this.$hub.fonctionToAddToInter;
    },
  },
  methods: {
    createInter(fonction, finition, doigt, gamme) {
      const interPossibleConfig = {
        quantity: 1,
        createdAt: Date.now(),
        boite: null,
        fonctions: [
          {
            id: fonction.get('id'),
            poste: 0,
            mod: 0,
          },
        ],
        doigt: doigt.doigt,
        finition: finition.get('id'),
      };

      const formatsPossibles = this.$getNativeMYS().FORMATS.findAllByModuleAndSize(fonction.get('module#id'), fonction.get('q'));
      if (!formatsPossibles.length) {
        return;
      }
      const plaque = this.$getNativeMYS().FINITIONS.get(finition.get('id')).getCheapestPlaqueByFormat(formatsPossibles);
      const format = plaque.getFormat();
      const rotation = format.get('vertical') === true && format.get('horizontal') !== true;
      const interWithGoodFormat = { ...interPossibleConfig, format: plaque.get('format#id'), rotation };
      return this.$create('Interrupteur', interWithGoodFormat);
    },
    sendGTMProductPrint(impressions) {
      if (!impressions.length) {
        return;
      }
      const evt = {
        event: 'productPrint',
        ecommerce: {
          currencyCode: 'EUR',
          impressions,
        },
      };
      this.$gtm.push(evt);
    },
    getAllFinishesCategories() {
      const { gamme } = this;
      const categoriesFromConfig = this.$getNativeMYS().CONFIG.MENUS.couleurs;
      const finitions = this.$getNativeMYS().FINITIONS.filter({ gamme });
      const categories = uniq(flatten(map(finitions, 'attributes.couleurs')));
      if (isEmpty(categoriesFromConfig)) {
        return categories;
      }
      return intersection(categoriesFromConfig, categories);
    },
    preventDefaultOnClick(event) {
      event.preventDefault();
    },
    debouncedOpenFunctionsSelector() {},
    addToCart(event) {
      this.$emit('clickAdd', event);
    },
    stopMenuIsAnimating() {
      // this.sendSelectorHeight()
      this.menuIsAnimating = false;
    },
    startMenuIsAnimating() {
      this.menuIsAnimating = true;
    },
    isActiveInLevel1(name) {
      return this.activatedMenuInLevel1 === name;
    },
    isActiveInLevel2(name) {
      return this.activatedMenuInLevel2 === name;
    },
    selectMenuHandlerLevel1(value) {
      if (value === this.activatedMenuInLevel1) {
        value = null;
      }
      if (value) {
        this.$emit('update:isOpened', true);
      }
      this.activatedMenuInLevel1 = value;
    },
    selectMenuHandlerLevel2(value) {
      this.selectedMenuInLevel2 = value;
    },
    finitionClickHandler(id) {
      this.$emit('finition', id);
    },
    formatClickHandler(id) {
      this.$emit('format', id);
    },
    fonctionClickHandler(id) {
      this.$hub.fonctionToAddToInter = id;
    },
    openFunctionsSelector() {
      if (!this.isActiveInLevel1('functions')) {
        this.selectMenuHandlerLevel1('functions');
      }
    },
    sendSelectorHeight() {
      const h = reduce(['last-selector', 'second-selector', 'first-selector'], (result, value) => {
        result += document.getElementsByClassName(value)[0] ? document.getElementsByClassName(value)[0].offsetHeight : 0;
        return result;
      }, 0);
      this.$emit('changeHeight', h);
    },
    pushGTMMenuInLevel3(type, id) {
      this.$gtm.push({
        event: 'eventGA',
        eventCategory: this.$getNativeMYS().CONFIG.contour,
        eventAction: `Choix ${type}`,
        eventLabel: L(id),
      });
    },
  },
  created() {
    this.debouncedOpenFunctionsSelector = debounce(this.openFunctionsSelector, 300);
    this.$hub.$on('freeAreaClicked', this.debouncedOpenFunctionsSelector);
  },
  destroyed() {
    this.$hub.$off('freeAreaClicked', this.debouncedOpenFunctionsSelector);
  },
  mounted() {
    if (!this.$viewport.useLateralConfigurationMenu) {
      this.activatedMenuInLevel1 = null;
    }
    this.sendSelectorHeight();
    this.$on('finition', (id) => {
      this.pushGTMMenuInLevel3('finition', id);
    });
    this.$on('format', (id) => {
      this.pushGTMMenuInLevel3('format', id);
    });
    window._selector = this;
  },
};
</script>

<style lang="scss">
$btnSize: 20px;
$btnAddSize: 75px;
$breakSmallHeight: 568px;
$navHeight: 64px;

.in-out-slide-fade-enter-active, .in-out-slide-fade-leave-active,
.in-out-fade-enter-active, .in-out-fade-leave-active{
  transition: all .2s;
}
.in-out-slide-fade-enter, .in-out-slide-fade-leave-active,
.in-out-fade-enter, .in-out-fade-leave-active {
  opacity: 0;
}
.in-out-slide-fade-enter {
  transform: translateY(31px);
}
.in-out-slide-fade-leave-active {
  transform: translateY(31px);
}

.selectors {
  & > * {
    pointer-events: all;
  }

  .addToCartSelector {
    width: $btnAddSize;
    height: $btnAddSize;
    position: absolute;
    left: calc(100vw - #{$btnAddSize});
    bottom: calc(100% - #{$btnSize} + 7px); // -44px;
    z-index: 100;
    overflow: hidden;
    box-shadow: rgba(199, 147, 147, 0.2) 0 -3px 10px;
    border: none;
    border-top-left-radius: 90%;
    padding: 0;

    &:before {
      display: block;
      bottom: 0;
      right: 0;
      box-sizing: border-box;
      position: absolute;
      font-size: 28px;
      font-family: 'icomoon' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      content: "\e901";
      padding-right: 14px;
      padding-bottom: 14px;
    }
  }

  .close {
    display: flex;
    width: 60px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: calc(#{$navHeight} + 10px);
    z-index: 100;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    i{
      font-size: 14px;
      padding-top: 2px;
    }
    &:before{
      content: '';
      width: 60px;
      height: 60px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  .close + .addToCartSelector {
    bottom: calc(100% - #{$btnSize} );
  }
  pointer-events: none;
  position: fixed;
  padding-top: 13px;
  bottom: 0;
  width: 100%;
  transition: all 500ms ease;
  z-index: 12;
}

.pn-product-nav-wrapper {
  position: relative;
  box-sizing: border-box;
  .pn-product-nav {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    .pn-product-nav-contents {
      min-width: 100%;
      float: left;
      transition: transform .2s ease-in-out;
      position: relative;
    }
    .pn-product-nav-contents-no-transition {
      transition: none;
    }
  }
}
.last-selector {
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li.active {
      background-color: rgba(0, 0, 0, .07);
    }
  }
}
.first-selector, .second-selector {
  color: var(--primary-color);
  ul {
    display: flex;
    justify-content: space-between;
    gap: 10px 10px;
    li {
      cursor: pointer;
      vertical-align: middle;
      border-style: solid;
      border-width: 1px;
      text-transform: capitalize;
    }
  }
}

.first-selector {
  &.active:before {
    opacity: 0;
  }
  & > ul {
    margin: 0 10px;

    & > li {
      transition: all 250ms ease;
      border-color: var(--primary-color);
      font-weight: 500;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      width: 100%;
      text-transform: uppercase;
      &:hover{
        background-color: rgba(var(--primary-color-values),.1);
      }
      &.active {
        background-color: rgba(var(--primary-color-values), 1);
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.second-selector {
  ul {
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      border-color: rgba(226, 228, 230, 1);
      color: rgba(0, 0, 0, .7);
      word-break: unset;
      white-space: nowrap;
      word-break: unset;
      font-size: 14px;
      line-height: 40px;
      height: 40px;
      transition: all 250ms ease;
      padding: 0 5px;
      &:hover,
      &.active{
        background-color: rgba(226, 228, 230, 1);
      }
    }
  }
}

.last-selector{
  overflow: auto;
  ul{
    display: grid;
    gap: 1rem;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    width: auto;
    li{
      width: 100%;
      display: flex;
      border: 1px solid rgba(226, 228, 230, 1);
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.list-fonctions li.item, .list-plaques li.item, .list-formats li.item {
  padding-bottom: 0px;
  .text span {
    max-height: 33px;
    white-space: initial;
    color: rgba(0, 0, 0, 0.7);
  }
}
.list-formats li.item {
  .text span {
    width: 78px;
  }
}

.list-plaques{
  .thumb{
    transform: scale(1.2);
    transform-origin: top;
  }
}

.is-lateral-configuration-menu {
  .selectors{
    height: calc( 100% - #{$navHeight} );
    left: 0;
    width: 380px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 30px 30px 0;
    background-color: white;
    .first-selector, .second-selector {
      position: static;
      margin-bottom: 15px;
      padding: 0;
    }
    .close{
      display: none;
    }
  }

  .list-fonctions,
  .list-plaques,
  .list-formats{
    max-width: 320px;
    ul{
      flex-wrap: wrap;
      li .text span{
        max-height: none;
      }
    }
  }
  .list-item{
    padding-top: 0;
  }
  .first-selector ul {
    margin: 0;
  }

  .second-selector ul{
    display: flex;
    overflow: hidden;
    li{
      white-space: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 25px;
      height: 25px;
      padding: 0 10px;
    }
  }

  .last-selector{
    ul{
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      margin-right: 10px;
      li{
        width: 100%;
        display: flex;
        border: 1px solid rgba(226, 228, 230, 1);
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.camera-process-video,
.camera-process-photo,
.camera-enabled{
  &:not(.show-interface){
    .selectors{
      bottom: -100%;
    }
  }
}
</style>
