// import { get, map, each, find } from 'lodash'
import _ from '@/libs/lodash/index';
import Backbone from '@/libs/backbone/backbone-light';
import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import App from '@/App.vue';
import MYS from '@/plugins/MYS';
import GTM from '@/plugins/GTM';
import Viewport from '@/plugins/viewport/index';
import Camera from '@/plugins/camera/index';
import nativeMYS from '@/libs/mon-interrupteur-desktop';
import VModal from 'vue-js-modal';
import stylesMixin from '@/mixins/styles';
import backMixin from '@/mixins/back';
import downloaderMixin from '@/mixins/downloader';
import MathMixin from '@/mixins/math';
import '@/filters/pricize';
import '@/registerServiceWorker';
import '@/libs/elementsFromPoint';
import SvgIcon from '@/components/SvgIcon.vue';

document.documentElement.className += (('ontouchstart' in document.documentElement) ? ' touch' : ' no-touch');

window._ = _;
window.Backbone = Backbone;
let limit = 100;
const waitForConfigDatas = setInterval(() => {
  limit--;
  if (nativeMYS.CONFIG && nativeMYS.CONFIG.doigts) {
    installAndLaunchApp('#app-mobile');
    clearInterval(waitForConfigDatas);
  } else if (limit < 0) {
    // eslint-disable-next-line
    console.error('[ CONFIG ] Une erreur est survenue lors du chargement de la page')
    clearInterval(waitForConfigDatas);
  }
}, 100);

function installAndLaunchApp(where) {
  Vue.use(VModal, { dialog: true });
  Vue.use(MYS);
  Vue.use(GTM);
  Vue.use(Viewport);
  Vue.use(Camera);
  Vue.component('svg-icon', SvgIcon);
  Vue.mixin(stylesMixin);
  Vue.mixin(backMixin);
  Vue.mixin(MathMixin);
  Vue.mixin(downloaderMixin);
  Vue.config.productionTip = false;
  Vue.config.strict = false;
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(where);
}
