/**
 @typedef {Object} Produit
 @property {string} id - identifiant /  Référence du Produit
 @property {string} id_reseller - (Facultatif) identifiant /  Référence du Produit du revendeur
 @property {string} name - Nom, description du Produit
 @property {number} prix - Prix du produit
 */

/**
 * Représente le Modèle Produit.
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 * @constructor
 * @param {Produit} produit - Données du Produit, permettant sa génération.
 * @augments Backbone.Model
 */
MYS.BB.MODELS.Produit = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Produit.prototype */
    {
        more: function() {
            var support, plaque, finition, format, color, fonction
            var more = {
                marque: 'undefined',
                gamme: 'undefined',
                category: '',
                postes: '',
                color: ''
            }
            var that = this

            // SUPORT ? 

            if (!_.isUndefined(MYS.SUPPORTS)) {
                support = MYS.SUPPORTS.find(function(el) {
                    return _.contains(el.get('refs'), that.get('id'))
                })
            }
            if (support) {
                more.marque = support.get('marque')
                more.gamme = support.get('gamme')
                more.category = 'support'
                return more
            }
            // PLAQUE ? 
            if (!_.isUndefined(MYS.PLAQUES)) {
                plaque = MYS.PLAQUES.find(function(el) {
                    return _.contains(el.get('refs'), that.get('id'))
                })
            }
            if (plaque) {
                finition = plaque.getFinition()
                format = (plaque.getFormat() || {}).id
            }
            if (finition) {
                more.marque = finition.get('marque').toLowerCase()
                more.gamme = finition.get('gamme').toLowerCase()
                more.category = 'plaque'
                more.color = finition.get('name').toLowerCase()
                more.postes = L("forStats_" + L(format))
                return more
            }
            // FONCTIONS ? 
            color = ''
            if (!_.isUndefined(MYS.FONCTIONS)) {
                fonction = MYS.FONCTIONS.find(function(el) {
                    var f = _.filter(el.get('doigts'), function(subEl) {
                        return _.contains(subEl.refs, that.get('id'))
                    })
                    if (f.length === 1) {
                        color = f[0].doigt
                    }
                    return f.length > 0
                })
            }
            if (fonction) {
                more.marque = fonction.get('marque')
                more.gamme = fonction.get('gamme')
                more.category = 'fonction'
                more.color = color
                return more
            }
            return more
        },

    });
