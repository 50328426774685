<template lang="pug">
  div.interVisuel.bgOutsideScreen(
    @mouseleave="hideDeleteButton"
    :class="interClasses"
    :style="interStyle"
    )
    interrupteur-render-fonction(
      @click="clickOnFonction($event, fonctionIndex)"
      @mouseover="hoverOnFonction($event, fonctionIndex)"
      v-for="(f, fonctionIndex) in visuelDatas.images.fonctions"
      :key="`fonction-${fonctionIndex}`"
      v-bind='f'
      :size="size"
      :index='10 - fonctionIndex'
    )
    button.deleteFonction(
      v-release-outside="hideDeleteButton"
      v-if="showDeleteFonction"
      @click="deleteFonction"
      :style="deleteButtonStyle")
      i(class="mobile-icon-close")
    interrupteur-render-light(
      v-if="isLuminous && isDarkMode"
      :datas="visuelDatas"
      :uid="uid",
      :plaqueIsLuminous="plaqueIsLuminous"
      :sizeFolder="size"
    )
</template>

<script>
import { PATH } from '@/libs/core/utils';
import { isUndefined } from 'lodash';
import { releaseOutside } from '@/directives/release';
import interrupteurRenderFonction from '@/components/Interrupteur/render/fonction/fonction.vue';
import interrupteurRenderLight from '@/components/Interrupteur/render/light.vue';

export default {
  name: 'interrupteur-render',
  components: {
    interrupteurRenderFonction,
    interrupteurRenderLight,
  },
  directives: {
    releaseOutside,
  },
  props: {
    isLuminous: {
      type: Boolean,
      default: false,
    },
    isInteractive: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'SMALL',
    },
  },
  data() {
    return {
      showDeleteFonction: false,
      functionIndexDeleting: {},
    };
  },
  watch: {
  },
  computed: {
    isDarkMode() {
      return this.$hub.darkMode;
    },
    plaqueIsLuminous() {
      return this.$hub.inter.getPlaque().isLuminous();
    },
    uid() {
      return `${this.$hub.inter.get('createdAt')}_${Math.round(Math.random() * 10000)}`;
    },
    interStyle() {
      const styles = {
        'pointer-events': this.isInteractive ? 'all' : 'none',
        width: `${this.visuelDatas.images.plaque.w}px`,
        height: `${this.visuelDatas.images.plaque.h}px`,
      };
      if (!this.displayWholeInter) return Object.assign(styles, { 'background-image': `url(${this.PATH()}/static/2/imgs/${this.size.toLowerCase()}@2x/${this.visuelDatas.images.plaque.id}.png)` });
      return styles;
    },
    interClasses() {
      return [(this.inter.get('rotation') ? 'vertical' : 'horizontal'), this.size, this.gamme];
    },
    gamme() {
      return this.inter.getGamme();
    },
    inter() {
      return this.value;
    },
    visuel() {
      return this.$hub.inter.getVisuel(this.size, { retina: true, withoutFinition: this.displayWholeInter });
    },
    visuelDatas() {
      return this.$hub.inter.getDatas(this.size, { retina: true, canSyncFunctionWithFinition: this.canSyncFunctionWithFinition });
    },
    displayWholeInter() {
      if (!this.inter) return false;
      const gamme = this.$hub.getCurrentInterGamme();
      return gamme && gamme.get('applyWholeInterFromFunctionMenu') === true;
    },
    canSyncFunctionWithFinition() {
      const gamme = this.$hub.getCurrentInterGamme();
      return gamme && gamme.get('canSyncFunctionWithFinition') === true;
    },
    deleteButtonStyle() {
      if (isUndefined(this.functionIndexDeleting)) return {};
      const datas = this.visuelDatas.images.fonctions[this.functionIndexDeleting];
      const decalage = 5;
      if (!datas) return {};
      return {
        top: `${datas.y + datas.h - 28 + decalage}px`,
        left: `${datas.x - decalage}px`,
      };
    },
  },
  methods: {
    hideDeleteButton() {
      this.functionIndexDeleting = undefined;
      this.showDeleteFonction = false;
    },
    deleteFonction() {
      if (!isUndefined(this.functionIndexDeleting)) {
        const datas = this.visuelDatas.positions.fonctions[this.functionIndexDeleting];
        this.inter.deleteFonction(datas.poste, datas.mod);
      }
      this.hideDeleteButton();
    },
    clickOnFonction(event, index) {
      if (!this.isInteractive) return;
      const gamme = this.$hub.getCurrentInterGamme();
      if (gamme && gamme.get('syncFingersWithFinition') === true) return;
      this.showDeleteFonction = true;
      this.functionIndexDeleting = index;
    },
    hoverOnFonction(event, index) {
      if (!this.isInteractive) return;
      const gamme = this.$hub.getCurrentInterGamme();
      if (gamme && gamme.get('syncFingersWithFinition') === true) return;
      this.showDeleteFonction = true;
      this.functionIndexDeleting = index;
    },
    PATH,
  },
};
</script>

<style lang="scss">
.deleteFonction {
    position: absolute;
    z-index: 11;
    font-size: 10px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    text-align: center;
    line-height: 0;
    padding: 7px
}
</style>
