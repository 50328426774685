/**
 @typedef {Object} Support
 @property {string} id - identifiant du Support
 @property {string[]} refs - Liste des références {@link MYS.BB.MODELS.Produit} composants le Support
 @property {string[]} format#id - Liste des id de {@link MYS.BB.MODELS.Format} compatibles avec le Support
 */

/**
 * Représente le Modèle Support.
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 * @constructor
 * @param {Support} Support - Données du Support, permettant sa génération.
 * @augments Backbone.Model
 */
MYS.BB.MODELS.Support = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Support.prototype */
    {
        /**
         * Calcule le prix du Support, et se basant sur les références qui la compose.
         * @return {Number}
         */
        getPrix: function() {
            return MYS.PRODUITS.getTotalByProduits(this.get("refs"));
        },
        /**
         * Vérifie la validité du Support en contrôlant l'existance des références.
         * Si une référence est manquante, alors la fonction retourne FALSE.
         * @return {string|void}  La chaine retournée est un descriptif de l'erreur entrainant l'invalidation
         */
        validate: function() {
            if (!MYS.PRODUITS.has(this.get("refs"))) {
                return "Des références sont manquantes";
            }
        }
    });
