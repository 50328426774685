<template lang="pug">
  .list-selector
    .selected(
      @click="toggleSelector()"
    )
      | {{ selectedLabel }}
      svg-icon.icon(icon="selectbox-arrow")

    transition(name="in-out-fade" mode="out-in")
      .list(v-if='selectorIsOpen')
        .item(
          v-for="item in items"
          :key="item.value"
          :class='[ { active: isActive(item.value)}, item.value ]'
          @click="select(item)"
          )
          | {{ item.label }}

</template>

<script>
import {
  map, find, get, uniq, flatten, isEmpty, intersection, debounce, reduce, compact, orderBy, forEach, filter, some,
} from 'lodash';
import { L } from '@/libs/core/utils';

export default {
  name: 'list-selector',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: 'Selectionner',
    },
  },
  data() {
    return {
      selectorIsOpen: false,
      activeItem: null,
      selectedLabel: this.placeholder,
    };
  },
  methods: {
    isActive(value) {
      return this.activeItem === value;
    },
    select(itemSelected) {
      this.selectedLabel = itemSelected.label;
      this.activeItem = itemSelected.value;
      this.selectorIsOpen = false;
      this.$emit('selectItem', itemSelected.value);
    },
    toggleSelector() {
      this.selectorIsOpen = !this.selectorIsOpen;
    },
  },
};
</script>

<style lang="scss">
.in-out-fade-enter-active, .in-out-fade-leave-active{
  transition: all .2s;
}
.in-out-fade-enter, .in-out-fade-leave-active {
  opacity: 0;
}

.list-selector {
  position: relative;

  .selected {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border:1px solid rgba(226, 228, 230, 1);
    color: rgba(0, 0, 0, .7);
    text-transform: capitalize;
  }

  .list {
    position: absolute;
    top: 100%;
    left: 0;
    border:1px solid rgba(226, 228, 230, 1);
    color: rgba(0, 0, 0, .7);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    z-index: 100;
    overflow: auto;
    max-height: 60vh;
    width: 100%;
    margin-top: 5px;

    .item {
      cursor: pointer;
      vertical-align: middle;
      text-transform: capitalize;
      height: 100%;
      padding: 10px;
      font-size: 16px;
      font-weight: 300;
      text-align: left;

      & + .item {
        border-top: 1px solid rgba(226, 228, 230, 1);
      }

      &:hover{
        background-color: rgba(0, 0, 0, .07);
      }
      &.active {
        background-color: rgba(0, 0, 0, .07);
      }
    }
  }
}
</style>
