/**
 @typedef {Object} Gamme
 @property {string} id - identifiant du Gamme
 @property {boolean} usGrids - use grids or not (false by default)
 */

/**
 * Représente le Modèle Gamme.
 * @see {@link http://backbonejs.org/#Model Backbone.Model}
 * @constructor
 * @param {Gamme} Gamme - Données du gamme, permettant sa génération.
 * @augments Backbone.Model
 */

MYS.BB.MODELS.Gamme = Backbone.Model.extend(
    /** @lends MYS.BB.MODELS.Gamme.prototype */
    {
        defaults: {
            privelegedFormat: null,
            syncFingersWithFinition: false,
            formatBorderRadius: [{
                value: 0
            }],
            dropAreaBorderRadius: undefined,
            description: undefined
        },
        getDoigts: function(input) {
            var fonctions = MYS.FONCTIONS.where({
                gamme: this.id
            })
            var allDoigts = _.uniq(_.flatten(_.map(fonctions, function(fonction) {
                return _.pluck(fonction.get('doigts'), 'doigt')
            }), true))
            if (input.length === 0) {
                return allDoigts
            }
            var intersection = _.intersection(input, allDoigts)
            if (intersection.length !== input.length) {
                console.warn('Des doigts parmis "' + input.join(', ') + '" n\'existent pas dans la gamme "' + this.id + '". Seuls les doigts "' + allDoigts.join(', ') + '" sont disponibles')
            }
            if (intersection.length > 0) {
                return intersection
            }
            console.error('les doigts "' + input.join(', ') + '" n\'existent pas dans la gamme "' + this.id + '". Seuls les doigts "' + allDoigts.join(', ') + '" sont disponibles')
            return allDoigts
        },
        getFormatBorderRadius: function(stringToTest) {
            var out = 0;
            var rules = this.get('formatBorderRadius');
            if (!_.isArray(rules)) return out;
            for (var i = 0; i < rules.length; i++) {
                var rule = rules[i]
                if (_.isUndefined(rule.value)) {
                    continue;
                }
                if (!_.isString(rule.test)) {
                    return rule.value;
                }
                if (_.isString(rule.test)) {
                    var isValid = false;
                    try {
                        var re = new RegExp(rule.test, "g");
                        isValid = re.test(stringToTest);
                    } catch (error) {
                        console.error(error);
                    }
                    if (isValid) {
                        return rule.value;
                    }
                }
            }
            return defaultValue;
        },
        validate: function() {
            if (MYS.FONCTIONS.where({
                    gamme: this.id
                }).length === 0) {
                return 'aucune fonction pour la gamme ' + this.id
            }
            if (MYS.FINITIONS.where({
                    gamme: this.id
                }).length === 0) {
                return 'aucune finition pour la gamme ' + this.id
            }
        }
    }
);
